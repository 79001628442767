import {Box, Link, List, ListItem, Typography} from "@material-ui/core";
import {margin, padding} from "../../styles/styleConstants";


export const SgiHelpComponent = () => {
    return <>
        <Typography color='primary' variant='h6'>
            <strong>Manual Acceso al SGI</strong>
        </Typography>
        <Box m={margin}>
            <Typography color='secondary' variant='h6'>
                <strong>• ¿Qué es el SGI?</strong>
            </Typography>

            <Box m={margin}>
                <Typography variant='body1' align='justify'>
                    El SGI es un Sistema de Gestión Integrado, una plataforma viva que se conforma de carpetas virtuales
                    la cual tiene por objetivo unificar procesos de áreas diferentes en un solo sistema. Como empresa
                    estamos certificados bajo 3 normas ISO:
                </Typography>

                <Box m={margin}>
                    <Typography variant='body1' align='justify'>
                        <strong>• ISO 9001-2015, SISTEMA DE GESTION DE CALIDAD</strong>
                    </Typography>
                    <Typography variant='body1' align='justify'>
                        <strong>• ISO 45001-2018 SISTEMA GESTION DE SEGURIDAD Y SALUD</strong>
                    </Typography>
                    <Typography variant='body1' align='justify'>
                        <strong>• ISO 14001-2015 SISTEMA DE GESTION AMBIENTAL</strong>
                    </Typography>
                </Box>

                <Typography variant='body1' align='justify'>
                    Y las directrices de estas 3 normas están consolidadas en este sistema, este conjunto de carpetas
                    son una herramienta de trabajo que nos permite estandarizarnos y trabajar en forma más alineada y
                    eficiente. Te invitamos a utilizar los documentos del sistema y conocer nuestros procedimientos.
                </Typography>
            </Box>

            <Box m={margin}>
                <Typography color='secondary' variant='h6'>
                    <strong>• ¿Cómo acceder al SGI?</strong>
                </Typography>

                <Box m={margin}>
                    <Typography variant='body1' align='justify'>
                        Para acceder a nuestro SISTEMA DE GESTIÓN INTEGRADO debes ingresar en este&nbsp;
                        <Link
                            href="https://bminingspa.sharepoint.com/sites/BMININGSGI/Documentos%20compartidos/Forms/AllItems.aspx">
                            link
                        </Link>
                    </Typography>
                </Box>
            </Box>

            <Box m={margin}>
                <Typography color='secondary' variant='h6'>
                    <strong>• ¿Qué contiene el SGI?</strong>
                </Typography>

                <Box m={margin}>
                    <Typography variant='body1' align='justify'>
                        El SGI estás conformado por 6 carpetas y cada una por la cantidad de carpetas exigidas por las
                        normas:
                    </Typography>


                    <Box m={margin}>
                        <Typography variant='body1' align='justify'>
                            <strong>1. GERENCIA GENERAL</strong>
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            <strong>2. GESTION COMERCIAL</strong>
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            <strong>3. GESTION HSEQ</strong>
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            <strong>4. GESTION PROYECTOS</strong>
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            <strong>5. GESTION ABASTECIMIENTO</strong>
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            <strong>6. GESTION ADMINISTRACION, FINANZAS Y RRHH</strong>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
}