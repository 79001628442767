import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import {useMsal} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import {ExpandMore} from "@material-ui/icons";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IClientComponentModel} from "./IClientComponentModel";
import {List as ListLinq} from "linq-typescript";
import {IUserModel} from "../../api/IUserModel";
import {clientsService} from "../../api/ClientsService";
import {projectService} from "../../api/ProjectsService";
import {usersService} from "../../api/UsersService";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {progress} from "../../redux/progress";
import {Strings} from "../../Resources/Strings";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        progress: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '0%'
        }
    }),
);

export function ClientsComponent() {

    const classes = useStyles();
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const dispatch = useDispatch<AppDispatch>()
    const [clientsData, setClientsData] = useState<IClientComponentModel[]>([]);


    const clientClickCallback = (clientComponentModel: IClientComponentModel) => {
        let index = clientsData.indexOf(clientComponentModel);
        const newList = [...clientsData];
        let item = newList[index];
        if (!item.areProjectsProcessed) {
            item.projects = [];
            projectService.getProjectsByClient(item.id).then(async value => {

                let projectsList = new ListLinq(value);

                let allUsers = new ListLinq(await usersService.getUsers());
                let idUserModel = new Map<string, IUserModel>();
                projectsList.select(element => element.manager.id).distinct().forEach(managerId => {
                    idUserModel.set(managerId, allUsers.first(element => element.id === managerId));
                });

                value.forEach(projectByClient => {
                    item.projects.push({
                        projectId: projectByClient.id,
                        projectCode: projectByClient.code,
                        projectName: projectByClient.name,
                        projectManager: idUserModel.get(projectByClient.manager.id)
                    });
                });
                item.areProjectsProcessed = true;
                setClientsData(newList);
            }).catch(reason =>
                console.error(reason));
        }
    };

    React.useEffect(() => {
        dispatch(progress.actions.openProgressBar({}));
        clientsService.getClients().then(value => {
            dispatch(progress.actions.closeProgressBar());
            let data = value.map(clientModel => {
                return {
                    id: clientModel.id,
                    name: clientModel.name,
                    creator_id: clientModel.creator_id,
                    areProjectsProcessed: false,
                    projects: []
                } as IClientComponentModel
            });
            setClientsData(data)

        }).catch(reason => console.error(reason));
    },[]);

    return <>
        {!isOpen && <>
            <Typography variant='h6'>{Strings.Clients}</Typography>
            {clientsData?.map(value => <Accordion key={value.name} onClick={() => {
                clientClickCallback(value)
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMore/>}>
                    <Typography>{value.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.root}>
                        <Divider/>
                        <Typography>Proyectos</Typography>
                        {
                            value.areProjectsProcessed ?
                                <List>
                                    {value.projects?.map(project => <ListItem key={project.projectCode}>
                                        <ListItemText
                                            primary={`${project.projectCode} ${project.projectName} ${project.projectManager.email}`}/>
                                    </ListItem>)}
                                </List>
                                : <div className={classes.progress}>
                                    <CircularProgress/>
                                </div>
                        }
                    </div>
                </AccordionDetails>
            </Accordion>)}
        </>}
    </>
}