import React, {ChangeEvent} from 'react'
import {PelambresService} from "../../api/PelambresService";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress, Divider,
    FormControlLabel,
    Grid,
    List,
    Paper,
    Typography
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import Center from 'react-center';
import {List as ListLinq} from 'linq-typescript'
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {notification} from "../../redux/notification";


interface IMovementBase64 {
    fileName: string
    base64: string,
    isLoaded: boolean
}

interface ICutsByPriority {
    cutName: string,
    isPriority: boolean
}

interface IBadWeatherDays {
    day: number,
    isBadWeatherDay: boolean
}

const padding = 1;
const margin = 1;
const elevation = 2;

export const PelambresComponent = () => {

    const dispatch = useDispatch<AppDispatch>()

    const [mineToDestination, setMineToDestination] = React.useState<IMovementBase64>({
        base64: '',
        fileName: '',
        isLoaded: false
    })
    const [destinationToMine, setDestinationToMine] = React.useState<IMovementBase64>({
        base64: '',
        fileName: '',
        isLoaded: false
    })

    const [cutsByPriority, setCutsByPriority] = React.useState<ICutsByPriority[]>([])
    const [badWeatherDays, setBadWeatherDays] = React.useState<IBadWeatherDays[]>([])
    const [isCheckingData, setIsCheckingData] = React.useState<boolean>()
    const [isDataPreProcessed, setIsDataPreProcessed] = React.useState<boolean>()

    const uploadMineToDestination = (event: ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files[0];
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let base64 = reader.result as string
            let isLoaded = true
            let fileName = file.name
            setMineToDestination({base64: base64, fileName: fileName, isLoaded: isLoaded});
        }
    }


    const uploadDestinationToMine = (event: ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files[0];
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let base64 = reader.result as string
            let isLoaded = true
            let fileName = file.name
            setDestinationToMine({base64: base64, fileName: fileName, isLoaded: isLoaded});
        }
    }

    const checkFiles = () => {
        setIsCheckingData(true)
        let pelambresApi = new PelambresService()
        pelambresApi.checkData(mineToDestination.base64, destinationToMine.base64).then(pelambresCutsAndDaysModel => {
            let newCutsByPriority = [] as ICutsByPriority[]
            pelambresCutsAndDaysModel.cuts.forEach(cut => {
                newCutsByPriority.push({cutName: cut, isPriority: false})
            });
            setCutsByPriority(newCutsByPriority)

            let badWeatherDays = [] as IBadWeatherDays[]
            pelambresCutsAndDaysModel.uniqueDays.forEach(value => {
                badWeatherDays.push({isBadWeatherDay: false, day: value})
            })
            setBadWeatherDays(badWeatherDays)
            setIsDataPreProcessed(true)

        }).catch(reason => {
            console.error(reason)
            dispatch(notification.actions.openDialog({
                text: reason,
                title: Strings.Error
            }))
        }).finally(() => {
            setIsCheckingData(false)
        })
    }

    function onCheckedPriorityCut(value: ICutsByPriority) {
        let index = cutsByPriority.indexOf(value);
        let newTable = [...cutsByPriority];
        newTable[index].isPriority = !newTable[index].isPriority;
        setCutsByPriority(newTable);
    }

    function onCheckedBadWeather(value: IBadWeatherDays) {
        let index = badWeatherDays.indexOf(value);
        let newTable = [...badWeatherDays];
        newTable[index].isBadWeatherDay = !newTable[index].isBadWeatherDay;
        setBadWeatherDays(newTable);
    }

    function process() {
        let pelambresApi = new PelambresService()

        let cutsByPriorityList = new ListLinq(cutsByPriority)
        let cuts = cutsByPriorityList.where(element => element.isPriority)
            .select(element => element.cutName).toArray()

        let badWeatherDaysList = new ListLinq(badWeatherDays)
        let uniqueDays = badWeatherDaysList.where(element => element.isBadWeatherDay)
            .select(element => element.day).toArray()

        pelambresApi.getReport(mineToDestination.base64, destinationToMine.base64, cuts, uniqueDays).then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `report.xlsx`;
            link.click();
        }).catch(reason => {
            dispatch(notification.actions.openDialog({
                text: reason,
                title: Strings.Error
            }))
        });
    }


    return <>
        <Box m={margin} p={padding}>
            <Paper elevation={elevation}>
                {/* Mine to Destination */}
                <Grid container direction='row'>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button
                                variant="contained"
                                component="label">
                                {Strings.UploadGoing}
                                <input onChange={uploadMineToDestination}
                                       type="file"
                                       hidden
                                />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Typography variant='h6'>{mineToDestination.fileName}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                {/* Destination to Mine */}
                <Grid container direction='row'>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button
                                variant="contained"
                                component="label">
                                {Strings.UploadLeaving}
                                <input onChange={uploadDestinationToMine}
                                       type="file"
                                       hidden
                                />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Typography variant='h6'>{destinationToMine.fileName}</Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container alignContent='center' alignItems='center'>
                    <Grid item xs>

                    </Grid>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button onClick={checkFiles} variant='contained' color='secondary'
                                    disabled={!mineToDestination.isLoaded || !destinationToMine.isLoaded}>{Strings.Load}</Button>
                        </Box>
                    </Grid>

                    {isCheckingData && <>
                        <Grid item xs='auto'>
                            <Box m={margin} p={padding}>
                                <Center>
                                    <CircularProgress size={30}/>
                                </Center>
                            </Box>
                        </Grid>
                    </>}
                </Grid>
            </Paper>
        </Box>

        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <Button color='primary' onClick={process} variant='contained'
                            disabled={!isDataPreProcessed}>{Strings.Process}</Button>
                </Box>

                <Grid container>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            `<Typography variant='h6'>{Strings.PriorityCuts}</Typography>
                        </Box>

                        <Divider/>

                        <Box m={margin} p={padding}>
                            <List>
                                {cutsByPriority.map(value => {
                                    return <Box key={cutsByPriority.indexOf(value)}>
                                        <FormControlLabel
                                            checked={value.isPriority}
                                            control={<Checkbox onClick={() => onCheckedPriorityCut(value)}
                                                               inputProps={{'aria-label': 'primary checkbox'}}/>}
                                            label={value.cutName}/>
                                    </Box>
                                })}
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            `<Typography variant='h6'>{Strings.BadWeatherDays}</Typography>
                        </Box>

                        <Divider/>

                        <Box m={margin} p={padding}>
                            <List>
                                {badWeatherDays.map(value => {
                                    return <Box key={value.day}>
                                        <FormControlLabel
                                            checked={value.isBadWeatherDay}
                                            control={<Checkbox onClick={() => onCheckedBadWeather(value)}
                                                               inputProps={{'aria-label': 'primary checkbox'}}/>}
                                            label={value.day}/>
                                    </Box>
                                })}
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    </>
}