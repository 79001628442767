import {apiUrl} from "./ApiConfig";
import {IClientModel} from "./IClientModel";
import {BaseService} from "./BaseService";

class ClientsService extends BaseService {
    async getClients() {
        let token = this.getToken();

        let host = apiUrl;
        let resource = `clients/all`;
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json()) as IClientModel[]
    }
}

export const clientsService = new ClientsService();






