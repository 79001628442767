import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IAccountRequestModel} from "./IAccountRequestModel";
import {CredentialTypeEnum} from "./CredentialTypeEnum";
import {AccountRequestStatusEnum} from "./AccountRequestStatusEnum";

export interface IAccountRequest {
    name: string;
    surname: string;
    enterpriseEmail: string;
    personalEmail: string;
    credentialType: CredentialTypeEnum;
    withCopyEmails: string[];
}

export interface IMicrosoftUser {
    id: string,
    name: string,
    surname: string,
    enterpriseEmail: string,
    userType: string,
    createdDateTime: Date,
    assignedLicences: string[],
    credentialType: CredentialTypeEnum
}

export interface IMicrosoftAccounts {
    value: IMicrosoftUser[]
}


class AccountRequestService extends BaseService {
    async addAccountRequest(name: string, surname: string, enterpriseEmail:string, personalEmail:String, credentialType:CredentialTypeEnum, withCopyEmails:string[]) {
        let url = `${apiUrl}AccountRequest/add?`
        url += `name=${name}`
        url += `&surname=${surname}`
        url += `&enterpriseEmail=${enterpriseEmail}`
        url += `&personalEmail=${personalEmail}`
        url += `&credentialType=${credentialType}`
        for(let i=0;i<withCopyEmails.length ;i++){
            url += `&withCopyEmails=${withCopyEmails[i]}`
            }

        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModel = await response.json() as IAccountRequestModel;
        return accountRequestModel;
    }

    async getAccountRequestById(id:string) {
        let url = `${apiUrl}AccountRequest/AccountRequestId?`
        url += `id=${id}`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModel = await response.json() as IAccountRequestModel;
        return accountRequestModel;
    }

    async getAccountRequestsAll() {
        let url = `${apiUrl}AccountRequest/All`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModels = await response.json() as IAccountRequestModel[];
        return accountRequestModels;
    }

    async getAccountRequestsByStatus(status:AccountRequestStatusEnum[]) {
        let url = `${apiUrl}AccountRequest/AccountRequestsStatus?`
        for(let i=0;i<status.length ;i++){
            if (i===0) {
                url += `status=${status[i]}`
            } else {
                url += `&status=${status[i]}`
            }
            
        }
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModels = await response.json() as IAccountRequestModel[];
        return accountRequestModels;
    }

    async setAccountRequestsApproved(ids:string[]) {
        let url = `${apiUrl}AccountRequest/approve?`
        for(let i=0;i<ids.length ;i++){
            if (i===0) {
                url += `accountRequestId=${ids[i]}`
            } else {
                url += `&accountRequestId=${ids[i]}`
            }
        }
        
        let token = await this.getToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModels = await response.json() as IAccountRequestModel[];
        return accountRequestModels;
    }

    async setAccountRequestsRejected(ids:string[]) {
        let url = `${apiUrl}AccountRequest/reject?`
        for(let i=0;i<ids.length ;i++){
            if (i===0) {
                url += `accountRequestId=${ids[i]}`
            } else {
                url += `&accountRequestId=${ids[i]}`
            }
        }
        
        let token = await this.getToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModels = await response.json() as IAccountRequestModel[];
        return accountRequestModels;
    }

    async getMicrosoftAccountBminingAll() {
        let url = `${apiUrl}AccountRequest/GetMicrosoftBmining`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let microsoftUserModels = await response.json() as IMicrosoftUser[];
        return microsoftUserModels;
    }

    async downloadMicrosoftAccountBmining() {
        let url = `${apiUrl}AccountRequest/DonwloadMicrosoftBmining`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `bmining_active_users.csv`;
        link.click();
    }

    async getMicrosoftAccountMurocAll() {
        let url = `${apiUrl}AccountRequest/GetMicrosoftMuroc`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let microsoftUserModels = await response.json() as IMicrosoftUser[];
        return microsoftUserModels;
    }

    async downloadMicrosoftAccountMuroc() {
        let url = `${apiUrl}AccountRequest/DonwloadMicrosoftMuroc`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `muroc_active_users.csv`;
        link.click();
    }

    async removeMicrosoftUserRequest(id: string, withCopyEmails: string[]) {
        let url = `${apiUrl}/AccountRequest/RemoveMicrosoftUserRequest?`
        url += `id=${id}`
        for(let i=0;i<withCopyEmails.length ;i++){
            url += `&withCopyEmails=${withCopyEmails[i]}`
            }
        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModel = await response.json() as IAccountRequestModel;
        return accountRequestModel;
    }

    async changeCredentialTypeUserRequest(id: string, withCopyEmails: string[]) {
        let url = `${apiUrl}/AccountRequest/ChangeMicrosoftCredentialTypeRequest?`
        url += `id=${id}`
        for(let i=0;i<withCopyEmails.length ;i++){
            url += `&withCopyEmails=${withCopyEmails[i]}`
            }
        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let accountRequestModel = await response.json() as IAccountRequestModel;
        return accountRequestModel;
    }
}

export const accountRequestService = new AccountRequestService();