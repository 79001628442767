import React, {useEffect} from 'react'
import {
    Box,

} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Strings} from "../../Resources/Strings";
import {loadAccountRequestsAllThunk, IAccountRequestsTable, 
    rejectAccountRequestThunk, approveAccountRequestThunk} from "../../redux/accountRequest";

import MaterialTable, {Column} from "material-table";
import {AccountRequestStatusEnum, getAccountRequestStatusString} from '../../api/AccountRequestStatusEnum';
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons';
import { progress } from '../../redux/progress';

const margin = 1;
const padding = 1;

function getTemplateColumnsHistoric(): Column<IAccountRequestsTable>[] {
    return [
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.EnterpriseEmail, field: 'enterpriseEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.PersonalEmail, field: 'personalEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.CredentialType, field: 'credentialType', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.RequestUser, field: 'requestUser', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Date, field: 'requestDate', type: 'datetime', editable: "never", width: 'auto'},
        {title: Strings.Date, field: 'requestType', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.ApprovingUser, field: 'approvingUser', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Status, field: 'status', type: 'string', editable: "never", width: 'auto'},
        ]
}

function getTemplateColumnsPending(): Column<IAccountRequestsTable>[] {
    return [
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.EnterpriseEmail, field: 'enterpriseEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.PersonalEmail, field: 'personalEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.CredentialType, field: 'credentialType', type: 'numeric', editable: "never", width: 'auto'},
        {title: Strings.RequestUser, field: 'requestUser', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.RequestType, field: 'requestType', type: 'string', editable: "never", width: 'auto'},
        ]
}


export const ApproveAccountRequestComponent = () => {

    const dispatch = useDispatch();
    const {accountRequests} = useSelector((state: RootState) => state.accountRequest)
    const [accountRequestColumnsPending] = React.useState(getTemplateColumnsPending());
    const [accountRequestColumnsHistoric] = React.useState(getTemplateColumnsHistoric());
    const accountRequestsPending = accountRequests.filter(a => a.status === getAccountRequestStatusString(AccountRequestStatusEnum.Pending))
    const dataTablePending = accountRequestsPending.map(value => ({...value}));
    const accountRequestsHistoric = accountRequests.filter(a => a.status === getAccountRequestStatusString(AccountRequestStatusEnum.Approved) || a.status === getAccountRequestStatusString(AccountRequestStatusEnum.Rejected))
    const dataTableHistoric = accountRequestsHistoric.map(value => ({...value}));
    
    useEffect(() => {
        async function loadData() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadAccountRequestsAllThunk)
            dispatch(progress.actions.closeProgressBar())
        }
        loadData()
    }, [])

    return <>
        
        <Box m={margin} p={padding}>
            <MaterialTable
                title={Strings.AccountRequestPending}
                columns={accountRequestColumnsPending}
                data={dataTablePending}
                options={{
                    actionsColumnIndex: -1
                }}
                actions={[ {
                    icon: ThumbDownAltOutlined,
                    
                    tooltip: `${Strings.RejectAccount}`,
                    onClick: async (event, rowData: IAccountRequestsTable) => {
                        await dispatch(rejectAccountRequestThunk(rowData.id))                     
                    }
                },
                {
                    icon: ThumbUpAltOutlined,
                    tooltip: `${Strings.ApproveAccount}`,
                    onClick: async (event, rowData: IAccountRequestsTable) => {
                        await dispatch(approveAccountRequestThunk(rowData.id))                     
                    }
                }]}
            />
        </Box>

        <Box m={margin} p={padding}>
            <MaterialTable
                title={Strings.AccountRequestHistory}
                columns={accountRequestColumnsHistoric}
                data={dataTableHistoric}
                options={{
                    actionsColumnIndex: -1
                }}
            />
        </Box>
    </>
}