import React from 'react'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Paper,
    Typography
} from "@material-ui/core";
import {IReportProjectItem} from "./IReportProjectItem";
import {getCodeProjectName} from "../../utils/utils";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {ProjectSelectorTemplate} from "../Templates/ProjectSelectorTemplate";
import {Strings} from "../../Resources/Strings";
import DateFnsUtils from "@date-io/date-fns";
import {projectService} from "../../api/ProjectsService";
import {reportService} from "../../api/ReportService";
import {elevation, margin, padding} from "../../styles/styleConstants";

export const ReportComponent = () => {
    // Controls
    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();
    const [isAddingProjects, setIsAddingProjects] = React.useState(false);
    // Project selector
    const [projectsSelectorList, setProjectSelectorList] = React.useState<IReportProjectItem[]>([]);
    const [projectsSelected, setProjectsSelected] = React.useState<IReportProjectItem[]>([]);


    React.useEffect(() => {
        setToDate(moment().toDate());
        setFromDate(moment().toDate())

        projectService.getAllProjects().then(projects => {
            let projectItemsAutoComplete = [] as IReportProjectItem[];
            projects.forEach(project => {
                projectItemsAutoComplete.push({
                    id: project.id,
                    projectName: project.name,
                    projectCode: project.code,
                    projectDisplay: getCodeProjectName(project.code, project.name)
                });
            });
            setProjectSelectorList(projectItemsAutoComplete)
        })
    }, [])


    function generateReportCallback() {
        reportService.makeTrackingHoursReport(fromDate, toDate, projectsSelected).then(blob => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `report.xlsx`;
            link.click();
        });
    }

    const handleToDataChanged = (date: Date) => {
        setToDate(date);
    }

    const handleFromDataChanged = (date: Date) => {
        setFromDate(date);
    }

    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Grid container direction='row'
                      justifyContent='space-between'
                      alignItems='center'>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Typography variant='h6'>{Strings.InitialDate}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={fromDate} onChange={handleFromDataChanged}
                                            title={Strings.InitialDate} format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Typography variant='h6'>{Strings.FinalDate}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={toDate} onChange={handleToDataChanged}
                                            title={Strings.FinalDate} format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Button variant='contained' color='primary' onClick={generateReportCallback} fullWidth>
                                {Strings.ReportGenerator}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
        <Box p={padding} m={margin}>
            <Paper elevation={1}>
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item xs='auto'>
                        <Box p={padding} m={margin} aria-disabled={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={isAddingProjects}
                                              onChange={() => {
                                                  setIsAddingProjects(!isAddingProjects)
                                              }}/>} label={Strings.SpecificProjects}/>
                        </Box>
                    </Grid>

                    {isAddingProjects &&
                    <Grid item xs>
                        <ProjectSelectorTemplate onSelected={project => {
                            let index = projectsSelected.findIndex(value => value.id === project.id);
                            if (index !== -1) {
                                alert(Strings.ThisProjectAlreadyExists);
                                return;
                            }
                            let newTable = [...projectsSelected]
                            newTable.push(project);
                            setProjectsSelected(newTable);
                        }} projectItems={projectsSelectorList}/>
                    </Grid>
                    }
                </Grid>

                <Box p={padding} m={margin}>
                    <Divider style={{margin: '6'}}/>
                </Box>

                <Box p={padding} m={margin}>
                    <List>
                        {projectsSelected.map(value => <ListItem key={value.projectCode}>
                            {value.projectDisplay}
                        </ListItem>)}
                    </List>
                </Box>
            </Paper>
        </Box>
    </>
}

