import Identity from "../Services/Identity";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";
import {authService} from "../Services/authService";
import {usersService} from "../api/UsersService";
import {updateCurrentUser} from "./user";

export interface AuthenticationState {
    identity: Identity,
    inProgress: boolean,
    isAuthenticated: boolean,
}

const initialState: AuthenticationState = {
    identity: null,
    inProgress: false,
    isAuthenticated: false,
};

export const authentication = createSlice({
    name: "authentication",
    initialState: initialState,
    reducers: {
        startNetwork: (state: AuthenticationState) => {
            state.inProgress = true;
            return state;
        },
        endNetwork: (state: AuthenticationState) => {
            state.inProgress = false;
            return state;
        },
        signIn: (state: AuthenticationState, action: PayloadAction<Identity>) => {
            state.identity = action.payload;
            state.isAuthenticated = true;
            return state;
        },
        signOut: (state: AuthenticationState) => {
            state.identity = null;
            state.isAuthenticated = false;
            return state;
        }
    }
});

export const signInThunk = () => async (dispatch: AppDispatch) => {
    await dispatch(authentication.actions.startNetwork())
    let identity = await authService.signIn();
    await dispatch(authentication.actions.signIn(identity));
    // Storage id
    await usersService.getUserByEmail(identity.credentials.email).then(value => {
        identity.credentials.setId(value.id);
    }).catch(reason => {
        alert(reason)
    })
    await dispatch(authentication.actions.endNetwork())
}

export const signOutThunk = () => async (dispatch: AppDispatch) => {
    dispatch(authentication.actions.startNetwork())
    await authService.signOut();
    dispatch(authentication.actions.signOut());
    dispatch(authentication.actions.endNetwork())
}

export const initializeAuthenticationThunk = () => async (dispatch: AppDispatch) => {
    try {
        let identity = await authService.getIdentity();
        if (identity != null) {
            await dispatch(authentication.actions.signIn(identity));
            // Storage id
            await usersService.getUserByEmail(identity.credentials.email).then(value => {
                identity.credentials.setId(value.id);
            }).catch(reason => {
                alert(reason)
            })
        } else dispatch(authentication.actions.signOut())
    } catch (e) {
        console.error(e);
    } finally {
        dispatch(authentication.actions.endNetwork())
    }
}