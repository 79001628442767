import React from 'react'
import {List, ListItem, Typography} from "@material-ui/core";
import {codeExplanationItems} from "./ICodeDescriptionElement";
import {Strings} from "../../Resources/Strings";

export function CodeExplanationComponent() {
    return <>
        <Typography variant='h6'>{Strings.Codes}</Typography>
        <List>
            {codeExplanationItems.map(value => {
                return <ListItem key={value.code}>
                    <Typography variant='body1'><b>{value.code}</b> {value.description}</Typography>
                </ListItem>
            })}
        </List>
    </>
}