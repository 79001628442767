import React, {FC, useEffect, useState} from 'react'
import {IActivityRecord} from "../../../api/IActivityRecord";
import MaterialTable, {Column} from "material-table";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {RootState} from "../../../redux/store";
import {Strings} from "../../../Resources/Strings";
import {activityRecord} from "../../../redux/activityRecord";
import {ActivityRecordExternalUsersDialogComponent} from "../../Dialogs/ActivityRecordExternalUsers/ActivityRecordExternalUsersDialogComponent";
import {AddActivityRecordDialogComponent} from "../../Dialogs/AddActivityRecord/AddActivityRecordDialogComponent";
import {ActivityRecordBminingUsersDialogComponent} from "../../Dialogs/ActivityRecordUsers/ActivityRecordBminingUsersDialogComponent";
import {IProjectModel} from "../../../api/IProjectModel";
import {AddActivityRecordCompromiseDialogComponent} from "../../Dialogs/AddActivityRecordCompromise/AddActivityRecordCompromiseDialogComponent";
import {compromise} from "../../../redux/compromise";
import {IUserModel} from "../../../api/IUserModel";
import {FileCopyOutlined, ViewAgenda} from "@material-ui/icons";
import {IExternalUser} from "../../../api/ExternalUsersService";
import {reportService} from "../../../api/ReportService";

function getTemplateColumns(): Column<IActivityRecord>[] {
    return [{title: Strings.Title, field: 'title', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Id, field: 'id', type: 'string', editable: "never", width: 'auto'}];
}

export interface ActivityRecordTableComponentProps {
    activityRecords: IActivityRecord[],
    projects: IProjectModel[],
    externalUsers: IExternalUser[]
    users: IUserModel[],
    selectedProject?: IProjectModel
}

export const ActivityRecordTableComponent: FC<ActivityRecordTableComponentProps> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const dataTable = props.activityRecords.map(value => ({...value}));
    const {selectedActivityRecord} = useSelector((state: RootState) => state.activityRecord)
    const [materialTableTitle, setMaterialTableTitle] = useState<string>()

    useEffect(() => {
        if (props.selectedProject !== null && props.selectedProject !== undefined)
            setMaterialTableTitle(`${Strings.ActivityRecords} ${props.selectedProject.code} ${props.selectedProject.name}`)
        else setMaterialTableTitle(`${Strings.ActivityRecords}`)
    }, [props.selectedProject])


    const addActivityRecordCallback = () => {
        dispatch(activityRecord.actions.openAddActivityRecord());
    }

    return <>
        <MaterialTable
            title={materialTableTitle}
            columns={getTemplateColumns()}
            data={dataTable}
            options={{
                paging: false,
                actionsColumnIndex: -1
            }}

            actions={[{
                tooltip: Strings.Add,
                icon: 'add',
                isFreeAction: true,
                onClick: addActivityRecordCallback
            }, {
                icon: 'edit',
                tooltip: `${Strings.Edit}`,
                onClick: (event, rowData: IActivityRecord) => {
                    let activityRecordItem = props.activityRecords.find(s => s.id === rowData.id);
                    dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
                    history.push(`/editActivityRecord`)
                }
            }, {
                icon: 'group',
                tooltip: `${Strings.AddExternalUser}`,
                onClick: (event, rowData: IActivityRecord) => {
                    let activityRecordItem = props.activityRecords.find(s => s.id === rowData.id);
                    dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
                    dispatch(activityRecord.actions.openEditActivityRecordExternalUsers())
                }
            }, {
                icon: 'group',
                tooltip: `${Strings.AddBminingUser}`,
                onClick: (event, rowData: IActivityRecord) => {
                    let activityRecordItem = props.activityRecords.find(s => s.id === rowData.id);
                    dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
                    dispatch(activityRecord.actions.openEditActivityRecordBminingUsers())
                },
            }, {
                icon: 'add',
                tooltip: `${Strings.AddCompromise}`,
                onClick: async (event, rowData: IActivityRecord) => {
                    let activityRecordItem = props.activityRecords.find(s => s.id === rowData.id);
                    await dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
                    dispatch(compromise.actions.openAddActivityRecordCompromise())
                },
            }, {
                icon: ViewAgenda,
                tooltip: `${Strings.Compromises}`,
                onClick: async (event, rowData: IActivityRecord) => {
                    let activityRecordItem = props.activityRecords.find(s => s.id === rowData.id);
                    await dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
                    history.push('/compromises')
                },
            }, {
                icon: FileCopyOutlined,
                tooltip: `${Strings.Report}`,
                onClick: async (event, rowData: IActivityRecord) => {
                    reportService.makeActivityRecordReport(rowData.id).then(blob => {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `Reporte.html`;
                        link.click();
                    });
                },
            }]}
        />

        {selectedActivityRecord && <>
            <ActivityRecordExternalUsersDialogComponent selectedActivityRecord={selectedActivityRecord}
                                                        externalUsers={props.externalUsers}/>
            <ActivityRecordBminingUsersDialogComponent selectedActivityRecord={selectedActivityRecord}
                                                       users={props.users}/>
            <AddActivityRecordCompromiseDialogComponent users={props.users} activityRecord={selectedActivityRecord}/>
        </>}
        <AddActivityRecordDialogComponent projects={props.projects} selectedProject={props.selectedProject}/>
    </>
}