import React from 'react';
import {Box, Link, List, ListItem, Paper, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {elevation, margin, padding} from "../../styles/styleConstants";
import {IWikiLinkElement} from "./IWikiLinkElement";
import {CodeExplanationComponent} from "../CodeExplanation/CodeExplanationComponent";

export const WikiComponent = () => {

    const [links, setLinks] = React.useState<IWikiLinkElement[]>([]);

    React.useEffect(() => {
        setLinks([{
            name: Strings.BminingPpt,
            link: 'https://bminingspa.sharepoint.com/:p:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7B99B38145-9376-43E2-B871-587931432C40%7D&file=PLANTILLA%20POWERPOINT%20%20BM%202023.pptx&action=edit&mobileredirect=true'
        },
            {
            name:Strings.BminingInformeSemilla,
            link: 'https://bminingspa.sharepoint.com/:w:/s/BMININGSGI/EerXPLLr3f9JhpuBfPlh3T8BzxPhCj6DP8GlJP2GtYGznw?e=KeLaHw'
            }
            ,{
                name:Strings.BminingGraphics,
                link: 'https://bminingspa-my.sharepoint.com/:b:/g/personal/francisco_soto_bmining_cl/EUtOIuyo9KBAqmdPJM73CTEBx9EwIgOXu42I-SiR1qvS3A?e=nMG6R5'
            }
            ,{
            name: Strings.BminingIcon,
            link: 'https://i.ibb.co/D5xsykz/bmining-icon.png'
        },])
    }, []);

    return <>
        <Box m={margin} p={padding}>
            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <Typography variant='h6'>{Strings.Template}</Typography>
                </Box>
                <List>
                    {links.map(value => {
                        return <ListItem key={value.name}>
                            <Link href={value.link}>{value.name}</Link>
                        </ListItem>
                    })}
                </List>
            </Paper>

            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <CodeExplanationComponent/>
                </Box>
            </Paper>
        </Box>
    </>
}
