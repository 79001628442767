import React, {useEffect} from 'react';
import './App.css';
import AppBar from "./components/NavBar/NavBarComponent";
import {BrowserRouter as Router} from 'react-router-dom';
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import {MainDialogsComponent} from "./components/Dialogs/MainDialogs/MainDialogsComponent";
import {useDispatch} from "react-redux";
import {initializeAuthenticationThunk} from "./redux/authentication";


const theme = createTheme({
    palette: {
        primary: {
            main: '#002060',
        },
        secondary: {
            main: '#007d73',
        },
        background: {
            default: '#fafafa',
            paper: '#f5f5f5'
        }
    },
});


function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(initializeAuthenticationThunk())
    }, [])

    return <>
        <Router>
            <MuiThemeProvider theme={theme}>
                <AppBar/>
                <MainDialogsComponent/>
            </MuiThemeProvider>
        </Router>
    </>
}

export default App;
