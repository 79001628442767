import React from "react";
import {Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Center from 'react-center';
import {signInThunk, signOutThunk} from "../../redux/authentication";
import {margin, padding} from "../../styles/styleConstants";
import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Person, ScheduleOutlined, VerifiedUser} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import {useHistory} from "react-router";

export const SignInOutComponent = () => {

    const {identity, inProgress} = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch();
    const history = useHistory()

    const signInCallback = () => {
        dispatch(signInThunk())
    }

    const signOutCallback = () => {
        dispatch(signOutThunk())
    }

    function navigateToUser() {
        history.push('/user')
    }

    return (<div>
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
                <Box m={margin} p={padding}>
                    {identity && identity.credentials.isAuthenticated && <>
                        <Typography variant='h6'>{identity.credentials.email}</Typography>
                    </>}
                </Box>
            </Grid>

            <Grid item xs='auto'>
                {identity && <>
                    <Tooltip title={Strings.User}>
                        <IconButton onClick={navigateToUser} color='secondary'>
                            <Person/>
                        </IconButton>
                    </Tooltip>
                </>}
            </Grid>
            <Grid item>
                <Button variant='contained' color='secondary'
                        onClick={(identity && identity.credentials.isAuthenticated) ? signOutCallback : signInCallback}>
                    <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                        {inProgress && <>
                            <Grid item xs='auto'>
                                <Center>
                                    <CircularProgress size={20}/>
                                </Center>
                            </Grid>

                            <Grid item xs>
                                <Box m={margin}/>
                            </Grid>
                        </>}

                        <Grid item xs='auto'>
                            {identity ? Strings.SignOut : Strings.SignIn}
                        </Grid>
                    </Grid>
                </Button>
            </Grid>
        </Grid>
    </div>)
}

