export interface ICodeDescriptionElement {
    code: string,
    description: string
}

export const codeExplanationItems: ICodeDescriptionElement[] = [
    {
        code: "BM-INT-ADM ADMINISTRACIÓN",
        description: "Reuniones de coordinación u otras actividades relacionadas con la empresa y temas administrativos, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-RDE REUNIÓN DE EQUIPO",
        description: "Reunión semanal de equipo (cada lunes), Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-PAC PAUSA ACTIVA",
        description: "Instancia de cada miércoles entre las 16:30 a 17:00 hrs, vía Teams, objetivo es momento de recreación, compartir como equipo considerando nuestra modalidad de trabajo online, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-RCP REUNIÓN COORDINACIÓN DE PROYECTOS",
        description: "Reunión semanal de coordinación del equipo de proyectos, Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-CAP CAPACITACIÓN",
        description: "Cursos, charlas relacionadas con instrucción en uso de software, sistemas de calidad u otras cosas (Ej: seguro complementario de salud), Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-PER PERMISOS",
        description: "Diversas actividades no relacionadas con la empresa y normalmente de carácter personal (Esta situación debe ser comunicada al jefe directo antes de realizar esta actividad), Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-PRO PROPUESTAS",
        description: "Trabajo relacionado a la preparación y elaboración de propuestas (Este trabajo debe ser asignado por el Gerente General, Gerente de Operaciones, director de Ingeniería), Aprobador: Edgar Adam"
    },
    {
        code: "BM-INT-FER FERIADOS",
        description: "Días considerados como Feriados legales, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-VAC VACACIONES",
        description: "Se realiza la gestión a través de intranet en forma automática para que sea aprobada por la jefatura correspondiente, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-LME LICENCIAS MÉDICAS",
        description: "Respaldadas por una licencia médica entregada por un profesional de la salud, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT -OVE OVERHEAD",
        description: "Cualquier otra condición que no se encuentra relacionada a ningún proyecto activo o a ninguna descripción de los códigos de este listado, Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-DHI DESARROLLO DE HERRAMIENTAS INTERNAS",
        description: "Trabajo asignado específicamente para desarrollo de soluciones que requiera nuestro equipo, Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-DEC DISEÑO Y ESTRATEGIAS DE COMUNICACIÓN",
        description: "Trabajo asignado Evelyn Adam, Aprobador: Evelyn Adam"
    },
    {
        code: "BM-INT-TEB TEAM BUILDING",
        description: "Cualquier actividad recreativa que realice el equipo BM o parte de este, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM-INT-MEM MEMORIAS",
        description: "Desarrollo de memorias, Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-PRA PRÁCTICAS",
        description: "Prácticas universitaria, Aprobador: Jorge Contreras"
    },
    {
        code: "BM-INT-IND INDUCCIÓN",
        description: "Capacitación inicial que se realiza a cada persona que se incorpora a la organización, Aprobador: Vanesa Miranda"
    },
    {
        code: "BM23-INT-DBD DESARROLLO DE BIBLIOTECA DIGITAL BM",
        description: "Proyecto que desarrolla un repositorio interno con información de los proyectos, Aprobador: Ana Theiler"
    },
    {
        code: "BM-INT-SGI SISTEMA DE GESTIÓN INTEGRADO",
        description: "Conjunto de archivos almacenados en un SharePoint que corresponden a procedimientos, política, objetivos y todo lo relacionado con la exigencia de la trinorma ISO, Aprobador: Ana Theiler"
    },
    {
        code: "BM-INT-COM COMITÉ PARITARIO",
        description: "Organismo técnico conformado por representantes de la empresa y trabajadores para detectar y evaluar los riesgos de accidentes y enfermedades profesional, Aprobador: Diego Plaza"
    }
];
