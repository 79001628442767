import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable, {Column} from "material-table";
import {Button, CircularProgress, Divider, Grid, IconButton, TextField, Typography} from "@material-ui/core";
import {List} from "linq-typescript";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {TimeTrackingTypeEnum} from "../../api/TimeTrackingTypeEnum";
import {getWeekNumber, updateHeaders, getTrackingHoursRow} from "../../utils/utils";
import {TrackingHoursStateEnum} from "../../api/TrackingHoursStateEnum";
import {ITrackingHoursModel} from "../../api/ITrackingHoursModel";
import Center from 'react-center';
import ITrackingHoursRowData from "../../utils/ITrackingHoursRowData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IUserModel} from "../../api/IUserModel";
import {usersService} from "../../api/UsersService";
import {trackingHoursService} from "../../api/TrackingHoursService";
import {projectService} from "../../api/ProjectsService";
import {Strings} from "../../Resources/Strings";
import {question} from "../../redux/question";
import {progress} from "../../redux/progress";
import {userHolidayService} from "../../api/UserHolidayService";
import {loadUsersThunk} from "../../redux/user";
import {loadUserHolidaysThunk} from "../../redux/userHolidays";
import {useDispatch} from "react-redux";


export const AdminViewUserHoursComponent = () => {
    const [selectedUser, setSelectedUser] = React.useState<IUserModel>();
    const [users, setUsers] = React.useState([] as IUserModel[]);
    const dispatch = useDispatch()

    const [currentDate, setCurrentDate] = React.useState<Date>(new Date());
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<Date>(new Date());
    const [weekOfYear, setWeekOfYear] = React.useState<number>(0);
    const [columnsTable] = React.useState(getTemplateColumns);
    const [ordinaryHoursTable, setOrdinaryHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [ordinaryHoursColumns, setOrdinaryHoursColumns] = React.useState(getTemplateColumns);
    const [extraordinaryHoursTable, setExtraOrdinaryHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [waitingForApprovalHoursTable, setWaitingForApprovalHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [approvedHoursTable, setApprovedHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {

        usersService.getUsers().then(userModels => {
            let userModelsList = new List<IUserModel>();
            userModelsList.pushRange(userModels);
            setUsers([...userModels]);
            setSelectedUser(userModelsList.firstOrDefault());
        });
    }, [])

    React.useEffect(() => {
        if (selectedUser !== undefined) {
            handleDateChange(currentDate)
        }
        // eslint-disable-next-line
    }, [currentDate, selectedUser])

    const handleDateChange = (date: Date) => {
        setIsLoading(true);
        let [, weekOfYear] = getWeekNumber(date);
        const pivotDay = 6;
        let delta = 0;
        let newStartDate = new Date(date);

        newStartDate = new Date(Date.UTC(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate()))
        let newEndDate = new Date(newStartDate);
        if (newStartDate.getUTCDay() !== pivotDay)
            delta = newStartDate.getUTCDay() + 1;
        newStartDate.setUTCDate(newStartDate.getUTCDate() - delta)
        newEndDate.setUTCDate(newEndDate.getUTCDate() - delta + 6)
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setCurrentDate(date);
        setWeekOfYear(weekOfYear);

        if (selectedUser === null) {
            setOrdinaryHoursTable([]);
            setExtraOrdinaryHoursTable([]);
            setApprovedHoursTable([]);
            setWaitingForApprovalHoursTable([]);
            setIsLoading(false);
            return;
        }


        initializeTrackingHours(selectedUser.email, newStartDate, newEndDate)
            .then(tables => {
                // Update ordinary table
                setOrdinaryHoursTable(tables.newOrdinaryDataTable);
                setOrdinaryHoursColumns(updateHeaders(tables.newOrdinaryDataTable, ordinaryHoursColumns));
                setExtraOrdinaryHoursTable(tables.newExtraOrdinaryDataTable);
                setApprovedHoursTable(tables.newApprovedDataTable);
                setWaitingForApprovalHoursTable(tables.newWaitingForApprovalDataTable);
                setIsLoading(false);
            });
        return;
    }

    function setPreviousWeekCallback() {
        let date = new Date(currentDate);
        date.setDate(date.getDate() - 7);
        handleDateChange(date);
    }

    function setNextWeekCallback() {
        let date = new Date(currentDate);
        date.setDate(date.getDate() + 7);
        handleDateChange(date);
    }

    async function resetCallback() {

        if (selectedUser)
            await dispatch(question.actions.openDialog({
                title: Strings.Question,
                text: Strings.AreYouSure,
                actionOnYes: async () => {
                    await dispatch(progress.actions.openProgressBar({}))
                    await trackingHoursService.resetHoursAdmin(startDate,endDate,selectedUser.email)
                    window.location.reload();
                }
            }))

    }


    return <div>
        {!isLoading && <div>
            <Grid container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'>
                <Grid item xs>
                    <IconButton color="primary" onClick={setPreviousWeekCallback}>
                        <ArrowBack/>
                    </IconButton>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker value={currentDate} onChange={handleDateChange}
                                    format="dd/MM/yyyy"/>
                    </MuiPickersUtilsProvider>
                    <IconButton color="primary" onClick={setNextWeekCallback}>
                        <ArrowForward/>
                    </IconButton>
                </Grid>

                <Grid item xs>
                    <Typography variant='h6' align='center'
                                paragraph>Semana {weekOfYear} {startDate.getUTCDate()}/{startDate.getUTCMonth() + 1}/{startDate.getUTCFullYear()} - {endDate.getUTCDate()}/{endDate.getUTCMonth() + 1}/{endDate.getUTCFullYear()}</Typography>
                </Grid>

                <Grid item xs>
                    <Autocomplete style={{width: '150'}}
                                  value={selectedUser}
                                  onChange={(event: any, newValue: IUserModel | null) => {
                                      // selectedUser = newValue
                                      setSelectedUser(newValue);


                                  }}
                                  id="combo-box-demo"
                                  options={users}
                                  getOptionLabel={(option) => option.email}
                                  renderInput={(params) =>
                                      <TextField {...params} variant="outlined"/>}
                    />
                </Grid>
            </Grid>
            {<div>
                <MaterialTable
                    title={`Horas template ordinarias`}
                    columns={ordinaryHoursColumns}
                    data={ordinaryHoursTable}
                    isLoading={isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                />
                <Divider light/>
                <MaterialTable
                    title={`Horas template extraordinarias`}
                    columns={columnsTable}
                    data={extraordinaryHoursTable}
                    isLoading={isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                />
            </div>}
            {<div>
                <MaterialTable
                    title={`Horas esperando aprobación`}
                    columns={getColumns()}
                    data={waitingForApprovalHoursTable}
                    isLoading={isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                />
                <Divider/>
                <MaterialTable
                    title={`Horas aprobadas`}
                    columns={getColumns()}
                    data={approvedHoursTable}
                    isLoading={isLoading}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1
                    }}
                />
                <Button onClick={resetCallback} variant='contained' color='secondary'>{Strings.Reset}</Button>
            </div>}
        </div>}
        {(isLoading) && <div>
            <Center>
                <CircularProgress/>
            </Center>
            <Center>
                <p>Cargando</p>
            </Center>
        </div>}
    </div>
}

function getTemplateColumns(): Column<ITrackingHoursRowData>[] {
    return [{title: 'Nombre', field: 'projectCodeName', type: 'string', editable: "never", width: 'auto'},
        {title: 'Sábado', field: 'saturday', type: 'numeric', width: 150},
        {title: 'Domingo', field: 'sunday', type: 'numeric', width: 150},
        {title: 'Lunes', field: 'monday', type: 'numeric', width: 150},
        {title: 'Martes', field: 'tuesday', type: 'numeric', width: 150},
        {title: 'Miércoles', field: 'wednesday', type: 'numeric', width: 150},
        {title: 'Jueves', field: 'thursday', type: 'numeric', width: 150},
        {title: 'Viernes', field: 'friday', type: 'numeric', width: 150}];
}

function getColumns(): Column<ITrackingHoursRowData>[] {
    return [{title: 'Nombre', field: 'projectCodeName', editable: 'never', type: 'string', width: 'auto'},
        {title: 'Tipo', field: 'timeTrackingTypeDisplay', editable: 'never', type: 'string', width: 'auto'},
        {title: 'Sábado', field: 'saturday', type: 'numeric', width: 'auto'},
        {title: 'Domingo', field: 'sunday', type: 'numeric', width: 'auto'},
        {title: 'Lunes', field: 'monday', type: 'numeric', width: 'auto'},
        {title: 'Martes', field: 'tuesday', type: 'numeric', width: 'auto'},
        {title: 'Miércoles', field: 'wednesday', type: 'numeric', width: 'auto'},
        {title: 'Jueves', field: 'thursday', type: 'numeric', width: 'auto'},
        {title: 'Viernes', field: 'friday', type: 'numeric', width: 'auto'}];
}

interface IInitializeTrackingHoursResult {
    newOrdinaryDataTable: ITrackingHoursRowData[];
    newExtraOrdinaryDataTable: ITrackingHoursRowData[];
    newWaitingForApprovalDataTable: ITrackingHoursRowData[];
    newApprovedDataTable: ITrackingHoursRowData[];
    hoursHasBeenSent: boolean
}


async function initializeTrackingHours(email: string,
                                       newStartDate: Date,
                                       newEndDate: Date) {
    let newOrdinaryDataTable: ITrackingHoursRowData[];
    let newExtraOrdinaryDataTable: ITrackingHoursRowData[];
    let newWaitingForApprovalDataTable: ITrackingHoursRowData[];
    let newApprovedDataTable: ITrackingHoursRowData[];


    let user = await usersService.getUserByEmail(email);
    let userId = user.id;

    let trackingHours = await trackingHoursService.getTrackingHours(userId, newStartDate, newEndDate);
    // Create the list and push the elements
    const trackingHourModels = new List<ITrackingHoursModel>();
    trackingHourModels.pushRange(trackingHours);

    // Get all distinct projects
    let distinctProjectIds = trackingHourModels.select(element => element.project.id).distinct().toArray();
    let trackingHoursProjectModels = await projectService.getProjectByIds(distinctProjectIds);

    let hoursHasBeenSent = false;
    if (trackingHourModels.where(element => element.status === TrackingHoursStateEnum.WaitingForApproval ||
        element.status === TrackingHoursStateEnum.Approved).count() > 0) {
        hoursHasBeenSent = true;
    }
    // Bundle between ordinary and extraordinary
    newOrdinaryDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.type === TimeTrackingTypeEnum.Ordinary && element.status === TrackingHoursStateEnum.Template).toList(),
        trackingHoursProjectModels, userId, newStartDate);
    newExtraOrdinaryDataTable = getTrackingHoursRow(trackingHourModels.where
    (element => element.type === TimeTrackingTypeEnum.Extraordinary
        && element.status === TrackingHoursStateEnum.Template).toList(), trackingHoursProjectModels, userId, newStartDate);

    newWaitingForApprovalDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.status === TrackingHoursStateEnum.WaitingForApproval).toList(),
        trackingHoursProjectModels, userId, newStartDate);

    newApprovedDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.status === TrackingHoursStateEnum.Approved).toList(),
        trackingHoursProjectModels, userId, newStartDate);

    return {
        newExtraOrdinaryDataTable: newExtraOrdinaryDataTable,
        newOrdinaryDataTable: newOrdinaryDataTable,
        newApprovedDataTable: newApprovedDataTable,
        newWaitingForApprovalDataTable: newWaitingForApprovalDataTable,
        hoursHasBeenSent: hoursHasBeenSent
    } as IInitializeTrackingHoursResult;
}