import {Credentials} from "../api/Credentials";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import {usersService} from "../api/UsersService";


export default class Identity {
    protected account: AccountInfo
    protected accessToken: string
    protected authenticationResult: AuthenticationResult;
    public credentials: Credentials

    constructor(tokenResponse: AuthenticationResult) {
        this.account = tokenResponse.account;
        this.accessToken = tokenResponse.accessToken;


        this.credentials = new Credentials();
        if (this.account.idTokenClaims !== undefined) {
            let idTokenClaims = this.account.idTokenClaims as object;
            let claims = Object.values(idTokenClaims);
            let keys = Object.keys(idTokenClaims);
            // Storage Email
            this.credentials.setAuthenticated();
            let index = keys.indexOf('roles');
            if (index !== -1) {
                let userRoles = claims[index] as string[];
                for (const userRole of userRoles) {
                    this.credentials.addRoles(userRole)
                }
            }

            // Storage email
            index = keys.indexOf('preferred_username');
            this.credentials.email = claims[index] as string;
        }
    }

    get emailAddress() {
        return this.account;
    }

    get name() {
        return this.account.name;
    }

    get idToken() {
        return this.accessToken;
    }

}