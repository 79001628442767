import {Box, Button, Grid, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import React, {ChangeEvent, useState} from "react";
import {useDispatch} from "react-redux";
import {genericNotificationThunk} from "../../redux/notification";
import {
    getSecurityReflectionSampleThunk,
    uploadSecurityReflectionFileThunk
} from "../../redux/securityReflection";
import {margin, padding} from "../../styles/styleConstants";

export const SecurityReflectionAdminComponent = () => {
    const dispatch = useDispatch()

    async function getSampleCallback() {
        await dispatch(getSecurityReflectionSampleThunk)
    }


    const uploadSecurityReflection = (event: ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files[0];
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            let base64 = reader.result as string
            let isLoaded = true
            let fileName = file.name

            // Check if file is an csv
            let extension = fileName.split('.').pop();

            if (extension != 'csv') {
                await dispatch(genericNotificationThunk(Strings.InvalidFileExtension))
                return;
            }

            await dispatch(uploadSecurityReflectionFileThunk(base64))
            // Start the upload

        }
    }

    return <>
        <Grid container direction='row' justify='space-between'>
            <Grid item>
                <Box p={padding} m={margin}>
                    <Typography variant='h6'>{Strings.UpdateList}</Typography>
                </Box>
            </Grid>

            <Grid item>
                <Box p={padding} m={margin}>
                    <Button
                        variant="contained"
                        component="label">
                        {Strings.UploadSecurityReflectionFile}
                        <input onChange={uploadSecurityReflection}
                               type="file"
                               accept='.csv'
                               hidden
                        />
                    </Button>
                </Box>
            </Grid>

            <Grid item>
                <Box m={margin} p={padding}>
                    <Button variant='contained' onClick={getSampleCallback}>{Strings.GetSample}</Button>
                </Box>
            </Grid>
        </Grid>
    </>
}