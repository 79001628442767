import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ProgressState {
    isOpen: boolean,
    text?: string,
    isIndeterminate: boolean,
    progress: number
}

export interface OpenProgressPayload {
    text?: string
}

const progressBarInitialState: ProgressState = {
    isIndeterminate: true,
    isOpen: false,
    progress: 0
}

export const progress = createSlice({
    name: "progress",
    initialState: progressBarInitialState,
    reducers: {
        openProgressBar: (state: ProgressState, action: PayloadAction<OpenProgressPayload>) => {
            state.isOpen = true;
            state.text = action.payload.text;
            return state;
        },
        closeProgressBar: (state: ProgressState) => {
            state.isOpen = false;
            state.isIndeterminate = true;
            state.text = ''
            state.progress = 0
            return state;
        }
    },
});