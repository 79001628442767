import React, {useEffect, useState} from 'react'
import {loadUserProfileThunk, openAddHolidaysDialogThunk} from "../../redux/userHolidays";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {IUserModel} from "../../api/IUserModel";
import {IUserHolidayModel} from "../../api/IUserHolidayModel";
import {getDateFromString, getDateString} from "../../utils/utils";
import {loadUsersThunk, updateCurrentUser} from "../../redux/user";
import {progress} from "../../redux/progress";
import {getHolidayTypeString, HolidayTypeEnum} from "../../api/HolidayTypeEnum";
import {AddUserHolidayDaysDialogComponent} from "../Dialogs/AddUserHolidayDays/AddUserHolidayDaysDialogComponent";
import {Add, CloudDownloadOutlined} from "@material-ui/icons";
import {userHolidayService} from "../../api/UserHolidayService";

interface IUserHolidayDisplayItem {
    id: string;

    rut: string;
    userId: string,
    email: string,
    name: string,
    surname: string,

    hasUserHolidayProfile: boolean
    incorporationDate: Date
    monthsOfExperienceAtIncorporation: number
    registerDays: IUserHolidayRegisterDisplayItem[]
    availableDays: number
    progressiveDays: number,
    totalHolidayDaysFromBeginning: number,


}

interface IUserHolidayRegisterDisplayItem {
    id: string
    registerDateTime: Date
    daysUsed: number
    fromDate: Date
    toDate: Date
    comment: string,
    accepted: boolean,
    acceptedString: string,
    holidayType: HolidayTypeEnum,
    holidayTypeString: string,
    approvingUserEmail: string
}

export const UserHolidayComponent = () => {
    const dispatch = useDispatch();

    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {
        hasUserProfile,
        userProfile,
        selectedUserForHolidays,
        isAddingUserHolidays
    } = useSelector((state: RootState) => state.userHolidays)
    const {users, currentUser} = useSelector((state: RootState) => state.user)
    const {identity} = useSelector((state: RootState) => state.authentication)

    const [userHolidayDisplayItem, setUserHolidayDisplayItem] = useState<IUserHolidayDisplayItem>()

    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadUserProfileThunk)
        await dispatch(loadUsersThunk)
        await dispatch(updateCurrentUser)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (!isAddingUserHolidays)
            loadData()
    }, [isAddingUserHolidays])

    useEffect(() => {
        if (userProfile && users) {
            setUserHolidayDisplayItem(getUserHolidayDisplayItem(userProfile, users));
        }
    }, [userProfile, users])

    async function getDocument(id: string) {
        await userHolidayService.downloadUserHolidayRegisterDocument(id)
    }

    return <> {!isOpen && <>
        <Typography variant='h6'>{Strings.Holidays}</Typography>
        {!hasUserProfile || !currentUser || !currentUser.hasSignature && <>{Strings.YouDontHaveHolidayProfileOrSignature}</>}
        {hasUserProfile && currentUser && currentUser.hasSignature && userHolidayDisplayItem && <>
            <p><a href="https://www.dt.gob.cl/portal/1628/w3-article-60194.html#:~:text=El%20beneficio%20de%20feriado%20progresivo%20o%20“vacaciones%20progresivas”%2C,cotizaciones%20previsionales%29%20para%20el%20actual%20o%20anteriores%20empleadores." target="_blank">¿En qué consiste el derecho al feriado progresivo?</a></p>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{Strings.IncorporationDate}</b></TableCell>
                            <TableCell><b>{Strings.MonthsOfExperienceAtIncorporation}</b></TableCell>
                            <TableCell><b>{Strings.AvailableDays}</b></TableCell>
                            <TableCell><b>{Strings.ProgressiveDays}</b></TableCell>
                            <TableCell><b>{Strings.TotalHolidaysFromIncorporation}</b></TableCell>
                            <TableCell><b>{Strings.RUT}</b></TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='mainRow'>
                            <TableCell>{getDateString(userHolidayDisplayItem.incorporationDate)}</TableCell>
                            <TableCell>{userHolidayDisplayItem.monthsOfExperienceAtIncorporation}</TableCell>
                            <TableCell>{userHolidayDisplayItem.availableDays.toFixed(2)}</TableCell>
                            <TableCell>{userHolidayDisplayItem.progressiveDays.toFixed(2)}</TableCell>
                            <TableCell>{userHolidayDisplayItem.totalHolidayDaysFromBeginning.toFixed(2)}</TableCell>
                            <TableCell>{userHolidayDisplayItem.rut}</TableCell>
                            <TableCell><Tooltip title={Strings.AddHolidays}>
                                <IconButton onClick={() => {
                                    dispatch(openAddHolidaysDialogThunk(identity.credentials.id))
                                }}><Add/></IconButton>
                            </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{Strings.From}</b></TableCell>
                            <TableCell><b>{Strings.To}</b></TableCell>
                            <TableCell><b>{Strings.BusinessDaysUsed}</b></TableCell>
                            <TableCell><b>{Strings.ApprovingUser}</b></TableCell>
                            <TableCell><b>{Strings.Comments}</b></TableCell>
                            <TableCell><b>{Strings.Type}</b></TableCell>
                            <TableCell><b>{Strings.Accepted}</b></TableCell>
                            <TableCell><b>{Strings.Signatures}</b></TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userHolidayDisplayItem.registerDays.map((row) => (
                            <TableRow key={row.registerDateTime.toUTCString()}>
                                <TableCell>{getDateString(row.fromDate)}</TableCell>
                                <TableCell>{getDateString(row.toDate)}</TableCell>
                                <TableCell>{row.daysUsed}</TableCell>
                                <TableCell>{row.approvingUserEmail}</TableCell>
                                <TableCell>{row.comment}</TableCell>
                                <TableCell>{row.holidayTypeString}</TableCell>
                                <TableCell>{row.acceptedString}</TableCell>
                                <TableCell>
                                    {row.accepted && <>
                                        <Tooltip title={Strings.DownloadAvailableDocument}>
                                            <IconButton onClick={() => getDocument(row.id)}>
                                                <CloudDownloadOutlined/>
                                            </IconButton>
                                        </Tooltip>
                                    </>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {selectedUserForHolidays && <AddUserHolidayDaysDialogComponent isAdmin={false}/>}
        </>}
    </>}
    </>
}


function getUserHolidayDisplayItem(userHoliday: IUserHolidayModel, users: IUserModel[]): IUserHolidayDisplayItem {

    let user = users.find(s => s.id === userHoliday.user.id);

    // Create base empty
    let userHolidayRowDataItem: IUserHolidayDisplayItem = {
        id: "",
        rut: '',
        progressiveDays: 0,
        totalHolidayDaysFromBeginning: 0,
        userId: user.id,
        email: user.email,
        name: user.name,
        surname: user.surname,
        hasUserHolidayProfile: false,
        incorporationDate: undefined,
        monthsOfExperienceAtIncorporation: 0,
        availableDays: 0,
        registerDays: []
    }
    // Find an item
    if (userHoliday) {
        userHolidayRowDataItem.id = userHoliday.id;
        userHolidayRowDataItem.hasUserHolidayProfile = true;
        userHolidayRowDataItem.monthsOfExperienceAtIncorporation = userHoliday.monthsOfExperienceAtIncorporation
        userHolidayRowDataItem.incorporationDate = getDateFromString(userHoliday.incorporationDate)
        userHolidayRowDataItem.availableDays = userHoliday.availableDays
        userHolidayRowDataItem.progressiveDays = userHoliday.progressiveDays
        userHolidayRowDataItem.totalHolidayDaysFromBeginning = userHoliday.totalHolidayDaysFromBeginning
        userHolidayRowDataItem.rut = `${userHoliday.rut}-${userHoliday.verificationNumber}`

        for (let usedDayRegister of userHoliday.userHolidayRegisters) {
            let approvingUser = users.find(value => value.id === usedDayRegister.approvingUser.id)
            userHolidayRowDataItem.registerDays.push({
                comment: usedDayRegister.comment,
                daysUsed: usedDayRegister.daysUsed,
                fromDate: getDateFromString(usedDayRegister.fromDate),
                toDate: getDateFromString(usedDayRegister.toDate),
                registerDateTime: getDateFromString(usedDayRegister.registerDateTime),
                accepted: usedDayRegister.isAccepted,
                acceptedString: usedDayRegister.isAccepted ? Strings.Yes : Strings.Waiting,
                holidayType: usedDayRegister.holidayType,
                holidayTypeString: getHolidayTypeString(usedDayRegister.holidayType),
                approvingUserEmail: approvingUser.email,
                id: usedDayRegister.id
            })
        }
    }
    return userHolidayRowDataItem;
}
