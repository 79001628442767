import React from 'react';
import {ProjectComponentRowModel} from "./ProjectsComponentRowModel";
import MaterialTable, {Column} from "material-table";
import {GetProjectStatusString, GetProjectTypeString} from "../../utils/utils";
import {Strings} from '../../Resources/Strings';
import {useHistory} from "react-router";
import {CheckBoxOutlined, PictureAsPdf} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {loadProjectsThunk, project} from "../../redux/project";
import {RootState} from "../../redux/store";
import {progress} from "../../redux/progress";
import {projectService} from "../../api/ProjectsService";
import {List} from "linq-typescript";
import {genericNotificationThunk} from "../../redux/notification";
import {authentication} from "../../redux/authentication";

function getTemplateColumns(): Column<ProjectComponentRowModel>[] {
    let columns: Column<ProjectComponentRowModel>[] = [];
    columns.push({
        title: Strings.ProjectCode,
        field: 'code',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: Strings.Name,
        field: 'name',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: Strings.Type,
        field: 'type',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: Strings.Status,
        field: 'status',
        type: 'string',
        editable: 'never'
    });

    return columns;
}

export const ProjectsComponent = () => {
    const history = useHistory();

    const {projects} = useSelector((state: RootState) => state.project)
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {identity} = useSelector((state: RootState) => state.authentication)

    const dispatch = useDispatch()

    const projectDataTable = projects.map(projectModel => {
        return {
            id: projectModel.id,
            status: GetProjectStatusString(projectModel.status),
            type: GetProjectTypeString(projectModel.type),
            code: projectModel.code,
            name: projectModel.name
        } as ProjectComponentRowModel
    })

    React.useEffect(() => {
        async function fetchData() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadProjectsThunk)
            dispatch(progress.actions.closeProgressBar())
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchData()
    }, []);

    return <>{!isOpen && <MaterialTable
        title={Strings.Projects}
        columns={getTemplateColumns()}
        data={projectDataTable}
        options={{
            actionsColumnIndex: -1
        }}
        actions={[{
            icon: 'group',
            tooltip: `${Strings.Members}`,
            onClick: async (event, rowData: ProjectComponentRowModel) => {
                let projectModel = projects.find(project => project.id === rowData.id)
                await dispatch(project.actions.setSelectedProject(projectModel))
                history.push(`/addMember`)
            }
        }, {
            icon: CheckBoxOutlined,
            tooltip: `${Strings.Compromises}`,
            onClick: async (event, rowData: ProjectComponentRowModel) => {
                let projectModel = projects.find(project => project.id === rowData.id)
                let currentProject = await projectService.getProjectById(projectModel.id)
                let members = new List(currentProject.members)

                if (members.any(element => element.id == identity.credentials.id)) {
                    await dispatch(project.actions.setSelectedProject(projectModel))
                    history.push(`/projectCompromises`)
                } else {
                    await dispatch(genericNotificationThunk(`${Strings.YouDontHaveAccess}`))
                }
            }
        }, {
            icon: PictureAsPdf,
            tooltip: `${Strings.ActivityRecords}`,
            onClick: async (event, rowData: ProjectComponentRowModel) => {
                let projectItem = projects.find(s => s.id === rowData.id);
                await dispatch(project.actions.setSelectedProject(projectItem))
                history.push('/activityRecordsByProject')
            }
        }]}
    />}
    </>
}
