import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IActivityRecord} from "./IActivityRecord";
import {stringify} from "querystring";
import {IUpdateActivityRecordModel} from "./IUpdateActivityRecordModel";

class ActivityRecordsService extends BaseService {
    async addActivityRecords(title: string, projectId: string) {
        const url = `${apiUrl}activityRecord?projectId=${projectId}&title=${title}`;
        let token = await this.getToken();
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        let response = await fetch(url, request);
        return (await response.json()) as IActivityRecord
    }

    async getActivityRecords() {
        const url = `${apiUrl}activityRecord`;
        let token = await this.getToken();
        const request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        let response = await fetch(url, request);
        return (await response.json()) as IActivityRecord[]
    }

    async updateActivityRecord(id: string, title: string, date: Date, durationHours: number, contentHtml: string) {
        const url = `${apiUrl}activityRecord`;
        let token = await this.getToken();
        let model = {
            id: id,
            contentHtml: contentHtml,
            durationHours: durationHours,
            date: date.toISOString(),
            title: title
        } as IUpdateActivityRecordModel
        const request = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(model),
        }
        let response = await fetch(url, request);
        return (await response.json()) as IActivityRecord[]
    }

    async addExternalUser(activityRecordId: string, externalUserId: string) {
        const url = `${apiUrl}activityRecord/externalUser?activityRecordId=${activityRecordId}&externalUserId=${externalUserId}`;
        let token = await this.getToken();
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        let response = await fetch(url, request);
        return (await response.json()) as IActivityRecord[]
    }

    async removeExternalUser(activityRecordId: string, externalUserId: string) {
        const url = `${apiUrl}activityRecord/externalUser?activityRecordId=${activityRecordId}&externalUserId=${externalUserId}`;
        let token = await this.getToken();
        const request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        await fetch(url, request);
    }

    async addBminingUser(activityRecordId: string, bminingUserId: string) {
        const url = `${apiUrl}activityRecord/bminingUser?activityRecordId=${activityRecordId}&bminingUserId=${bminingUserId}`;
        let token = await this.getToken();
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        let response = await fetch(url, request);
        return (await response.json()) as IActivityRecord[]
    }

    async removeBminingUser(activityRecordId: string, bminingUserId: string) {
        const url = `${apiUrl}activityRecord/bminingUser?activityRecordId=${activityRecordId}&bminingUserId=${bminingUserId}`;
        let token = await this.getToken();
        const request = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }
        await fetch(url, request);
    }

}

export const activityRecordsService = new ActivityRecordsService();