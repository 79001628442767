import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import React, {useEffect, useState} from "react";
import {IUserModel} from "../../../api/IUserModel";
import {loadUsersThunk} from "../../../redux/user";
import {isEmpty} from "../../../utils/utils";
import {addProjectCompromiseThunk, compromise} from "../../../redux/compromise";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {margin, padding} from "../../../styles/styleConstants";
import {Autocomplete} from "@material-ui/lab";
import {LoadingButton} from "../../DataTemplates/LoadingButton/LoadingButton";

export const AddProjectCompromiseDialogComponent = () => {
    const dispatch = useDispatch();

    const {users} = useSelector((state: RootState) => state.user);
    const {isAddingProjectCompromise} = useSelector((state: RootState) => state.compromise);
    const {selectedProject} = useSelector((state: RootState) => state.project);


    const [title, setTitle] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<IUserModel>();
    const [availableUsers, setAvailableUsers] = useState<IUserModel[]>([])

    const [isLoading, setIsLoading] = useState<boolean>()
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(false)

    useEffect(() => {
        setSelectedUser(null)
    }, [isAddingProjectCompromise])

    useEffect(() => {
        if (isEmpty(title) || selectedUser === null || selectedUser === undefined)
            setIsYesAvailable(false)
        else setIsYesAvailable(true)
    }, [title, selectedUser]);

    useEffect(() => {
        if (users && selectedProject) {
            let validUsers: IUserModel[] = []
            if (selectedProject.isOrganizational)
                setAvailableUsers(users)
            else {
                for (let member of selectedProject.members) {
                    let user = users.find(value => value.id === member.id)
                    validUsers.push(user)
                }
                setAvailableUsers(validUsers)
            }
        }
    }, [users, selectedProject])

    const handleNo = () => {
        dispatch(compromise.actions.closeAddProjectCompromise());
    }

    const handleYes = async () => {
        setIsLoading(true)
        await dispatch(addProjectCompromiseThunk(selectedUser.id, title, selectedProject.id))
        setIsLoading(false)
    }

    const titleChangedCallback = (event: any | HTMLInputElement) => {
        setTitle(event.target.value)
    }

    return <>
        <Dialog open={isAddingProjectCompromise} onClose={handleNo} fullWidth>
            <DialogTitle>{Strings.NewCompromise} {selectedProject && selectedProject.code}</DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.Title} onChange={titleChangedCallback} fullWidth/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          value={selectedUser}
                                          onChange={(event: any, newValue: IUserModel | null) => {
                                              setSelectedUser(newValue)
                                          }}
                                          id="user-autocomplete"
                                          options={availableUsers}
                                          getOptionLabel={(option) => `${option.email}`}
                                          renderInput={(params) =>
                                              <TextField {...params} label={Strings.Owner}
                                                         variant="outlined"/>}/>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleYes} inProgress={isLoading} text={Strings.Yes}
                               variant='contained' disabled={isLoading || !isYesAvailable} color='primary'
                               progressBarColor='secondary'/>
                <Button onClick={handleNo} color="secondary" variant='contained' disabled={isLoading}>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}