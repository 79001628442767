import React, {useEffect, useState} from 'react'
import {Box, ButtonGroup, Grid, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactQuill from "react-quill";
import {Strings} from "../../Resources/Strings";
import {margin, padding} from "../../styles/styleConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import moment from "moment";
import {activityRecord, updateActivityRecordThunk} from "../../redux/activityRecord";
import {projectService} from "../../api/ProjectsService";
import {project} from "../../redux/project";
import {progress} from "../../redux/progress";
import {LoadingButton} from "../DataTemplates/LoadingButton/LoadingButton";
import {PhotoSizeSelectLarge, PictureAsPdf, VerifiedUserOutlined} from "@material-ui/icons";
import {compromise} from "../../redux/compromise";
import {ActivityRecordExternalUsersDialogComponent} from "../Dialogs/ActivityRecordExternalUsers/ActivityRecordExternalUsersDialogComponent";
import {ActivityRecordBminingUsersDialogComponent} from "../Dialogs/ActivityRecordUsers/ActivityRecordBminingUsersDialogComponent";
import {AddActivityRecordCompromiseDialogComponent} from "../Dialogs/AddActivityRecordCompromise/AddActivityRecordCompromiseDialogComponent";
import {loadExternalUsersThunk} from "../../redux/externalUser";
import {loadUsersThunk} from "../../redux/user";
import {EditCompromiseDialogComponent} from "../Dialogs/EditCompromise/EditCompromiseDialogComponent";

export const ActivityRecordEditComponent = () => {

    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {selectedActivityRecord} = useSelector((state: RootState) => state.activityRecord);
    const {externalUsers} = useSelector((state: RootState) => state.externalUser);
    const {selectedCompromise} = useSelector((state:RootState) => state.compromise)
    const {users} = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch()

    const [projectTitle, setProjectTitle] = useState<string>('')

    const [title, setTitle] = useState<string>('');
    const [date, setDate] = useState<Date>()
    const [durationHours, setDurationHours] = useState<number>()
    const [contentHtml, setContentHtml] = useState<string>()

    const [isSaveAvailable, setIsSaveAvailable] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>()

    useEffect(() => {
        async function fetchData() {
            let projectItem = (await projectService.getProjectById(selectedActivityRecord.project.id))
            await dispatch(project.actions.setSelectedProject(projectItem))
            await dispatch(loadExternalUsersThunk)
            await dispatch(loadUsersThunk)
            await setProjectTitle(`${Strings.ActivityRecord} ${projectItem.code} ${projectItem.name}`)
            await dispatch(progress.actions.closeProgressBar())
        }

        dispatch(progress.actions.openProgressBar({}))
        setDurationHours(selectedActivityRecord.durationHours)
        setDate(moment(selectedActivityRecord.date).toDate())
        setTitle(selectedActivityRecord.title)
        setContentHtml(selectedActivityRecord.contentHtml)
        // noinspection JSIgnoredPromiseFromCall
        fetchData()
    }, [])


    const onContentHtmlCallback = (value) => {
        setContentHtml(value)
        setIsSaveAvailable(true)
    }

    const onDateChangeCallback = (date: Date) => {
        setDate(date)
        setIsSaveAvailable(true)
    }

    const onChangeTitleCallback = (event: any | HTMLInputElement) => {
        setTitle(event.target.value);
        setIsSaveAvailable(true)
    }

    const onChangeDurationHours = (event: any | HTMLInputElement) => {
        setDurationHours(event.target.value);
        setIsSaveAvailable(true)
    }

    const onSaveCallback = async () => {
        setIsSaveAvailable(false)
        setIsSaving(true)
        let id = selectedActivityRecord.id;
        await dispatch(updateActivityRecordThunk(id, title, date, durationHours, contentHtml))
        setIsSaving(false)
    }

    const onAddCompromiseCallback = async () => {
        await dispatch(activityRecord.actions.setSelectedActivityRecord(selectedActivityRecord))
        dispatch(compromise.actions.openAddActivityRecordCompromise())
    }

    const onBminingUserCallback = async () => {
        await dispatch(activityRecord.actions.setSelectedActivityRecord(selectedActivityRecord))
        dispatch(activityRecord.actions.openEditActivityRecordBminingUsers())
    }

    const onExternalUserCallback = async () => {
        await dispatch(activityRecord.actions.setSelectedActivityRecord(selectedActivityRecord))
        dispatch(activityRecord.actions.openEditActivityRecordExternalUsers())
    }

    return <>
        {!isOpen && <>
            <Typography variant='h6'>{projectTitle}</Typography>
            <Grid direction='row' container alignItems='center'>
                <Grid item xs>
                    <Box m={margin} p={padding}>
                        <TextField value={title} onChange={onChangeTitleCallback} label={Strings.Title}
                                   variant='outlined' fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs='auto'>
                    <Box m={margin} p={padding}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker value={date} onChange={onDateChangeCallback}
                                        label={Strings.Date}
                                        format="dd/MM/yyyy"/>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>

                <Grid item xs='auto'>
                    <Box m={margin} p={padding}>
                        <TextField type='number' value={durationHours} onChange={onChangeDurationHours}
                                   label={Strings.DurationHours} fullWidth/>
                    </Box>
                </Grid>

                <Grid item xs='auto'>
                    <ButtonGroup>
                        <Tooltip title={Strings.BminingUser}>
                            <IconButton color='secondary' aria-label={Strings.BminingUser}
                                        onClick={onBminingUserCallback}>
                                <VerifiedUserOutlined/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Strings.ExternalUser}>
                            <IconButton color='secondary'
                                        onClick={onExternalUserCallback}>
                                <PhotoSizeSelectLarge/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Strings.Compromises}>
                            <IconButton color='secondary' onClick={onAddCompromiseCallback}>
                                <PictureAsPdf/>
                            </IconButton>
                        </Tooltip>

                        <LoadingButton onClick={onSaveCallback} color='primary' variant='contained'
                                       inProgress={isSaving} text={Strings.Save}
                                       disabled={!isSaveAvailable || isSaving}/>
                    </ButtonGroup>
                </Grid>


                <Grid item xs='auto'>
                    <Box m={margin} p={padding}>

                    </Box>
                </Grid>
            </Grid>
            <Box m={margin} p={padding}>
                <ReactQuill value={contentHtml} onChange={onContentHtmlCallback}
                            theme='snow' placeholder={Strings.Notes}/>
            </Box>

            <ActivityRecordExternalUsersDialogComponent selectedActivityRecord={selectedActivityRecord}
                                                        externalUsers={externalUsers}/>
            <ActivityRecordBminingUsersDialogComponent selectedActivityRecord={selectedActivityRecord} users={users}/>
            <AddActivityRecordCompromiseDialogComponent users={users} activityRecord={selectedActivityRecord}/>
            <EditCompromiseDialogComponent selectedCompromise={selectedCompromise}/>
        </>}
    </>
}