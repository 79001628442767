import React, {useEffect, useState} from "react";
import {loadUsersThunk} from "../../redux/user";
import {useDispatch, useSelector} from "react-redux";
import {progress} from "../../redux/progress";
import {
    deleteUserHolidayRegisterThunk, downloadUserHolidayRegisterDocumentThunk,
    loadUserHolidaysThunk,
    openAddHolidaysDialogThunk,
    openCreateUserHolidayProfileThunk
} from "../../redux/userHolidays";
import {IUserModel} from "../../api/IUserModel";
import {IUserHolidayModel} from "../../api/IUserHolidayModel";
import {getDateFromString, getDateString} from "../../utils/utils";
import {RootState} from "../../redux/store";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {margin, padding} from "../../styles/styleConstants";
import MaterialTable, {Column} from "material-table";
import {Add, CloudDownload, Delete, WbSunnyOutlined} from "@material-ui/icons";
import {
    CreateUserHolidayProfileDialogComponent
} from "../Dialogs/CreateUserHolidayProfile/CreateUserHolidayProfileDialogComponent";
import {AddUserHolidayDaysDialogComponent} from "../Dialogs/AddUserHolidayDays/AddUserHolidayDaysDialogComponent";
import {getHolidayTypeString, HolidayTypeEnum} from "../../api/HolidayTypeEnum";

export const UserHolidayAdminComponent = () => {
    const dispatch = useDispatch()

    const {users} = useSelector((state: RootState) => state.user)
    const {userHolidays, selectedUserForHolidays} = useSelector((state: RootState) => state.userHolidays)
    const {isOpen} = useSelector((state: RootState) => state.progress)

    const [userHolidaysDisplayItems, setUserHolidaysDisplayItems] = useState<IUserHolidayRowDataItem[]>([])

    useEffect(() => {
        async function loadData() {
            await dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadUsersThunk)
            await dispatch(loadUserHolidaysThunk)
            await dispatch(progress.actions.closeProgressBar())
        }

        loadData()
    }, [])

    useEffect(() => {
        if (users && userHolidays) {
            setUserHolidaysDisplayItems(getUserHolidayDisplayRowDataItems(users, userHolidays))
        }
    }, [users, userHolidays])

    async function deleteCallback(userHolidayRegisterId: string) {
        await dispatch(deleteUserHolidayRegisterThunk(userHolidayRegisterId))
    }

    async function downloadCallback(userHolidayRegisterId: string) {
        await dispatch(downloadUserHolidayRegisterDocumentThunk(userHolidayRegisterId))
    }


    return <>
        <Box m={margin} p={padding}>
            <MaterialTable columns={getColumns()} title={Strings.Holidays}
                           isLoading={isOpen} data={userHolidaysDisplayItems} options={{
                paging: false,
                filtering: true,
                actionsColumnIndex: -1
            }}
                           detailPanel={(rowData: IUserHolidayRowDataItem) => {
                               return <>{rowData.registerDays.length > 0 && <TableContainer>
                                   <Table>
                                       <TableHead>
                                           <TableRow>
                                               <TableCell><b>{Strings.From}</b></TableCell>
                                               <TableCell><b>{Strings.To}</b></TableCell>
                                               <TableCell><b>{Strings.BusinessDaysUsed}</b></TableCell>
                                               <TableCell><b>{Strings.ApprovingUser}</b></TableCell>
                                               <TableCell><b>{Strings.Comments}</b></TableCell>
                                               <TableCell><b>{Strings.Type}</b></TableCell>
                                               <TableCell><b>{Strings.Accepted}</b></TableCell>
                                               <TableCell/>
                                           </TableRow>
                                       </TableHead>
                                       <TableBody>
                                           {rowData.registerDays.map((row) => (
                                               <TableRow key={row.registerDateTime.toUTCString()}>
                                                   <TableCell>{getDateString(row.fromDate)}</TableCell>
                                                   <TableCell>{getDateString(row.toDate)}</TableCell>
                                                   <TableCell>{row.daysUsed}</TableCell>
                                                   <TableCell>{row.approvingUserEmail}</TableCell>
                                                   <TableCell>{row.comment}</TableCell>
                                                   <TableCell>{row.holidayTypeString}</TableCell>
                                                   <TableCell>{row.acceptedString}</TableCell>
                                                   <TableCell>
                                                       <Tooltip title={Strings.Delete}>
                                                           <IconButton onClick={() => {
                                                               deleteCallback(row.id)
                                                           }}>
                                                               <Delete/>
                                                           </IconButton>
                                                       </Tooltip>
                                                       {row.accepted && <>
                                                           <Tooltip title={Strings.DownloadAvailableDocument}>
                                                               <IconButton onClick={() => {
                                                                   downloadCallback(row.id)
                                                               }}>
                                                                   <CloudDownload/>
                                                               </IconButton>
                                                           </Tooltip>
                                                       </>}
                                                   </TableCell>
                                               </TableRow>
                                           ))}
                                       </TableBody>
                                   </Table>
                               </TableContainer>}
                               </>
                           }}
                           actions={[
                               rowData => ({
                                   icon: Add,
                                   tooltip: Strings.CreateUserHolidayProfiles,
                                   onClick: async (event, rowData: IUserHolidayRowDataItem) => {
                                       dispatch(openCreateUserHolidayProfileThunk(rowData.userId))
                                   },
                                   hidden: rowData.hasUserHolidayProfile
                               }),
                               rowData => ({
                                   icon: WbSunnyOutlined,
                                   tooltip: Strings.AddHolidays,
                                   onClick: async (event, rowData: IUserHolidayRowDataItem) => {
                                       dispatch(openAddHolidaysDialogThunk(rowData.userId))
                                   },
                                   hidden: !rowData.hasUserHolidayProfile
                               })]}
            />
        </Box>
        {
            selectedUserForHolidays &&
            <>
                <CreateUserHolidayProfileDialogComponent/>
                <AddUserHolidayDaysDialogComponent isAdmin={true}/>
            </>
        }
    </>
}

interface IUserHolidayRowDataItem {
    userId: string,
    email: string,
    name: string,
    surname: string,


    hasUserHolidayProfile: boolean
    incorporationDate: Date
    monthsOfExperienceAtIncorporation: number
    registerDays: IUserHolidayRegisterDisplayItem[]
    availableDays: number
    progressiveDays: number
    totalHolidayDaysFromBeginning: number

}

interface IUserHolidayRegisterDisplayItem {
    id: string
    registerDateTime: Date
    daysUsed: number
    fromDate: Date
    toDate: Date
    comment: string
    accepted: boolean,
    acceptedString: string,
    holidayType: HolidayTypeEnum,
    holidayTypeString: string,
    approvingUserEmail: string
}


function getUserHolidayDisplayRowDataItems(users: IUserModel[], userHolidays: IUserHolidayModel[]): IUserHolidayRowDataItem[] {
    let outputItems: IUserHolidayRowDataItem[] = []
    for (const user of users) {
        // Create base empty
        
        // cadan 24-01-2023 se agrega if para que solo muestre personal activo
        if (user.isActive)
        {
            let outputItem: IUserHolidayRowDataItem = {
                progressiveDays: 0,
                totalHolidayDaysFromBeginning: 0,
                userId: user.id,
                email: user.email,
                name: user.name,
                surname: user.surname,
                hasUserHolidayProfile: false,
                incorporationDate: undefined,
                monthsOfExperienceAtIncorporation: 0,
    
                availableDays: 0,
                registerDays: []
            }
            // Find an item
            let userHoliday = userHolidays.find(value => value.user.id === user.id)
            if (userHoliday) {
                outputItem.hasUserHolidayProfile = true;
                outputItem.monthsOfExperienceAtIncorporation = userHoliday.monthsOfExperienceAtIncorporation
                outputItem.incorporationDate = getDateFromString(userHoliday.incorporationDate)
                outputItem.availableDays = userHoliday.availableDays
                outputItem.progressiveDays = userHoliday.progressiveDays;
                outputItem.totalHolidayDaysFromBeginning = userHoliday.totalHolidayDaysFromBeginning;
    
                for (let usedDayRegister of userHoliday.userHolidayRegisters) {
                    let approvingUserModel = users.find(value => value.id === usedDayRegister.approvingUser.id);
                    outputItem.registerDays.push({
                        id: usedDayRegister.id,
                        comment: usedDayRegister.comment,
                        daysUsed: usedDayRegister.daysUsed,
                        fromDate: getDateFromString(usedDayRegister.fromDate),
                        toDate: getDateFromString(usedDayRegister.toDate),
                        registerDateTime: getDateFromString(usedDayRegister.registerDateTime),
                        accepted: usedDayRegister.isAccepted,
                        acceptedString: usedDayRegister.isAccepted ? Strings.Yes : Strings.Waiting,
                        holidayType: usedDayRegister.holidayType,
                        holidayTypeString: getHolidayTypeString(usedDayRegister.holidayType),
                        approvingUserEmail: approvingUserModel.email
                    })
                }
            }
            outputItems.push(outputItem)
        }

        }
        
    return outputItems;
}

function getColumns(): Column<IUserHolidayRowDataItem>[] {
    let columns: Column<IUserHolidayRowDataItem>[] = []

    columns.push({
        title: Strings.Name,
        editable: 'never',
        field: 'name'
    })
    columns.push({
        title: Strings.Surname,
        editable: 'never',
        field: 'surname'
    })

    columns.push({
        title: Strings.Email,
        editable: 'never',
        field: 'email'
    })

    columns.push({
        title: Strings.IncorporationDate,
        type: 'date',
        editable: 'never',
        field: 'incorporationDate'
    })

    columns.push({
        title: Strings.MonthsOfExperienceAtIncorporation,
        editable: 'never',
        field: 'monthsOfExperienceAtIncorporation',
        render: (data: IUserHolidayRowDataItem) => {
            if (!data.hasUserHolidayProfile)
                return <></>;
            return <>{data.monthsOfExperienceAtIncorporation}</>
        }
    })

    columns.push({
        title: Strings.AvailableDays,
        type: 'numeric',
        editable: 'never',
        field: 'availableDays',
        render: (data: IUserHolidayRowDataItem) => {
            if (!data.hasUserHolidayProfile)
                return <></>;
            return <>{data.availableDays.toFixed(2)}</>
        }
    })


    columns.push({
        title: Strings.ProgressiveDays,
        type: 'numeric',
        editable: 'never',
        field: 'progressiveDays',
        render: (data: IUserHolidayRowDataItem) => {
            if (!data.hasUserHolidayProfile)
                return <></>;
            return <>{data.progressiveDays.toFixed(2)}</>
        }
    })

    columns.push({
        title: Strings.TotalHolidaysFromIncorporation,
        type: 'numeric',
        editable: 'never',
        field: 'totalHolidayDaysFromBeginning',
        render: (data: IUserHolidayRowDataItem) => {
            if (!data.hasUserHolidayProfile)
                return <></>;
            return <>{data.totalHolidayDaysFromBeginning.toFixed(2)}</>
        }
    })
    return columns;
}
