import {Box, Link, Typography} from "@material-ui/core";
import {margin} from "../../styles/styleConstants";
import {useEffect, useState} from "react";


interface ISgiFile {
    name: string,
    items: ISgiFileItem[]
}

interface ISgiFileItem {
    name: string,
    items: ISgiFileLinkItem[]
}

interface ISgiFileLinkItem {
    name: string,
    link: string
}


export const SgiFilesComponent = () => {

    const [sgiFile, setSgiFile] = useState<ISgiFile>()

    useEffect(() => {
        const newSgiFIle: ISgiFile = {
            "name": "Archivos",
            "items": [
                {
                    "name": "SISTEMA DE GESTION INTEGRADO - SGI",
                    "items": [
                        {
                            "name": "PPT. INFORMACIÓN BÁSICA SGI",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FSiteAssets%2FContenido%2FMODULO%20INDUCCION%20SGI%2Epdf&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FSiteAssets%2FContenido"
                        },
                        {
                            "name": "CERTIFICACIONES BMINING",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F3%2E%2D%20Gesti%C3%B3n%20HSEQ%2F3%2E%2D%20Certificados%20ISO&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0"
                        },
                        {
                            "name": "INDUCCION CARPETA DIGITAL",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?sortField=Orden&isAscending=true&id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Carpeta%20Digital%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n"
                        },
                        //{
                        //    "name": "INFORME DE AUDITORÍA",
                        //    "link": "https://bminingspa.sharepoint.com/:b:/s/BMININGSGI/EfwqRpru5hdOk_nmNrFOJ2oBIw9leI5geSGrbvAxAcillw?e=GiXnZw"
                        //},
                        //{
                        //    "name": "REGISTRO DE EXPOSICIONES DE PROYECTOS 2023",
                        //    "link": "https://bminingspa.sharepoint.com/:x:/r/sites/BMININGSGI/_layouts/15/Doc.aspx?sourcedoc=%7BD1F083FB-8B3E-4BAC-8AB3-54DFBD6831D7%7D&file=Registro%20de%20Exposiciones%20de%20Proyectos%202023.xlsx&action=default&mobileredirect=true"
                       // },
                        //{
                        //    "name": "CALENDARIO AUDITORÍA",
                        //    "link": "#"
                       // },
                        //{
                        //    "name": "CALENDARIO DE AUDITORÍA INTERNA TRIMESTRALES",
                        //    "link": "#"
                        //},
                        //{
                        //    "name": "VIDEO INDUCCIÓN",
                        //    "link": "#"
                        //}
                    ]
                },
                {
                    "name": "RECURSOS HUMANOS - RR-HH",
                    "items": [
                        {
                            "name": "FORMULARIO SEGURO DE SALUD",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F3%2E%20%2D%20Recursos%20Humanos%2FSEGURO%20COMPLEMENTARIO%2FFormulario%20de%20Salud%2Epdf&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F3%2E%20%2D%20Recursos%20Humanos%2FSEGURO%20COMPLEMENTARIO"
                        },
                        {
                            "name": "FORMULARIO SEGURO DENTAL",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F3%2E%20%2D%20Recursos%20Humanos%2FSEGURO%20COMPLEMENTARIO%2FFormulario%20Gastos%20Dentales%2Epdf&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F3%2E%20%2D%20Recursos%20Humanos%2FSEGURO%20COMPLEMENTARIO"
                        },
                        {
                            "name": "OBLIGACIÓN DE INFORMAR COVID-19",
                            "link": "https://bminingspa.sharepoint.com/:x:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7BA76B30D8-F4DB-4C62-A95A-D5C629F5BCE7%7D&file=BM-HQ-RE-039-ver00%20Obligaci%C3%B3n%20de%20Informar%20COVID-19.xlsx&action=default&mobileredirect=true"
                        },
                        {
                            "name": "OBLIGACIÓN DE INFORMAR TELETRABAJO",
                            "link": "https://bminingspa.sharepoint.com/:x:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7BEA059B48-7512-4C35-835F-1ACB573BA157%7D&file=BM-HQ-RE-041-ver00%20Obligaci%C3%B3n%20de%20Informar%20TELETRABAJO.xlsx&action=default&mobileredirect=true"
                        },
                        {
                            "name": "OBLIGACIÓN DE INFORMAR OFICINA",
                            "link": "https://bminingspa.sharepoint.com/:x:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7BBAA98F93-A449-423B-806E-7E18756E73A0%7D&file=BM-HQ-RE-011-ver00%20Obligaci%C3%B3n%20de%20Informar%20OFICINA.xlsx&action=default&mobileredirect=true"
                        },
                        {
                            "name": "TOMA DE CONOCIMIENTO",
                            "link": "https://bminingspa.sharepoint.com/:w:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7B97894D8A-8EB1-4DD5-A59B-AE3006A67BA8%7D&file=BM-AF-RE-007-ver00%20Toma%20de%20conocimiento.docx&action=default&mobileredirect=true"
                        },
                        //{
                        //    "name": "PERFILES DE CARGO",
                        //    "link": "https://bminingspa.sharepoint.com/:b:/s/BMININGSGI/ET1vrAcGtGJInzASefBMVCcBOMjSmcY0rxQoXqviG74Vrw?e=8HlLbE"
                        //},
                        {
                            "name": "VIDEO MANIFIESTO",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FVideo%20Manifiesto%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "RECORRIDO VIRTUAL OFICINA",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FVideo%20Recorrido%20Oficina%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "VIDEO ODIS (SEGURIDAD Y SALUD EN EL TRABAJO) ",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FODI%20%28Seguridad%20y%20Salud%20en%20el%20Trabajo%29%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "SINTESIS GPA",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FSintesis%20GPA%2Epdf&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        }//,
                        //{
                        //    "name": "CALENDARIO CUMPLEAÑOS",
                        //    "link": "#"
                        //}
                    ]
                },
                {
                    "name": "FINANZAS",
                    "items": [
                        {
                            "name": "INSTRUCTIVO RENDICION",
                            "link": "https://bminingspa.sharepoint.com/:w:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7B311EDBFC-194E-4755-BF8A-9ADB7780F00F%7D&file=BM-AF-IT-004-ver01%20Instructivo%20de%20Rendiciones..docx&action=default&mobileredirect=true"
                        },
                        {
                            "name": "FORMULARIO TRENDICIÓN",
                            "link": "https://bminingspa.sharepoint.com/:x:/r/sites/SistemadeGestinIntegradoBMINING/_layouts/15/Doc.aspx?sourcedoc=%7BE8B3E7D8-0635-47C5-901D-D3F9A3B00181%7D&file=BM-AF-RE-002-ver00%20Registro%20de%20Rendici%C3%B3n.xlsx&action=default&mobileredirect=truev"
                        }
                    ]
                },
                {
                    "name": "SOPORTE TI",
                    "items": [
                        {
                            "name": "INDUCCION CREDENCIALES BMINING Y OFFICE 365",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FCredenciales%20Bmining%20y%20Office%20365%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "INDUCCION INSTALACION OFFICE 365 PARA MAC",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FInstalacion%20Office%20365%20para%20MAC%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "INDUCCION MICROSOFT OUTLOOK",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FUso%20Microsoft%20Outlook%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "INDUCCION TEAMS",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FInducci%C3%B3n%20Teams%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "INDUCCION SHAERRPOINT",
                            "link":"https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FUso%20Sharepoint%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        },
                        {
                            "name": "INDUCCION INSTALACION Y USO DE ONEDRIVE",
                            "link": "https://bminingspa.sharepoint.com/sites/SistemadeGestinIntegradoBMINING/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido%2FInstalaci%C3%B3n%20y%20Uso%20de%20Onedrive%2Emp4&viewid=2c8b23f1%2Dc506%2D4813%2Db1b9%2Dd08fad35c9d0&parent=%2Fsites%2FSistemadeGestinIntegradoBMINING%2FDocumentos%20compartidos%2F6%2E%2D%20Administraci%C3%B3n%20y%20Gesti%C3%B3n%20de%20Personas%2F5%2E%2D%20Inducci%C3%B3n%2FInducci%C3%B3n%20Contrato%20Indefinido"
                        }
                    ]
                }
            ]
        }

        setSgiFile(newSgiFIle)
    }, [])

    return <>
        {sgiFile && <>
            <Typography color='primary' variant='h5'>
                <strong>{sgiFile.name}</strong>
            </Typography>
            {sgiFile.items.map(value => {

                return <Box m={margin} key={value.name}>
                    <Typography color='secondary' variant='h6'>
                        <strong>• {value.name}</strong>
                    </Typography>

                    <Box m={margin}>
                        {value.items.map(secondaryValue => {
                            return <Box key={secondaryValue.name}>
                                {secondaryValue.link == "#" && <>
                                    {secondaryValue.name} (No disponible)
                                </>}

                                {secondaryValue.link != "#" && <>
                                    <Link variant='body1' color="primary" href={secondaryValue.link}>
                                        {secondaryValue.name}
                                    </Link>
                                </>}
                            </Box>
                        })}
                    </Box>
                </Box>
            })}
        </>}
    </>
}

