import React, {ChangeEvent} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {CredentialTypeEnum} from "../../api/CredentialTypeEnum";
import {GetCredentialTypeString, isEmpty} from "../../utils/utils";
import {AddBoxOutlined, RemoveCircleOutline} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {question} from "../../redux/question";
import {notification} from "../../redux/notification";
import {List as LinqList} from "linq-typescript";
import {usersService} from "../../api/UsersService";


const margin = 1;
const padding = 1;
const elevation = 1;

interface WithCopyEmail {
    email: string
}

interface PredefinedWithCopyEmail {
    email: string,
    isEnabled: boolean
}

export const AddUserComponent = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [addWithCopyEmail, setAddWithCopyEmail] = React.useState<string>('')
    const [predefinedWithCopyEmails, setPredefinedWithCopyEmails] = React.useState<PredefinedWithCopyEmail[]>([]);
    const [withCopyEmails, setWithCopyEmails] = React.useState<WithCopyEmail[]>([])

    const [selectedCredentialType, setSelectedCredentialType] = React.useState<CredentialTypeEnum>(CredentialTypeEnum.Office365);
    const [credentialTypes] =
        React.useState<CredentialTypeEnum[]>([CredentialTypeEnum.Email, CredentialTypeEnum.Office365]);

    const [canCreate, setCanCreate] = React.useState<boolean>(false);

    const [enterpriseEmail, setEnterpriseEmail] = React.useState<string>('')
    const [personalEmail, setPersonalEmail] = React.useState<string>('')

    const createUserCallback = () => {

        let withCopyList = new LinqList(withCopyEmails);
        let defaultEmailList = new LinqList(predefinedWithCopyEmails).where(element => element.isEnabled).toList();

        let definitiveEmailsList = new LinqList<string>();
        definitiveEmailsList.pushRange(withCopyList.select(element => element.email));
        definitiveEmailsList.pushRange(defaultEmailList.select(element => element.email));

        let definitiveEmailsArray = definitiveEmailsList.distinct().toArray();

        let action = () => {
            usersService.createUser(enterpriseEmail, personalEmail, selectedCredentialType, definitiveEmailsArray).then(() => {
                dispatch(notification.actions.openDialog({
                    title: Strings.UserCreatedWithSuccess,
                    text: Strings.Notification
                }))
            })
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    React.useEffect(() => {
        setPredefinedWithCopyEmails([{email: 'patricio.fernandez@bmining.cl', isEnabled: false},
            // se elimina cuenta de Jeniffer,Andres, Evelin y Francisco y se agrega cuenta de Oliver   
            //{email: 'jeniffer.mieres@bmining.cl', isEnabled: false},
            //{email: 'francisco.soto@bmining.cl', isEnabled: false},
            // se elimina a gabriela, se agrega a vanesa y carolina muroc 03-03-2023
            //{email: 'gabriela.alarcon@bmining.cl', isEnabled: false},
            {email: 'edgar.adam@bmining.cl', isEnabled: false},
            {email: 'vanesa.miranda@bmining.cl', isEnabled: false},
            {email: 'daniel.mesa@bmining.cl', isEnabled: true},            
            {email: 'patricio.fernandez@muroc.cl', isEnabled: false},
            {email: 'oliver.mura@muroc.cl', isEnabled: false}
            //{email: 'andres.adam@muroc.cl', isEnabled: false}
        ])
            
    }, [])

    React.useEffect(() => {

        if (!isEmpty(enterpriseEmail) && !isEmpty(personalEmail) && selectedCredentialType !== undefined)
            setCanCreate(true);
        else
            setCanCreate(false);

    }, [personalEmail, enterpriseEmail, selectedCredentialType])

    const addWithCopy = () => {
        setWithCopyEmails([...withCopyEmails, {email: addWithCopyEmail}]);
        setAddWithCopyEmail('')
    }

    const updatePersonalEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setPersonalEmail(event.target.value);
    }

    const updateEnterpriseEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEnterpriseEmail(event.target.value);
    }
    const updateWithCopyEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setAddWithCopyEmail(event.target.value);
    }

    const removeWithCopyEmail = (value: WithCopyEmail) => {
        let index = withCopyEmails.indexOf(value);
        let newItems = [...withCopyEmails];
        newItems.splice(index, 1);
        setWithCopyEmails(newItems);
    }

    const onCheckedPredefinedEmail = (value: PredefinedWithCopyEmail) => {
        let index = predefinedWithCopyEmails.indexOf(value);
        let newTable = [...predefinedWithCopyEmails];
        newTable[index].isEnabled = !newTable[index].isEnabled;
        setPredefinedWithCopyEmails(newTable);
    }


    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Grid container direction='row'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={enterpriseEmail}
                                onChange={updateEnterpriseEmail}
                                label={Strings.EnterpriseEmail}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={personalEmail}
                                onChange={updatePersonalEmail}
                                label={Strings.PersonalEmail}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <FormControl fullWidth>
                                <InputLabel id="selectCredentialTypeLabel">{Strings.CredentialType}</InputLabel>
                                <Select value={selectedCredentialType} labelId='selectCredentialTypeLabel'
                                        id='selectCredentialType'
                                        onChange={event => {
                                            setSelectedCredentialType(event.target.value as CredentialTypeEnum)
                                        }}>
                                    {credentialTypes.map(value => {
                                        return <MenuItem key={GetCredentialTypeString(value)}
                                                         value={value}>{GetCredentialTypeString(value)}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box m={margin} padding={padding}>
                            <Button variant='contained' onClick={createUserCallback}
                                    disabled={!canCreate}>{Strings.Create}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>

        <Grid container direction='row'>
            <Grid item xs>
                <Box m={margin} p={padding}>
                    <Paper elevation={elevation}>
                        <Box m={margin} p={padding}>
                            <Typography variant='h6'>{Strings.DefaultEmails}</Typography>
                        </Box>
                        <Box m={margin} p={padding}>
                            <List>
                                {predefinedWithCopyEmails.map(value => {
                                    return <Box key={value.email}>
                                        <FormControlLabel
                                            checked={value.isEnabled}
                                            control={<Checkbox onClick={() => onCheckedPredefinedEmail(value)}
                                                               inputProps={{'aria-label': 'primary checkbox'}}/>}
                                            label={value.email}/>
                                    </Box>
                                })}
                            </List>
                        </Box>
                    </Paper>
                </Box>
            </Grid>

            <Grid item xs>
                <Box m={margin} p={padding}>
                    <Paper elevation={elevation}>
                        <Grid container direction='row'>
                            <Grid item xs>
                                <Box m={margin} p={padding}>
                                    <TextField
                                        value={addWithCopyEmail}
                                        onChange={updateWithCopyEmail}
                                        label={Strings.SpecificEmails}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                </Box>
                            </Grid>

                            <Grid item xs='auto'>
                                <Box m={margin} p={padding}>
                                    <IconButton onClick={addWithCopy}>
                                        <AddBoxOutlined/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>


                        <List>
                            {withCopyEmails.map(value => {
                                return <Box key={withCopyEmails.indexOf(value)}>
                                    <Grid container direction='row' justifyContent='space-between'
                                          alignItems='center'>
                                        <Grid item xs>
                                            <Box p={padding}>
                                                <Typography>{value.email}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <IconButton onClick={() => {
                                                removeWithCopyEmail(value)
                                            }}>
                                                <RemoveCircleOutline/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            })}
                        </List>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    </>
}
