import React from 'react';
import {UserComponentRowModel} from "./UserComponentRowModel";
import MaterialTable, {Column} from "material-table";
import {usersService} from "../../api/UsersService";

function getColumns(): Column<UserComponentRowModel>[] {
    let columns: Column<UserComponentRowModel>[] = [];
    columns.push({
        title: 'Email',
        field: 'email',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: 'Nombre',
        field: 'name',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: 'Apellido',
        field: 'surname',
        type: 'string',
        editable: 'never'
    });
    columns.push({
        title: 'Activo',
        field: 'isActive',
        type: 'boolean',
    });
    return columns;
}

export const UsersComponent = () => {

    const [userModel, setUserModel] = React.useState<UserComponentRowModel[]>([]);
    const [columnUserModel] = React.useState<Column<UserComponentRowModel>[]>(getColumns);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isDataLoaded) {
            return;
        }
        usersService.getUsers().then(value => {
            let userData: UserComponentRowModel[] = [];
            value.forEach(userModel => {
                userData.push({
                    surname: userModel.surname,
                    name: userModel.name,
                    email: userModel.email,
                    isActive: userModel.isActive,
                    id: userModel.id
                })
            });
            setUserModel(userData);
            setIsDataLoaded(true);
            setIsLoading(false);
        }).catch(reason => {
            console.log(reason);
        });
    });

    const onCellEditApprovedCallback = async (newValue: any, oldValue: any, rowData: UserComponentRowModel,
                                              columnDef: Column<UserComponentRowModel>) => {
        if (columnDef.field === 'isActive') {
            let oldValueBoolean = oldValue as boolean;
            let newValueBoolean = newValue as boolean;
            // Case of the same values
            if (newValueBoolean === oldValueBoolean)
                return new Promise<void>(resolve => resolve());
            const index = userModel.indexOf(rowData);
            let newData = [...userModel];
            let userId = rowData.id;
            newData[index].isActive = newValueBoolean;
            await usersService.setUserActive(userId, newValueBoolean);
            setUserModel(newData);
            return;
        }
        return new Promise<void>(resolve => resolve());
    }

    return <MaterialTable
        title='Usuarios'
        columns={columnUserModel}
        data={userModel}
        isLoading={isLoading}
        cellEditable={{
            onCellEditApproved: onCellEditApprovedCallback
        }}/>
}



