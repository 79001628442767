import {IUserModel} from "../api/IUserModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {usersService} from "../api/UsersService";


export interface UsersState {
    users: IUserModel[],
    currentUser: IUserModel
}

const initialState: UsersState = {
    users: [],
    currentUser: null
}

export const user = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUsers: (state: UsersState, payload: PayloadAction<IUserModel[]>) => {
            state.users = [...payload.payload]
            return state;
        },
        setCurrentUser: (state: UsersState, payload: PayloadAction<IUserModel>) => {
            state.currentUser = payload.payload;
            return state;
        },
    }
})

export async function loadUsersThunk(dispatch: AppDispatch) {
    let users = await usersService.getUsers();
    await dispatch(user.actions.setUsers(users));
}
export async function updateCurrentUser(dispatch: AppDispatch,getState: () => RootState) {
    let email = getState().authentication.identity.credentials.email;
    let currentUser =await usersService.getUserByEmail(email);
    await dispatch(user.actions.setCurrentUser(currentUser))
}



