import React, {ChangeEvent, useEffect} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {IAccountRequest} from "../../api/AccountRequestService";
import {Strings} from "../../Resources/Strings";
import {loadAccountRequestsAllThunk, addAccountRequestThunk, IAccountRequestsTable, 
    loadMicrosoftAccountsAllThunk, IMicrosoftUsersTable, 
    removeMicrosoftUserRequestThunk, changeCredentialTypeRequestThunk, downloadMicrosoftAccountsMurocThunk, downloadMicrosoftAccountsBminingThunk} from "../../redux/accountRequest";
import {question} from "../../redux/question";
import MaterialTable, {Column} from "material-table";
import {progress} from "../../redux/progress";
import {GetCredentialTypeString} from '../../utils/utils';
import {isEmpty} from "../../utils/utils";
import {CredentialTypeEnum} from '../../api/CredentialTypeEnum';
import {List as LinqList} from "linq-typescript";
import { AddBoxOutlined, RemoveCircleOutline, Autorenew, Close, CloudDownloadOutlined} from '@material-ui/icons';


const margin = 1;
const padding = 1;
const elevation = 1;

interface WithCopyEmail {
    email: string
}

interface PredefinedWithCopyEmail {
    email: string,
    isEnabled: boolean
}

function getTemplateColumns(): Column<IAccountRequestsTable>[] {
    return [
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.EnterpriseEmail, field: 'enterpriseEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.PersonalEmail, field: 'personalEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.CredentialType, field: 'credentialType', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.RequestType, field: 'requestType', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Date, field: 'requestDate', type: 'datetime', editable: "never", width: 'auto'},
        {title: Strings.Status, field: 'status', type: 'string', editable: "never", width: 'auto'},
        ]
}

function getMicrosoftAccountsColumns(): Column<IMicrosoftUsersTable>[] {
    return [
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.EnterpriseEmail, field: 'enterpriseEmail', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.CredentialType, field: 'credentialType', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.CreatedDate, field: 'createdDateTime', type: 'datetime', editable: "never", width: 'auto'}
        ]
}

export const AccountRequestComponent = () => {

    const dispatch = useDispatch();
    const {accountRequests, microsoftAccountsBmining, microsoftAccountsMuroc} = useSelector((state: RootState) => state.accountRequest)
    const dataTableRequests = accountRequests.map(value => ({...value}));
    const dataTableMicrosoftBmining = microsoftAccountsBmining.map(value => ({...value}));
    const dataTableMicrosoftMuroc = microsoftAccountsMuroc.map(value => ({...value}));

    const [accountRequestColumns] = React.useState(getTemplateColumns());
    const [microsoftAccountsColumns] = React.useState(getMicrosoftAccountsColumns());

    const [name, setName] = React.useState<string>('');
    const [surname, setSurname] = React.useState<string>('');
    const [enterpriseEmail, setEnterpriseEmail] = React.useState<string>('');
    const [personalEmail, setPersonalEmail] = React.useState<string>('');
    const [selectedCredentialType, setSelectedCredentialType] = React.useState<CredentialTypeEnum>(CredentialTypeEnum.Email)
    const [credentialTypes] =
        React.useState<CredentialTypeEnum[]>([CredentialTypeEnum.Email, CredentialTypeEnum.Office365]);

    //const [microsoftAccounts, setMicrosoftAccounts] = React.useState<IMicrosoftUser[]>([]);

    const [isYesAvailable, setIsYesAvailable] = React.useState<boolean>(false);

    const [addWithCopyEmail, setAddWithCopyEmail] = React.useState<string>('')
    const [predefinedWithCopyEmails, setPredefinedWithCopyEmails] = React.useState<PredefinedWithCopyEmail[]>([]);
    const [withCopyEmails, setWithCopyEmails] = React.useState<WithCopyEmail[]>([])
    

    React.useEffect(() => {
        setPredefinedWithCopyEmails([
            {email: 'carolina.adan@bmining.cl', isEnabled: true},
            {email: 'vanesa.miranda@bmining.cl', isEnabled: true},
            {email: 'francisco.soto@bmining.cl', isEnabled: false},])
            //se eliminan correos 03-03-2023
            //{email: 'gabriela.alarcon@bmining.cl', isEnabled: false},
            //{email: 'carlos.schumann@bmining.cl', isEnabled: false}])
    }, [])


    const createAccountRequestCallback = () => {

        let withCopyList = new LinqList(withCopyEmails);
        let defaultEmailList = new LinqList(predefinedWithCopyEmails).where(element => element.isEnabled).toList();

        let definitiveEmailsList = new LinqList<string>();
        definitiveEmailsList.pushRange(withCopyList.select(element => element.email));
        definitiveEmailsList.pushRange(defaultEmailList.select(element => element.email));

        let definitiveEmailsArray = definitiveEmailsList.distinct().toArray();

        let action = () => {
            let accountRequest = {
                        name: name,
                        surname: surname,
                        enterpriseEmail: enterpriseEmail,
                        personalEmail: personalEmail,
                        credentialType: selectedCredentialType,
                        withCopyEmails: definitiveEmailsArray
                    } as IAccountRequest;
                    dispatch(addAccountRequestThunk(accountRequest))
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    const removeAccountRequestCallback = (id:string) => {

        let withCopyList = new LinqList(withCopyEmails);
        let defaultEmailList = new LinqList(predefinedWithCopyEmails).where(element => element.isEnabled).toList();

        let definitiveEmailsList = new LinqList<string>();
        definitiveEmailsList.pushRange(withCopyList.select(element => element.email));
        definitiveEmailsList.pushRange(defaultEmailList.select(element => element.email));

        let definitiveEmailsArray = definitiveEmailsList.distinct().toArray();

        let action = () => {
            dispatch(removeMicrosoftUserRequestThunk(id, definitiveEmailsArray))
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    const changeCredentialTypeRequestCallback = (id:string) => {

        let withCopyList = new LinqList(withCopyEmails);
        let defaultEmailList = new LinqList(predefinedWithCopyEmails).where(element => element.isEnabled).toList();

        let definitiveEmailsList = new LinqList<string>();
        definitiveEmailsList.pushRange(withCopyList.select(element => element.email));
        definitiveEmailsList.pushRange(defaultEmailList.select(element => element.email));

        let definitiveEmailsArray = definitiveEmailsList.distinct().toArray();

        let action = () => {
            dispatch(changeCredentialTypeRequestThunk(id, definitiveEmailsArray))
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    const downloadMicrosoftAccountsBminingCallback = () => {

        let action = () => {
            dispatch(downloadMicrosoftAccountsBminingThunk())
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    const downloadMicrosoftAccountsMurocCallback = () => {

        let action = () => {
            dispatch(downloadMicrosoftAccountsMurocThunk())
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }

    React.useEffect(() => {
        if (isEmpty(enterpriseEmail) || isEmpty(personalEmail) ||isEmpty(name) || isEmpty(surname) || selectedCredentialType === null)
            setIsYesAvailable(false)
        else setIsYesAvailable(true)
    }, [name, surname, enterpriseEmail, personalEmail, selectedCredentialType]);


    const nameChangedCallback = (event: any | HTMLInputElement) => {
        setName(event.target.value)
    }
    const surnameChangedCallback = (event: any | HTMLInputElement) => {
        setSurname(event.target.value)
    }
    const personalEmailChangedCallback = (event: any | HTMLInputElement) => {
        setPersonalEmail(event.target.value)
    }
    const enterpriseEmailChangedCallback = (event: any | HTMLInputElement) => {
        setEnterpriseEmail(event.target.value)
    }
    const updateWithCopyEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setAddWithCopyEmail(event.target.value);
    }

    const addWithCopy = () => {
        setWithCopyEmails([...withCopyEmails, {email: addWithCopyEmail}]);
        setAddWithCopyEmail('')
    }

    const removeWithCopyEmail = (value: WithCopyEmail) => {
        let index = withCopyEmails.indexOf(value);
        let newItems = [...withCopyEmails];
        newItems.splice(index, 1);
        setWithCopyEmails(newItems);
    }

    const onCheckedPredefinedEmail = (value: PredefinedWithCopyEmail) => {
        let index = predefinedWithCopyEmails.indexOf(value);
        let newTable = [...predefinedWithCopyEmails];
        newTable[index].isEnabled = !newTable[index].isEnabled;
        setPredefinedWithCopyEmails(newTable);
    }

    useEffect(() => {
        async function loadData() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadAccountRequestsAllThunk)
            dispatch(progress.actions.closeProgressBar())
        }
        loadData()
    }, [])

    useEffect(() => {
        async function loadData() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadMicrosoftAccountsAllThunk)
            dispatch(progress.actions.closeProgressBar())
        }
        loadData()
    }, [])

    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <Typography variant='h6'>{Strings.AccountRequest}</Typography>
                </Box>
                <Grid container direction='row'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={name}
                                onChange={nameChangedCallback}
                                label={Strings.Name}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={surname}
                                onChange={surnameChangedCallback}
                                label={Strings.Surname}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={enterpriseEmail}
                                onChange={enterpriseEmailChangedCallback}
                                label={Strings.EnterpriseEmail}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={personalEmail}
                                onChange={personalEmailChangedCallback}
                                label={Strings.PersonalEmail}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <FormControl fullWidth>
                                <InputLabel id="selectCredentialTypeLabel">{Strings.CredentialType}</InputLabel>
                                <Select value={selectedCredentialType} labelId='selectCredentialTypeLabel'
                                        id='selectCredentialType'
                                        onChange={event => {
                                            setSelectedCredentialType(event.target.value as CredentialTypeEnum)
                                        }}>
                                    {credentialTypes.map(value => {
                                        return <MenuItem key={GetCredentialTypeString(value)}
                                                         value={value}>{GetCredentialTypeString(value)}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box m={margin} padding={padding}>
                            <Button variant='contained' onClick={createAccountRequestCallback}
                                    disabled={!isYesAvailable}>{Strings.Create}</Button>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction='row'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Paper elevation={elevation}>
                                <Box m={margin} p={padding}>
                                    <Typography variant='h6'>{Strings.WithCopyEmails}</Typography>
                                </Box>
                                <Box m={margin} p={padding}>
                                    <List>
                                        {predefinedWithCopyEmails.map(value => {
                                            return <Box key={value.email}>
                                                <FormControlLabel
                                                    checked={value.isEnabled}
                                                    control={<Checkbox onClick={() => onCheckedPredefinedEmail(value)}
                                                                    inputProps={{'aria-label': 'primary checkbox'}}/>}
                                                    label={value.email}/>
                                            </Box>
                                        })}
                                    </List>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Paper elevation={elevation}>
                                <Grid container direction='row'>
                                    <Grid item xs>
                                        <Box m={margin} p={padding}>
                                            <TextField
                                                value={addWithCopyEmail}
                                                onChange={updateWithCopyEmail}
                                                label={Strings.SpecificEmails}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}/>
                                        </Box>
                                    </Grid>

                                    <Grid item xs='auto'>
                                        <Box m={margin} p={padding}>
                                            <IconButton onClick={addWithCopy}>
                                                <AddBoxOutlined/>
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>


                                <List>
                                    {withCopyEmails.map(value => {
                                        return <Box key={withCopyEmails.indexOf(value)}>
                                            <Grid container direction='row' justifyContent='space-between'
                                                alignItems='center'>
                                                <Grid item xs>
                                                    <Box p={padding}>
                                                        <Typography>{value.email}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <IconButton onClick={() => {
                                                        removeWithCopyEmail(value)
                                                    }}>
                                                        <RemoveCircleOutline/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
        
        <Box m={margin} p={padding}>
            <MaterialTable
                title={Strings.AccountRequest}
                columns={accountRequestColumns}
                data={dataTableRequests}
                options={{
                    actionsColumnIndex: -1
                }}
            />
        </Box>
        <Box m={margin} p={padding}>
            <MaterialTable
                title={Strings.MicrosoftActiveAccountsBmining}
                columns={microsoftAccountsColumns}
                data={dataTableMicrosoftBmining}
                options={{
                    actionsColumnIndex: -1
                }}

                actions={[ {
                    tooltip: Strings.DownloadCsvFile,
                    icon: CloudDownloadOutlined,
                    isFreeAction: true,
                    onClick: () => {
                        downloadMicrosoftAccountsBminingCallback()
                    }
                },
                {
                    icon: Close,
                    tooltip: `${Strings.DeleteRequest}`,
                    onClick: async (event, rowData: IMicrosoftUsersTable) => {
                        removeAccountRequestCallback(rowData.id)                    
                    }
                },
                {
                    icon: Autorenew,
                    tooltip: `${Strings.ChangeRequest}`,
                    onClick: async (event, rowData: IMicrosoftUsersTable) => {
                        changeCredentialTypeRequestCallback(rowData.id)                    
                    }
                }]}
            />
        </Box>
        <Box m={margin} p={padding}>
            <MaterialTable
                title={Strings.MicrosoftActiveAccountsMuroc}
                columns={microsoftAccountsColumns}
                data={dataTableMicrosoftMuroc}
                options={{
                    actionsColumnIndex: -1
                }}
                actions={[ {
                    tooltip: Strings.DownloadCsvFile,
                    icon: CloudDownloadOutlined,
                    isFreeAction: true,
                    onClick: () => {
                        downloadMicrosoftAccountsMurocCallback()
                    }
                },
                {
                    icon: Close,
                    tooltip: `${Strings.DeleteRequest}`,
                    onClick: async (event, rowData: IMicrosoftUsersTable) => {
                        removeAccountRequestCallback(rowData.id)                    
                    }
                },
                {
                    icon: Autorenew,
                    tooltip: `${Strings.ChangeRequest}`,
                    onClick: async (event, rowData: IMicrosoftUsersTable) => {
                        changeCredentialTypeRequestCallback(rowData.id)                    
                    }
                }]}
            />
        </Box>
    </>
}
