import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {loadActivityRecordsThunk} from "../../redux/activityRecord";
import {Column} from "material-table";
import {Strings} from "../../Resources/Strings";
import {IActivityRecord} from "../../api/IActivityRecord";
import {loadExternalUsersThunk} from "../../redux/externalUser";
import {progress} from "../../redux/progress";
import {loadUsersThunk} from "../../redux/user";
import {ActivityRecordTableComponent} from "../DataTemplates/ActivityRecordTable/ActivityRecordTableComponent";
import {loadProjectsThunk} from "../../redux/project";

function getTemplateColumns(): Column<IActivityRecord>[] {
    return [{title: Strings.Title, field: 'title', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Id, field: 'id', type: 'string', editable: "never", width: 'auto'}];
}

export const ActivityRecordComponent = () => {

    const dispatch = useDispatch();
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {activityRecords} = useSelector((state: RootState) => state.activityRecord)
    const {externalUsers} = useSelector((state: RootState) => state.externalUser)
    const {users} = useSelector((state: RootState) => state.user)
    const {projects, selectedProject} = useSelector((state: RootState) => state.project)


    useEffect(() => {
        async function fetchActivityRecords() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadExternalUsersThunk)
            await dispatch(loadUsersThunk)
            await dispatch(loadActivityRecordsThunk)
            await dispatch(loadProjectsThunk)
            dispatch(progress.actions.closeProgressBar())
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchActivityRecords()
    }, [])

    return <>
        {!isOpen &&
        <ActivityRecordTableComponent activityRecords={activityRecords} users={users}
                                      externalUsers={externalUsers} selectedProject={selectedProject}
                                      projects={projects}/>
        }
    </>
}