import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DialogActions, DialogContent, TextField} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {usersService} from "../../api/UsersService";
import {projectService} from "../../api/ProjectsService";

export interface TrackingHoursAddProjectDialogProps {
    open: boolean;
    onClose: (value: ITrackingHoursProjectDialogItem) => void;
}

export interface ITrackingHoursProjectDialogItem {
    id: string
    projectName: string,
    projectCode: string,
    projectDisplay: string,
}

export function TrackingHoursAddProjectDialog(props: TrackingHoursAddProjectDialogProps) {
    const {open, onClose} = props;
    const [selectedValue, setSelectedValue] = React.useState<ITrackingHoursProjectDialogItem>(null);
    const {identity} = useSelector((state: RootState) => state.authentication)

    const [credentials] = React.useState(identity.credentials);
    const [projects, setProjects] = React.useState([] as ITrackingHoursProjectDialogItem[]);

    React.useEffect(() => {
        usersService.getUserByEmail(credentials.email).then(userModel => {
            let userId = userModel.id;
            projectService.getActiveProjectsByUser(userId).then(projectModels => {
                let projects = projectModels.map(value => {
                    return {
                        id: value.id,
                        projectDisplay: `${value.code} - ${value.name}`,
                        projectCode: value.code,
                        projectName: value.name,
                    } as ITrackingHoursProjectDialogItem
                });
                setProjects(projects);
            });

        }).catch(reason =>
            console.error(reason));
    }, [])

    const handleOkClose = () => {
        onClose(selectedValue);
    };

    const handleCancelClose = () => {
        onClose(null);
    }

    return (
        <Dialog aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}>
            <DialogTitle id="simple-dialog-title">Proyectos</DialogTitle>
            <DialogContent>
                <Autocomplete style={{width: '700'}}
                              value={selectedValue}
                              onChange={(event: any, newValue: ITrackingHoursProjectDialogItem | null) => {
                                  setSelectedValue(newValue);
                              }}
                              id="combo-box-demo"
                              options={projects}
                              getOptionLabel={(option) => option.projectDisplay}
                              renderInput={(params) =>
                                  <TextField {...params} variant="outlined"/>}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={() => {
                    handleOkClose();
                }}>Ok</Button>
                <Button variant='contained' color='secondary' onClick={() => {
                    handleCancelClose();
                }}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
}