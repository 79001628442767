import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {compromiseService} from "../api/CompromiseService";
import {ICompromiseModel} from "../api/ICompromiseModel";
import {CompromiseStatusEnum} from "../api/CompromiseStatusEnum";
import {progress} from "./progress";
import {notification} from "./notification";
import {Strings} from "../Resources/Strings";
import {question} from "./question";
import moment from "moment";

export interface CompromiseState {
    isAddingActivityRecordCompromise: boolean,
    isAddingGeneralCompromise: boolean,
    isAddingProjectCompromise: boolean,
    isEditingCompromise: boolean
    compromises: ICompromiseModel[],
    selectedCompromise: ICompromiseModel
}

export const initialState: CompromiseState = {
    isAddingGeneralCompromise: false,
    isAddingActivityRecordCompromise: false,
    isAddingProjectCompromise: false,
    isEditingCompromise: false,
    compromises: [],
    selectedCompromise: null
};

export const compromise = createSlice({
    name: 'compromise',
    initialState: initialState,
    reducers: {
        openAddActivityRecordCompromise(state: CompromiseState) {
            state.isAddingActivityRecordCompromise = true
            return state
        },
        closeAddActivityRecordCompromise(state: CompromiseState) {
            state.isAddingActivityRecordCompromise = false
            return state
        },
        openAddGeneralCompromise(state: CompromiseState) {
            state.isAddingGeneralCompromise = true
            return state
        },
        closeGeneralCompromise(state: CompromiseState) {
            state.isAddingGeneralCompromise = false
            return state
        },
        openAddProjectCompromise(state: CompromiseState) {
            state.isAddingProjectCompromise = true
            return state
        },
        closeAddProjectCompromise(state: CompromiseState) {
            state.isAddingProjectCompromise = false
            return state
        },
        openEditCompromise(state: CompromiseState) {
            state.isEditingCompromise = true
            return state;
        },
        closeEditCompromise(state: CompromiseState) {
            state.isEditingCompromise = false
            return state;
        },
        setCompromises(state: CompromiseState, payload: PayloadAction<ICompromiseModel[]>) {
            state.compromises = payload.payload;
            return state;
        },
        setSelectedCompromise(state: CompromiseState, payload: PayloadAction<ICompromiseModel>) {
            state.selectedCompromise = payload.payload;
            return state;
        },
        unsetSelectedCompromise(state: CompromiseState) {
            state.selectedCompromise = null;
            return state
        }
    }
})

export function editCompromiseThunk(editCompromise: ICompromiseModel) {
    return async function editCompromiseFunction(dispatch: AppDispatch, getState: () => RootState) {
        let credentials = getState().authentication.identity.credentials;
        let userRoles = credentials.roles;
        let userId = credentials.id;

        if (!userRoles.includes("Admin") &&
            editCompromise.owner.id !== userId &&
            editCompromise.creator.id !== userId) {
            await dispatch(notification.actions.openDialog({
                title: Strings.Error,
                text: Strings.YouDontHaveAccess
            }))
            return;
        }
        await dispatch(compromise.actions.setSelectedCompromise(editCompromise))
        await dispatch(compromise.actions.openEditCompromise())
    }
}

export async function loadCompromisesThunk(dispatch: AppDispatch) {
    let compromiseModels = await compromiseService.getCompromises();
    await dispatch(compromise.actions.setCompromises(compromiseModels));
}

export function addActivityRecordCompromiseThunk(activityRecordId: string, userOwnerId: string, title: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        let newCompromise = await compromiseService.addActivityRecordCompromise(activityRecordId, userOwnerId, title);
        await loadCompromisesThunk(dispatch)
        await dispatch(compromise.actions.setSelectedCompromise(newCompromise))
        await dispatch(compromise.actions.closeAddActivityRecordCompromise())
        await dispatch(compromise.actions.openEditCompromise())
    }
}

export function addGeneralCompromiseThunk(userOwnerId: string, title: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        let newCompromise = await compromiseService.addGeneralCompromise(userOwnerId, title);
        await loadCompromisesThunk(dispatch)
        await dispatch(compromise.actions.setSelectedCompromise(newCompromise))
        await dispatch(compromise.actions.closeGeneralCompromise())
    }
}

export function addProjectCompromiseThunk(userOwnerId: string, title: string, projectId: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        let newCompromise = await compromiseService.addProjectCompromise(userOwnerId, title, projectId);
        await loadCompromisesThunk(dispatch)
        await dispatch(compromise.actions.setSelectedCompromise(newCompromise))
        await dispatch(compromise.actions.closeAddProjectCompromise())
    }
}

export function removeCompromiseThunk(removeCompromise: ICompromiseModel) {
    return async function addCompromise(dispatch: AppDispatch, getState: () => RootState) {
        await dispatch(progress.actions.openProgressBar({}))
        let credentials = getState().authentication.identity.credentials;

        let userRoles = credentials.roles;
        let userId = credentials.id;

        if (!userRoles.includes("Admin") &&
            removeCompromise.owner.id !== userId &&
            removeCompromise.creator.id !== userId) {
            await dispatch(notification.actions.openDialog({
                title: Strings.Error,
                text: Strings.YouDontHaveAccess
            }))
            await dispatch(progress.actions.closeProgressBar())
            return;
        }

        dispatch(question.actions.openDialog({
            title: Strings.AreYouSure,
            text: Strings.Question,
            actionOnYes: async () => {
                await compromiseService.removeCompromise(removeCompromise.id).catch(reason => {
                    console.error(reason)
                });
                await loadCompromisesThunk(dispatch)

            },
            onFinally: async () => {
                await dispatch(progress.actions.closeProgressBar())
            }
        }))
    }
}

export function updateCompromiseThunk(compromiseId: string, title: string, contentHtml: string,
                                      hasDueDate: boolean, compromiseStatus: CompromiseStatusEnum,
                                      percentageCompleted: number, dueDate: Date, associatedUsersIds: string[]) {
    return async function updateCompromise(dispatch: AppDispatch) {
        await compromiseService.updateCompromise(compromiseId, title, contentHtml, hasDueDate,
            compromiseStatus, percentageCompleted, dueDate, associatedUsersIds).catch(reason => {
            console.error(reason)
        })
        await loadCompromisesThunk(dispatch)
    }
}

export function updateCompromiseStatsThunk(compromiseId: string, status: CompromiseStatusEnum) {
    return async function updateCompromise(dispatch: AppDispatch, getState: () => RootState) {
        let credentials = getState().authentication.identity.credentials;
        let userRoles = credentials.roles;
        let userId = credentials.id;
        let compromise = getState().compromise.compromises.find(item => item.id === compromiseId);
        if (!userRoles.includes("Admin") &&
            compromise.owner.id !== userId &&
            compromise.creator.id !== userId) {
            await dispatch(notification.actions.openDialog({
                title: Strings.Error,
                text: Strings.YouDontHaveAccess
            }))
            return;
        }
        let associatedUserIds = compromise.associatedUsers.map(value => value.id)
        await compromiseService.updateCompromise(compromise.id, compromise.title, compromise.contentHtml, compromise.hasDueDate,
            status, compromise.percentageCompleted, moment(compromise.dueDate).toDate(), associatedUserIds).catch(reason => {
            console.error(reason)
        })
        await loadCompromisesThunk(dispatch)
    }
}









