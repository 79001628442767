import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {FC, useEffect, useState} from "react";
import {Box, Paper, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {usersService} from "../../api/UsersService";
import {IUserModel} from "../../api/IUserModel";
import {getUserCompromiseRowData} from "../DataTemplates/CompromisesTable/compromiseTableUtils";
import {getNameAndSurname} from "../../utils/utils";
import {progress} from "../../redux/progress";
import {signatureService} from "../../api/SignatureService";
import {updateCurrentUser} from "../../redux/user";
import {SignatureComponent} from "./SignatureComponent";
import {elevation, margin, padding} from "../../styles/styleConstants";


export const UserComponent = () => {
    const dispatch = useDispatch()
    const {currentUser} = useSelector((state: RootState) => state.user)
    const {isOpen} = useSelector((state: RootState) => state.progress)

    async function loadData() {
        await dispatch(progress.actions.openProgressBar({}))
        await dispatch(updateCurrentUser)
        await dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    return <>
        {!isOpen && <>
            {currentUser && <>
                <Typography variant='h6'>{Strings.Welcome} {getNameAndSurname(currentUser)}</Typography>
                <Box m={margin} p={padding}>
                    <Paper elevation={elevation}>
                        <Box m={margin} p={padding}>
                            <SignatureComponent/>
                        </Box>
                    </Paper>
                </Box>
            </>}
        </>}
    </>
}