import React from 'react'
import {Strings} from "../../../Resources/Strings";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {addExternalUserThunk, externalUser} from "../../../redux/externalUser";
import {Autocomplete} from "@material-ui/lab";
import {margin, padding} from "../../../styles/styleConstants";
import {IClientModel} from "../../../api/IClientModel";
import {clientsService} from "../../../api/ClientsService";
import {isEmpty} from "../../../utils/utils";
import {IExternalUser} from "../../../api/ExternalUsersService";

export const AddExternalUserDialogComponent = () => {
    const {isAddingExternalUser} = useSelector((state: RootState) => state.externalUser)
    const dispatch = useDispatch();

    const [name, setName] = React.useState<string>('');
    const [surname, setSurname] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [selectedClient, setSelectedClient] = React.useState<IClientModel>();
    const [clients, setClients] = React.useState<IClientModel[]>();

    const [isYesAvailable, setIsYesAvailable] = React.useState<boolean>(false);
    React.useEffect(() => {
        async function fetchClients() {
            let currentClients = await clientsService.getClients()
            setClients(currentClients);
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchClients();
    }, [])

    React.useEffect(() => {
        if (isAddingExternalUser) {
            setName('')
            setSurname('')
            setEmail('')
            setSelectedClient(null)
        }
    }, [isAddingExternalUser])

    React.useEffect(() => {
        if (isEmpty(email) || isEmpty(name) || isEmpty(surname) || selectedClient === undefined || selectedClient === null)
            setIsYesAvailable(false)
        else setIsYesAvailable(true)
    }, [email, surname, name, selectedClient]);

    const handleYes = () => {
        let externalUser = {
            id: '',
            name: name,
            surname: surname,
            email: email,
            clientId: selectedClient.id
        } as IExternalUser;
        dispatch(addExternalUserThunk(externalUser))
    }

    const handleNo = () => {
        dispatch(externalUser.actions.closeAddExternalUser())
    }

    const emailChangedCallback = (event: any | HTMLInputElement) => {
        setEmail(event.target.value)
    }
    const surnameChangedCallback = (event: any | HTMLInputElement) => {
        setSurname(event.target.value)
    }
    const nameChangedCallback = (event: any | HTMLInputElement) => {
        setName(event.target.value)
    }

    return <Dialog open={isAddingExternalUser} onClose={handleNo} fullWidth={true}>
        <DialogTitle>{Strings.NewExternalUser}</DialogTitle>
        <DialogContent>
            <Grid container direction='column'>
                <Grid item>
                    <Box m={margin} p={padding}>
                        <TextField label={Strings.Name} onChange={nameChangedCallback} fullWidth/>
                    </Box>
                </Grid>
                <Grid item>
                    <Box m={margin} p={padding}>
                        <TextField label={Strings.Surname} onChange={surnameChangedCallback} fullWidth/>
                    </Box>
                </Grid>
                <Grid item>
                    <Box m={margin} p={padding}>
                        <TextField label={Strings.Email} value={email} onChange={emailChangedCallback} fullWidth/>
                    </Box>
                </Grid>
                <Grid item>
                    <Box m={margin} p={padding}>
                        <Autocomplete fullWidth
                                      value={selectedClient}
                                      onChange={(event: any, newValue: IClientModel | null) => {
                                          setSelectedClient(newValue)
                                      }}
                                      id="client-autocomplete"
                                      options={clients}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) =>
                                          <TextField {...params} label={Strings.Client} variant="outlined"/>}/>
                    </Box>
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleYes} color="primary" variant='contained' disabled={!isYesAvailable}>
                {Strings.Yes}
            </Button>
            <Button onClick={handleNo} color="secondary" variant='contained'>
                {Strings.No}
            </Button>
        </DialogActions>
    </Dialog>
}