import {Redirect, Route} from "react-router-dom";
import React from "react";
import {appRoles} from "./appRoles";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {activityRecord} from "../redux/activityRecord";


export const PrivateRoute = ({component, ...rest}: any) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    const routeComponent = (props: any) => {
        return identity
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    }
    return <Route {...rest} render={routeComponent}/>;
};

export const AdminPrivateRoute = ({component, ...rest}: any) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    let status = identity && identity.credentials.roles.includes(appRoles.admin);
    const routeComponent = (props: any) => (
        status
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export const HumanResourcesPrivateRoute = ({component, ...rest}: any) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    let status = identity && identity.credentials.roles.includes(appRoles.humanResources);
    const routeComponent = (props: any) => (
        status
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    );
    console.log(rest)
    return <Route {...rest} render={routeComponent}/>;
};

export const ProjectManagerPrivateRoute = ({component, ...rest}: any) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    let status = identity && identity.credentials.roles.includes(appRoles.projectManager);
    const routeComponent = (props: any) => (
        status
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};


export const AdminHolidayPrivateRoute = ({component, ...rest}: any) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    let status = identity && (identity.credentials.roles.includes(appRoles.admin)
        || identity.credentials.roles.includes(appRoles.holiday));
    const routeComponent = (props: any) => (
        status
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

export const AdminCreatorPrivateRoute = ({component}: any, ...rest) => {
    const {identity} = useSelector((state: RootState) => state.authentication);
    let status = identity && (identity.credentials.roles.includes(appRoles.admin) ||
        identity.credentials.roles.includes(appRoles.creator));
    const routeComponent = (props: any) => (
        status
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/forbidden'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

