import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {progress} from "../../redux/progress";
import {loadCompromisesThunk} from "../../redux/compromise";
import {Strings} from "../../Resources/Strings";
import {loadUsersThunk} from "../../redux/user";
import {
    CompromisesTableComponent,
    CompromiseTableType
} from "../DataTemplates/CompromisesTable/CompromisesTableComponent";
import { UsersOnHolidaysComponent } from '../UserHoliday/UsersOnHolidaysComponent';
import {Box, Divider} from "@material-ui/core";
import {margin, padding} from "../../styles/styleConstants";
export const GeneralCompromisesComponent = () => {
    const {compromises, selectedCompromise, isEditingCompromise} = useSelector((state: RootState) => state.compromise);
    const {users} = useSelector((state: RootState) => state.user);

    const dispatch = useDispatch()

    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadCompromisesThunk)
        await dispatch(loadUsersThunk)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    return <>
        <CompromisesTableComponent title={Strings.GeneralCompromises}
                                   tableType={CompromiseTableType.General}/>                          
        <Box p={padding} m={margin}>
            <Divider style={{margin: '6'}}/>
        </Box>
        <UsersOnHolidaysComponent/>
    </>
   // cc
}