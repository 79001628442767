import React, {useState} from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, useTheme, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {appRoles} from "../../authentication/appRoles";
import {Link, Route, Switch} from 'react-router-dom'
import {
    AdminCreatorPrivateRoute, AdminHolidayPrivateRoute,
    AdminPrivateRoute, HumanResourcesPrivateRoute,
    PrivateRoute, ProjectManagerPrivateRoute
} from "../../authentication/CustomRoutes";
import {TrackingHoursComponent} from "../TrackingHours/TrackingHoursComponent";
import {UsersComponent} from "../Users/UsersComponent";
import {ProjectsComponent} from "../Projects/ProjectsComponent";
import {AddProjectComponent} from '../AddProject/AddProjectComponent';
import {SecondaryApproverAdminComponent} from '../SecondaryApproverAdmin/SecondaryApproverAdminComponent';

import {
    CodeTwoTone,
    FaceTwoTone,
    People,
    NoteAdd,
    PersonAdd,
    GroupAdd,
    ScheduleOutlined,
    ContactsOutlined,
    CheckOutlined,
    BusinessOutlined,
    AssignmentIndOutlined,
    EmojiObjectsOutlined,
    HelpOutline,
    CheckBoxOutlined,
    SecurityOutlined,
    VerifiedUserOutlined,
    TrendingDownOutlined,
    WbSunnyOutlined,
    WbSunnyTwoTone,
    WbCloudy,
    PauseCircleOutline,
    PeopleAltOutlined,
    Book,
    PersonPinCircle,
    GroupAddOutlined,
    HowToReg,
    LiveHelp, TabletSharp, AttachFile
} from "@material-ui/icons";
import {ClientsComponent} from "../Clients/ClientsComponent";
import {ForbiddenComponent} from "../Forbidden/ForbiddenComponent";
import {TokenComponent} from "../Lab/TokenComponent";
import {ApproveHoursComponent} from "../ApproveHours/ApproveHoursComponent";
import {ReportComponent} from "../Report/ReportComponent";
import {AdminViewUserHoursComponent} from "../AdminViewUserHours/AdminViewUserHoursComponent";
import {NotFoundComponent} from "../NotFound/NotFoundComponent";
import {WikiComponent} from "../Wiki/WikiComponent";
import {AddUserComponent} from "../AddUser/AddUserComponent";
import {PelambresComponent} from "../Lab/PelambresComponent";
import {Strings} from "../../Resources/Strings";
import {AddMemberComponent} from "../AddMember/AddMemberComponent";
import {ProgressComponent} from "../Progress/ProgressComponent";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {ExternalUsersComponent} from "../ExternalUsers/ExternalUsersComponent";
import {SignInOutComponent} from "../SignInOut/SignInOutComponent";
import {ActivityRecordComponent} from "../ActivityRecord/ActivityRecordComponent";
import {ActivityRecordEditComponent} from "../ActivityRecordDetails/ActivityRecordEditComponent";
import {CompromisesComponent} from "../Compromises/CompromisesComponent";
import {ActivityRecordByProjectComponent} from "../ActivityRegisterByProject/ActivityRecordByProjectComponent";
import {ActivityRecordHelpComponent} from "../ActivityRecordHelp/ActivityRecordHelpComponent";
import {SecurityReflectionComponent} from "../SecurityReflection/SecurityReflectionComponent";
import {GeneralCompromisesComponent} from "../GeneralCompromises/GeneralCompromisesComponent";
import {UserCompromisesComponent} from "../UserCompromises/UserCompromisesComponent";
import {ProjectCompromisesComponent} from "../ProjectCompromises/ProjectCompromisesComponent";
import {UsersPendingToUploadHoursComponent} from "../UsersPendingToUploadHours/UsersPendingToUploadHoursComponent";
import {UserHolidayAdminComponent} from "../UserHolidayAdmin/UserHolidayAdminComponent";
import {UserHolidayComponent} from "../UserHoliday/UserHolidayComponent";
import {AcceptHolidayComponent} from "../AcceptHoliday/AcceptHolidayComponent";
import {UserComponent} from "../User/UserComponent";
import {ActivePauseComponent} from "../ActivePause/ActivePauseComponent";
import {SgiHelpComponent} from "../SgiHelp/SgiHelpComponent";
import {AccountRequestComponent} from '../AccountRequest/AccountRequestComponent';
import {ApproveAccountRequestComponent} from '../AccountRequest/ApproveAccountRequestComponent';
import {Collapse} from "@material-ui/core";
import {
    HumanResourcesCompromiseNavigateComponent,
    ProjectManagerCompromiseNavigateComponent
} from "../compromiseNavigate/CompromiseNavigateComponent";
import {SgiFilesComponent} from "../sgiFiles/SgiFilesComponent";
import {ImageEditDataTemplateComponent} from "../DataTemplates/imageEditDateTemplate/imageEditDataTemplateComponent";

const drawerWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 20,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        title: {
            flexGrow: 1
        }
    }),
);

export default function NavBarComponent() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openHelpItems, setOpenHelpItems] = useState(false)

    const {identity} = useSelector((state: RootState) => state.authentication);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed"
                    className={clsx(classes.appBar, {[classes.appBarShift]: open})}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {[classes.hide]: open})}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {Strings.Bmining}
                    </Typography>

                    <SignInOutComponent/>
                </Toolbar>
                <ProgressComponent/>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}>
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListItem button key='trackingHours' component={Link} to='/trackingHours'>
                        <ListItemIcon><ScheduleOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.HoursRegister}/>
                    </ListItem>

                    <ListItem button key='approveHours' component={Link} to='/approveHours'>
                        <ListItemIcon><CheckOutlined/></ListItemIcon>
                        <ListItemText primary='Aprobar horas'/>
                    </ListItem>

                    <ListItem button key='projects' component={Link} to='/projects'>
                        <ListItemIcon><EmojiObjectsOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.Projects}/>
                    </ListItem>

                    <ListItem button key='myCompromises' component={Link} to='/myCompromises'>
                        <ListItemIcon><VerifiedUserOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.MyCompromises}/>
                    </ListItem>

                    <ListItem button key='generalCompromises' component={Link} to='/generalCompromises'>
                        <ListItemIcon><CheckBoxOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.Compromises}/>
                    </ListItem>

                    <ListItem button key='report' component={Link} to='/report'>
                        <ListItemIcon><AssignmentIndOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.Report}/>
                    </ListItem>

                    <ListItem button key='securityReflection' component={Link} to='/securityReflection'>
                        <ListItemIcon><SecurityOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.InChargeOfAnnotations}/>
                    </ListItem>

                    <ListItem button key='activePause' component={Link} to='/activePause'>
                        <ListItemIcon><PauseCircleOutline/></ListItemIcon>
                        <ListItemText primary={Strings.ActivePause}/>
                    </ListItem>

                    <ListItem button key='helpItems' onClick={() => {
                        setOpenHelpItems(!openHelpItems)
                    }}>
                        <ListItemIcon><PersonPinCircle/></ListItemIcon>
                        <ListItemText primary={Strings.Administration}/>
                    </ListItem>
                    <Collapse in={openHelpItems} timeout="auto" unmountOnExit>
                        <Divider/>
                        <List component="div" disablePadding>
                            <ListItem button key='sgiHelp' component={Link} to='/sgiFiles'>
                                <ListItemIcon><AttachFile/> </ListItemIcon>
                                <ListItemText primary={Strings.Files}/>
                            </ListItem>
                            <ListItem button key='sgiHelp' component={Link} to='/sgiHelp'>
                                <ListItemIcon><Book/> </ListItemIcon>
                                <ListItemText primary={Strings.SgiHelp}/>
                            </ListItem>
                            <ListItem button key='wiki' component={Link} to='/wiki'>
                                <ListItemIcon><HelpOutline/></ListItemIcon>
                                <ListItemText primary={Strings.Wiki}/>
                            </ListItem>

                            {identity && (identity.credentials.roles.includes(appRoles.humanResources)) && <>
                                <ListItem button key='humanResourcesCompromises' component={Link}
                                          to='/humanResourcesCompromises'>
                                    <ListItemIcon><LiveHelp/></ListItemIcon>
                                    <ListItemText primary={Strings.HumanResourcesCompromises}/>
                                </ListItem>
                            </>}

                            {identity && (identity.credentials.roles.includes(appRoles.projectManager)) && <>
                                <ListItem button key='projectManagerCompromises' component={Link}
                                          to='/projectManagerCompromises'>
                                    <ListItemIcon><TabletSharp/></ListItemIcon>
                                    <ListItemText primary={Strings.ProjectManagerCompromises}/>
                                </ListItem>
                            </>}

                        </List>

                        <Divider/>
                    </Collapse>


                    <ListItem button key='externalUsers' component={Link} to='/externalUsers'>
                        <ListItemIcon><ContactsOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.ExternalUsers}/>
                    </ListItem>

                    <ListItem button key='clients' component={Link} to='/clients'>
                        <ListItemIcon><BusinessOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.Clients}/>
                    </ListItem>

                    <ListItem button key='userHoliday' component={Link} to='/userHoliday'>
                        <ListItemIcon><WbSunnyTwoTone/></ListItemIcon>
                        <ListItemText primary={Strings.Holidays}/>
                    </ListItem>
                    
                    <ListItem button key='accountRequest' component={Link} to='/accountRequest'>
                        <ListItemIcon><GroupAddOutlined/></ListItemIcon>
                        <ListItemText primary={Strings.AccountRequest}/>
                    </ListItem>
                    {identity && (identity.credentials.roles.includes(appRoles.admin)) && <>
                        <ListItem button key='accountRequestApproval' component={Link} to='/accountRequestApproval'>
                            <ListItemIcon><HowToReg/></ListItemIcon>
                            <ListItemText primary={Strings.AccountRequestApproval}/>
                        </ListItem>
                    </>}

                </List>

                {/*Holiday area*/}
                {identity && (identity.credentials.roles.includes(appRoles.admin)
                    || identity.credentials.roles.includes(appRoles.holiday)) && <>
                    <Divider/>
                    <List>
                        <ListItem button key='acceptHoliday' component={Link} to='/acceptHoliday' hidden={true}>
                            <ListItemIcon><WbCloudy/></ListItemIcon>
                            <ListItemText primary={Strings.PendingHolidays}/>
                        </ListItem>

                        <ListItem button key='userHolidayAdmin' component={Link} to='/userHolidayAdmin'>
                            <ListItemIcon><WbSunnyOutlined/></ListItemIcon>
                            <ListItemText primary={Strings.HolidaysDashboard}/>
                        </ListItem>
                    </List>
                </>}

                {/* Creator area */}
                {(identity && (identity.credentials.roles.includes(appRoles.creator) ||
                    identity.credentials.roles.includes(appRoles.admin))) && <>
                    <Divider/>
                    <ListItem button key='addProject' component={Link} to='/addProject'>
                        <ListItemIcon><NoteAdd/></ListItemIcon>
                        <ListItemText primary={Strings.AddProject}/>
                    </ListItem>

                    <ListItem button key='addMember' component={Link} to='/addMember'>
                        <ListItemIcon><GroupAdd/></ListItemIcon>
                        <ListItemText primary={`${Strings.AddMember}`}/>
                    </ListItem>
                </>}
                {/* Admin area */}
                {identity && identity.credentials.roles.includes(appRoles.admin) &&
                    <List>
                        <Divider/>
                        <ListItem button key='users' component={Link} to='/users'>
                            <ListItemIcon><People/></ListItemIcon>
                            <ListItemText primary='Usuarios'/>
                        </ListItem>

                        <ListItem button key='token' component={Link} to='/token'>
                            <ListItemIcon><CodeTwoTone/></ListItemIcon>
                            <ListItemText primary='Token'/>
                        </ListItem>

                        <ListItem button key='userHours' component={Link} to='/userHours'>
                            <ListItemIcon><FaceTwoTone/></ListItemIcon>
                            <ListItemText primary='User Hours'/>
                        </ListItem>


                        <ListItem button key='addUser' component={Link} to='/addUser'>
                            <ListItemIcon><PersonAdd/></ListItemIcon>
                            <ListItemText primary='Agregar usuario'/>
                        </ListItem>

                        <ListItem button key='pendingUsers' component={Link} to='/pendingUsers'>
                            <ListItemIcon><TrendingDownOutlined/></ListItemIcon>
                            <ListItemText primary={Strings.PendingUsers}/>
                        </ListItem>

                        <ListItem button key='secondaryApprover' component={Link} to='/secondaryApprovers'>
                            <ListItemIcon><PeopleAltOutlined/></ListItemIcon>
                            <ListItemText primary={Strings.SecondaryApprovers}/>
                        </ListItem>


                    </List>}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>
                    <Route exact path="/">
                        {/*<ImageEditDataTemplateComponent/>*/}
                    </Route>
                    <Route path='/pelambres' component={PelambresComponent}/>
                    <Route path='/forbidden' component={ForbiddenComponent}/>

                    <PrivateRoute exact path='/clients' component={ClientsComponent}/>
                    <PrivateRoute exact path='/wiki' component={WikiComponent}/>
                    <PrivateRoute exact path='/trackingHours/:year/:month/:day' component={TrackingHoursComponent}/>
                    <PrivateRoute exact path='/trackingHours' component={TrackingHoursComponent}/>
                    <PrivateRoute exact path='/token' component={TokenComponent}/>
                    <PrivateRoute exact path='/approveHours' component={ApproveHoursComponent}/>
                    <PrivateRoute exact path='/report' component={ReportComponent}/>
                    <PrivateRoute exact path='/addMember' component={AddMemberComponent}/>
                    <PrivateRoute exact path='/generalCompromises' component={GeneralCompromisesComponent}/>
                    <PrivateRoute exact path='/editActivityRecord' component={ActivityRecordEditComponent}/>
                    <PrivateRoute exact path='/addMember' component={AddMemberComponent}/>
                    <PrivateRoute exact path='/externalUsers' component={ExternalUsersComponent}/>
                    <PrivateRoute exact path='/activityRecords' component={ActivityRecordComponent}/>
                    <PrivateRoute exact path='/compromises' component={CompromisesComponent}/>
                    <PrivateRoute exact path='/activityRecordsByProject' component={ActivityRecordByProjectComponent}/>
                    <PrivateRoute exact path='/projects' component={ProjectsComponent}/>
                    <PrivateRoute exact path='/activityRecordHelp' component={ActivityRecordHelpComponent}/>
                    <PrivateRoute exact path='/securityReflection' component={SecurityReflectionComponent}/>
                    <PrivateRoute exact path='/generalCompromises' component={GeneralCompromisesComponent}/>
                    <PrivateRoute exact path='/myCompromises' component={UserCompromisesComponent}/>
                    <PrivateRoute exact path='/projectCompromises' component={ProjectCompromisesComponent}/>
                    <PrivateRoute exact path='/userHoliday' component={UserHolidayComponent}/>
                    <PrivateRoute exact path='/user' component={UserComponent}/>
                    <PrivateRoute exact path='/activePause' component={ActivePauseComponent}/>
                    <PrivateRoute exact path='/sgiHelp' component={SgiHelpComponent}/>
                    <PrivateRoute exact path='/sgiFiles' component={SgiFilesComponent}/>
                    <PrivateRoute exact path='/accountRequest' component={AccountRequestComponent}/>
                    <PrivateRoute exact path='/accountRequestApproval' component={ApproveAccountRequestComponent}/>

                    <AdminPrivateRoute exact path='/users' component={UsersComponent}/>
                    <AdminPrivateRoute exact path='/userHours' component={AdminViewUserHoursComponent}/>
                    <AdminPrivateRoute exact path='/addUser' component={AddUserComponent}/>
                    <AdminPrivateRoute exact path='/pendingUsers' component={UsersPendingToUploadHoursComponent}/>


                    <AdminHolidayPrivateRoute exact path='/userHolidayAdmin' component={UserHolidayAdminComponent}/>
                    <AdminHolidayPrivateRoute exact path='/acceptHoliday' component={AcceptHolidayComponent}/>

                    <HumanResourcesPrivateRoute exact path='/humanResourcesCompromises'
                                                component={HumanResourcesCompromiseNavigateComponent} />
                    <ProjectManagerPrivateRoute exact path='/projectManagerCompromises'
                                                component={ProjectManagerCompromiseNavigateComponent}/>


                    <AdminCreatorPrivateRoute exact path='/addProject' component={AddProjectComponent}/>
                    <AdminCreatorPrivateRoute exact path='/secondaryApprovers'
                                              component={SecondaryApproverAdminComponent}/>


                    <Route path="*">
                        <NotFoundComponent/>
                    </Route>
                </Switch>
            </main>
        </div>
    );
}