import React, {useEffect} from 'react'
import {Autocomplete} from "@material-ui/lab";
import {Box, Button, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {elevation, margin, padding} from "../../styles/styleConstants";
import {List as ListLinq} from "linq-typescript";
import {IUserModel} from "../../api/IUserModel";
import {Strings} from "../../Resources/Strings";
import {IProjectMemberRefModel} from "../../api/IProjectMemberRefModel";
import {MembersComponent} from "../AdminViewUserHours/MembersComponent";
import {IUserRefModel} from "../../api/IUserRefModel";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {notification} from "../../redux/notification";
import {question} from "../../redux/question";
import {projectService} from "../../api/ProjectsService";
import {loadProjectsThunk, project} from "../../redux/project";
import {loadUsersThunk} from "../../redux/user";

interface IProjectDisplay {
    id: string;
    name: string;
    code: string;
    codeName: string;
    manager: IUserRefModel;
    members: IProjectMemberRefModel[]
}

interface IUserDisplay {
    id: string;
    name: string;
    surname: string;
    email: string;
}


export const AddMemberComponent = () => {
    const {selectedProject, projects} = useSelector((state: RootState) => state.project)
    const {users} = useSelector((state: RootState) => state.user)

    const [selectedDisplayProject, setSelectedDisplayProject] = React.useState<IProjectDisplay>(null);
    const [displayProjects, setDisplayProjects] = React.useState<IProjectDisplay[]>([]);

    const [projectMembers, setProjectMembers] = React.useState<IUserModel[]>([]);
    const [projectManager, setProjectManager] = React.useState<IUserModel>(null);

    const [selectedUser, setSelectedUser] = React.useState<IUserDisplay>(null);
    const [availableUsers, setAvailableUsers] = React.useState<IUserDisplay[]>();

    const [allUsers, setAllUsers] = React.useState<IUserModel[]>();

    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        async function fetch() {
            await loadProjectsThunk(dispatch)
            await loadUsersThunk(dispatch)
        }
        fetch().then(() => {
        }).catch(reason => {
            dispatch(notification.actions.openDialog({text: reason, title: Strings.Error}))
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!projects || !users) return;
        let projectModels = new ListLinq(projects).where(element => !element.isOrganizational).toArray();
        setAllUsers(users)

        let displayProjects = projectModels.map(value => {
            return {
                code: value.code,
                codeName: `${value.code} ${value.name}`,
                manager: value.manager,
                name: value.name,
                id: value.id,
                members: value.members
            } as IProjectDisplay
        })
        setDisplayProjects(displayProjects)
    }, [projects, users])

   function getOptions() {
        if (!selectedDisplayProject){
            return []
        }
        else{
            return availableUsers
        };
    }

    useEffect(() => {
        if (displayProjects.length > 0 && selectedProject && selectedDisplayProject === null) {
            onProjectChanged(displayProjects[displayProjects.findIndex(value => value.id === selectedProject.id)])
        }
    }, [displayProjects, selectedProject])

    function onProjectChanged(project: IProjectDisplay | null) {
        setSelectedDisplayProject(project);
        if (project == null) {
            setAvailableUsers([])
            setSelectedUser(null)
            setProjectMembers([])
            setProjectManager(null)
            return;
        }
        let usersList = new ListLinq(allUsers);
        let members = new ListLinq(project.members);
        let validUsers = usersList.where(element => !members.any(member => member.id === element.id));

        setProjectMembers(usersList.where(member =>
            members.firstOrDefault(element => element.id === member.id) != null).toArray());
        setProjectManager(usersList.first(element => element.id === project.manager.id))

        setAvailableUsers(validUsers.select(element => {
            return {
                id: element.id,
                email: element.email,
                name: element.name,
                surname: element.surname
            } as IUserDisplay
        }).toArray())
    }

    async function addMemberCallback() {
        const addMember = async () => {
            let userEmail = selectedUser.email;
            let user = new ListLinq(allUsers).first(element => element.id === selectedUser.id);
            let projectId = selectedDisplayProject.id;
            await projectService.addMember(projectId, userEmail).then(() => {
                let newMembers = [...projectMembers, user]
                setProjectMembers(newMembers)
                dispatch(notification.actions.openDialog({
                    text: `${user.email} ${Strings.Added}`,
                    title: Strings.Notification
                }))
            });
        };

        dispatch(question.actions.openDialog({
            text: Strings.DoYouWishToAddUser,
            title: Strings.Question,
            actionOnYes: addMember
        }))
    }

    return <>
        <Box m={margin} p={padding}>
            <Paper elevation={elevation}>
                <Grid container direction='row' alignContent='center' alignItems='center'>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Typography variant='h6'>{Strings.Project}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          defaultValue={selectedDisplayProject}
                                          value={selectedDisplayProject}
                                          onChange={(event: any, newValue: IProjectDisplay | null) => {
                                              onProjectChanged(newValue);
                                          }}
                                          id="project-autocomplete"
                                          options={displayProjects}
                                          getOptionLabel={(option) => option.codeName}
                                          renderInput={(params) =>
                                              <TextField {...params} variant="outlined"/>}/>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Typography variant='h6'>{Strings.User}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs>

                            <Box m={margin} p={padding}>
                                <Autocomplete fullWidth
                                            value={selectedUser}
                                            onChange={(event: any, newValue: IUserDisplay | null) => {
                                                setSelectedUser(newValue);
                                            }}
                                            id="user-autocomplete"
                                            options={getOptions()}
                                            getOptionLabel={(option) => option.email}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                            variant="outlined"/>}/>
                            </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button onClick={addMemberCallback} variant='contained'
                                    disabled={selectedDisplayProject == null || selectedUser == null}>{Strings.Add}</Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <MembersComponent members={projectMembers} projectManager={projectManager}/>
                </Grid>
            </Paper>
        </Box>
    </>
}


