import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {IUserHolidayModel} from "../api/IUserHolidayModel";
import {userHolidayService} from "../api/UserHolidayService";
import {IUserModel} from "../api/IUserModel";
import {notification} from "./notification";
import {Strings} from "../Resources/Strings";
import {HolidayTypeEnum} from "../api/HolidayTypeEnum";
import {question} from "./question";
import {progress} from "./progress";
import {loadUsersThunk} from "./user";
import { IUserHolidayRegisterDisplayModel } from '../api/IUserHolidayRegisterDisplayModel';

export interface UserHolidayState {
    isCreatingUserHolidayProfile: boolean,
    isAddingUserHolidays: boolean,
    userHolidays: IUserHolidayModel[],
    selectedUserForHolidays: IUserModel,
    hasUserProfile: boolean,
    userProfile: IUserHolidayModel,
    availableApprovingUsers: IUserModel[]
    usersHolidayRegister: IUserHolidayRegisterDisplayModel[]
}

const initialState: UserHolidayState = {
    usersHolidayRegister: [],
    availableApprovingUsers: [],
    isCreatingUserHolidayProfile: false,
    isAddingUserHolidays: false,
    userHolidays: [],
    selectedUserForHolidays: null,
    hasUserProfile: false,
    userProfile: null
}

export const userHoliday = createSlice({
    name: 'userHoliday',
    initialState: initialState,
    reducers: {
        setUserHolidays: (state: UserHolidayState, payload: PayloadAction<IUserHolidayModel[]>) => {
            state.userHolidays = [...payload.payload]
            return state;
        },
        openCreateUserHolidayProfileDialog(state: UserHolidayState) {
            state.isCreatingUserHolidayProfile = true
            return state
        },
        closeCreateUserHolidayProfileDialog(state: UserHolidayState) {
            state.isCreatingUserHolidayProfile = false
            return state
        },
        openAddUserHolidaysDialog(state: UserHolidayState) {
            state.isAddingUserHolidays = true
            return state
        },
        closeAddUserHolidaysDialog(state: UserHolidayState) {
            state.isAddingUserHolidays = false
            return state
        },
        setSelectedUser(state: UserHolidayState, payload: PayloadAction<IUserModel>) {
            state.selectedUserForHolidays = payload.payload;
            return state
        },
        setUserProfile(state: UserHolidayState, payload: PayloadAction<IUserHolidayModel>) {
            state.userProfile = payload.payload;
            state.hasUserProfile = true;
            return state;
        },
        unsetUserProfile(state: UserHolidayState) {
            state.userProfile = null
            state.hasUserProfile = false;
            return state;
        },
        setAvailableApprovingUsers(state: UserHolidayState, payload: PayloadAction<IUserModel[]>) {
            state.availableApprovingUsers = payload.payload;
            return state;
        },
        setUsersHolidayRegisterDisplay(state: UserHolidayState, payload: PayloadAction<IUserHolidayRegisterDisplayModel[]>) {
            state.usersHolidayRegister = payload.payload;
            return state;
        }

    }
})

export async function loadUserProfileThunk(dispatch: AppDispatch) {
    let status = await userHolidayService.hasUserHoliday();
    if (!status)
        await dispatch(userHoliday.actions.unsetUserProfile())
    else {
        let userHolidayModel = await userHolidayService.getUserHoliday()
        await dispatch(userHoliday.actions.setUserProfile(userHolidayModel))
    }
}

export async function loadUserHolidaysThunk(dispatch: AppDispatch) {
    let userHolidayModels = await userHolidayService.getUsersHolidays();
    await dispatch(userHoliday.actions.setUserHolidays(userHolidayModels));
}

export async function loadApprovingUsersThunk(dispatch: AppDispatch) {
    let userModels = await userHolidayService.getAvailableApprovingUsers();
    await dispatch(userHoliday.actions.setAvailableApprovingUsers(userModels));
}

export async function loadUsersHolidayRegisterDisplayThunk(dispatch: AppDispatch) {
    let userHolidayRegisterDisplayModels = await userHolidayService.getUsersHolidayRegisterDisplay();
    await dispatch(userHoliday.actions.setUsersHolidayRegisterDisplay(userHolidayRegisterDisplayModels))
}

export function openCreateUserHolidayProfileThunk(userId: string) {
    return async function addCompromise(dispatch: AppDispatch, getState: () => RootState) {
        let users = getState().user.users;
        let user = users.find(value => value.id === userId);
        await dispatch(userHoliday.actions.setSelectedUser(user))
        await dispatch(userHoliday.actions.openCreateUserHolidayProfileDialog())
    }
}

export function createUserHolidayProfileThunk(userId: string, incorporationDate: Date,
                                              monthsOfExperienceAtIncorporation: number,
                                              rut: number, verificationNumber: number) {
    return async function createUserHolidayProfile(dispatch: AppDispatch, getState: () => RootState) {
        let userHolidays = getState().userHolidays.userHolidays;
        // Check if user holiday already exists
        let userHolidayProfile = userHolidays.find(value => value.user.id === userId);
        if (userHolidayProfile) {
            dispatch(notification.actions.openDialog({title: Strings.Error, text: Strings.UserHasAlreadyAProfile}))
            return;
        }
        await userHolidayService.createUserHolidayProfile(userId, incorporationDate,
            monthsOfExperienceAtIncorporation, rut, verificationNumber)
        await loadUserHolidaysThunk(dispatch)
        await dispatch(userHoliday.actions.closeCreateUserHolidayProfileDialog())
    }
}

export function openAddHolidaysDialogThunk(userId: string) {
    return async function addCompromise(dispatch: AppDispatch, getState: () => RootState) {
        let users = getState().user.users;
        let user = users.find(value => value.id === userId);
        await dispatch(userHoliday.actions.setSelectedUser(user))
        await dispatch(userHoliday.actions.openAddUserHolidaysDialog())
    }
}

export function addAdminHolidaysThunk(userId: string, fromDate: Date, toDate: Date, usedDays: number,
                                      comment: string, holidayType: HolidayTypeEnum) {
    return async function addCompromise(dispatch: AppDispatch, getState: () => RootState) {
        let adminId = getState().authentication.identity.credentials.id;
        await userHolidayService.addHolidays(userId, fromDate, toDate, usedDays,
                                             comment, true, holidayType, adminId);
        await loadUserHolidaysThunk(dispatch)
        await dispatch(userHoliday.actions.closeAddUserHolidaysDialog())
    }
}

export function requestUserHolidaysThunk(userId: string, fromDate: Date, toDate: Date, usedDays: number,
                                         comment: string, holidayType: HolidayTypeEnum, approvingUserId: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        await userHolidayService.addHolidays(userId, fromDate, toDate, usedDays, comment,
            false, holidayType, approvingUserId);
        await loadUserHolidaysThunk(dispatch)
        await dispatch(userHoliday.actions.closeAddUserHolidaysDialog())
    }
}

export function acceptHoursThunk(userHolidayRegisterId: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        await dispatch(question.actions.openDialog({
            title: Strings.Question,
            text: Strings.AreYouSure,
            actionOnYes: async () => {
                await dispatch(progress.actions.openProgressBar({}))
                await userHolidayService.acceptUserHolidayRegister(userHolidayRegisterId);
                await loadUserHolidaysThunk(dispatch)
                await loadUsersThunk(dispatch)
                await dispatch(progress.actions.closeProgressBar())
            }
        }))
    }
}

export function rejectUserHolidayRegisterThunk(userHolidayRegisterId: string) {
    return async function addCompromise(dispatch: AppDispatch) {
        await dispatch(question.actions.openDialog({
            title: Strings.Question,
            text: Strings.AreYouSure,
            actionOnYes: async () => {
                await dispatch(progress.actions.openProgressBar({}))
                await userHolidayService.rejectUserHolidayRegister(userHolidayRegisterId);
                await loadUserHolidaysThunk(dispatch)
                await loadUsersThunk(dispatch)
                await dispatch(progress.actions.closeProgressBar())
            }
        }))
    }
}

export function deleteUserHolidayRegisterThunk(userHolidayRegisterId: string) {
    return async function deleteUserHolidayRegister(dispatch: AppDispatch) {
        await dispatch(question.actions.openDialog({
            title: Strings.Question,
            text: Strings.AreYouSure,
            actionOnYes: async () => {
                await dispatch(progress.actions.openProgressBar({}))
                await userHolidayService.deleteUserHolidayRegister(userHolidayRegisterId);
                await loadUserHolidaysThunk(dispatch)
                await dispatch(progress.actions.closeProgressBar())
            }
        }))
    }
}

export function downloadUserHolidayRegisterDocumentThunk(userHolidayRegisterId: string) {
    return async function downloadUserHolidayRegisterDocument(dispatch: AppDispatch) {
        await dispatch(progress.actions.openProgressBar({}))
        await userHolidayService.downloadUserHolidayRegisterDocument(userHolidayRegisterId)
        await dispatch(progress.actions.closeProgressBar())
    }
}