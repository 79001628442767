import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {activityRecordsService} from "../api/ActivityRecordService";
import {IActivityRecord} from "../api/IActivityRecord";

export interface ActivityRecordState {
    isEditingActivityRecordExternalUsers: boolean,
    isEditingActivityRecordBminingUsers: boolean,
    isAddingActivityRecord: boolean,
    activityRecords: IActivityRecord[],
    selectedActivityRecord: IActivityRecord,
}

const initialState: ActivityRecordState = {
    isAddingActivityRecord: false,
    activityRecords: [],
    selectedActivityRecord: null,
    isEditingActivityRecordExternalUsers: false,
    isEditingActivityRecordBminingUsers: false
}

export const activityRecord = createSlice({
    name: 'activityRecord',
    initialState: initialState,
    reducers: {
        openAddActivityRecord: (state: ActivityRecordState) => {
            state.isAddingActivityRecord = true;
            return state;
        },
        closeAddActivityRecord: (state: ActivityRecordState) => {
            state.isAddingActivityRecord = false;
            return state;
        },
        setActivityRecords: (state: ActivityRecordState, payloadAction: PayloadAction<IActivityRecord[]>) => {
            state.activityRecords = payloadAction.payload;
            return state;
        },
        setSelectedActivityRecord: (state: ActivityRecordState, payloadAction: PayloadAction<IActivityRecord>) => {
            state.selectedActivityRecord = payloadAction.payload;
            return state;
        },
        unsetSelectedActivityRecord: (state: ActivityRecordState) => {
            state.selectedActivityRecord = null;
            return state;
        },
        openEditActivityRecordExternalUsers: (state: ActivityRecordState) => {
            state.isEditingActivityRecordExternalUsers = true;
            return state;
        },
        closeEditActivityRecordExternalUsers: (state: ActivityRecordState) => {
            state.isEditingActivityRecordExternalUsers = false;
            return state;
        },
        openEditActivityRecordBminingUsers: (state: ActivityRecordState) => {
            state.isEditingActivityRecordBminingUsers = true;
            return state;
        },
        closeEditActivityRecordBminingUsers: (state: ActivityRecordState) => {
            state.isEditingActivityRecordBminingUsers = false;
            return state;
        }
    }
})

export function addActivityRecordThunk(title: string, projectId: string) {
    return async function addExternalUser(dispatch: AppDispatch) {
        // Add external user
        await activityRecordsService.addActivityRecords(title, projectId).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
        dispatch(activityRecord.actions.closeAddActivityRecord())
    }
}

export async function loadActivityRecordsThunk(dispatch: AppDispatch) {
    let activityRecords = await activityRecordsService.getActivityRecords();
    dispatch(activityRecord.actions.setActivityRecords(activityRecords));
}

export function updateActivityRecordThunk(id: string, title: string, date: Date, durationHours: number, contentHtml: string) {
    return async function updateActivityRecord(dispatch: AppDispatch) {
        // Update
        await activityRecordsService.updateActivityRecord(id, title, date, durationHours, contentHtml).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
    }
}

export function addExternalUserFromActivityRecordThunk(activityRecordId: string, externalUserId: string) {
    return async function addExternalUserToActivityRecord(dispatch: AppDispatch, getState: () => RootState) {
        await activityRecordsService.addExternalUser(activityRecordId, externalUserId).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
        let activityRecordItem = getState().activityRecord.activityRecords.find(value => value.id === activityRecordId)
        await dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
    }
}

export function removeExternalUserFromActivityRecordThunk(activityRecordId: string, externalUserId: string) {
    return async function removeExternalUserToActivityRecord(dispatch: AppDispatch, getState: () => RootState) {
        await activityRecordsService.removeExternalUser(activityRecordId, externalUserId).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
        let activityRecordItem = getState().activityRecord.activityRecords.find(value => value.id === activityRecordId)
        dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
    }
}

export function addBminingUserFromActivityRecordThunk(activityRecordId: string, bminingUserId: string) {
    return async function addExternalUserToActivityRecord(dispatch: AppDispatch, getState: () => RootState) {
        await activityRecordsService.addBminingUser(activityRecordId, bminingUserId).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
        let activityRecordItem = getState().activityRecord.activityRecords.find(value => value.id === activityRecordId)
        await dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
    }
}

export function removeBminingUserFromActivityRecordThunk(activityRecordId: string, bminingUserId: string) {
    return async function removeExternalUserToActivityRecord(dispatch: AppDispatch, getState: () => RootState) {
        await activityRecordsService.removeBminingUser(activityRecordId, bminingUserId).catch(reason => {
            console.error(reason)
        })
        await loadActivityRecordsThunk(dispatch);
        let activityRecordItem = getState().activityRecord.activityRecords.find(value => value.id === activityRecordId)
        dispatch(activityRecord.actions.setSelectedActivityRecord(activityRecordItem))
    }
}