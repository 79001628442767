import {IUserModel} from "../../api/IUserModel";
import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {signatureService} from "../../api/SignatureService";
import {Box, Button, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {CloudUpload, Delete} from "@material-ui/icons";
import {Strings} from "../../Resources/Strings";
import {updateCurrentUser} from "../../redux/user";
import {question} from "../../redux/question";
import {margin, padding} from "../../styles/styleConstants";

export const SignatureComponent = () => {

    const dispatch = useDispatch();
    const {currentUser} = useSelector((state: RootState) => state.user);
    const [imageSource, setImageSource] = useState('')

    async function loadPicture() {
        let signature = await signatureService.getSignature();
        setImageSource(signature.signatureImage)
    }

    useEffect(() => {
        if (currentUser)
            if (currentUser.hasSignature) {
                loadPicture()

            } else {

            }
    }, [currentUser])

    async function uploadDocument(event: any) {
        let htmlInputElement: HTMLInputElement = event.target
        if (htmlInputElement.files.length === 0)
            return;
        let reader = new FileReader();
        reader.onload = async (event) => {
            let signatureUrl = event.target.result as string
            // Send to the api
            await signatureService.createSignature(signatureUrl)
            await dispatch(updateCurrentUser);
        };
        reader.readAsDataURL(htmlInputElement.files[0])
    }

    async function deleteCallback() {
        await dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            title: Strings.Question,
            actionOnYes: async () => {
                await signatureService.deleteSignature()
                await dispatch(updateCurrentUser)
            }
        }))
    }

    return <>
        {/*Case of no signature*/}
        {!currentUser?.hasSignature && <>
            <Grid container direction='row' alignItems='center'>
                <Grid item xs='auto'>
                    <Typography variant='body1'>{Strings.YouDontHaveASignature}</Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Tooltip title={Strings.UploadSignature}>
                        <IconButton component="label" color='primary'>
                            <CloudUpload/>
                            <input type="file" onChange={uploadDocument}
                                   accept="image/png, image/jpeg"
                                   hidden/>
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid item xs='auto'>
                    <Tooltip title={Strings.SignatureSample}>
                        <img src={'/Firma Test.png'} height={50} alt='sample-signature'/>
                    </Tooltip>
                </Grid>
            </Grid>
        </>}
        {/*Case of signature*/}
        {currentUser?.hasSignature && <>
            <Grid container direction='row' alignItems='center'>
                <Grid item xs='auto'>
                    <Box m={margin}>
                        <Typography variant='body1'>{Strings.Signature}</Typography>
                    </Box>
                </Grid>

                <Grid item xs='auto'>
                    <img height={50} src={`data:image/png;base64,${imageSource}`} alt='signature'/>
                </Grid>

                <Grid item xs='auto'>
                    <Tooltip title={Strings.Delete}>
                        <IconButton onClick={deleteCallback}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </>}
    </>
}