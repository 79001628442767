import React, {FC, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {margin, padding} from "../../../styles/styleConstants";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {isEmpty} from "../../../utils/utils";
import {IActivityRecord} from "../../../api/IActivityRecord";
import {addActivityRecordCompromiseThunk, addGeneralCompromiseThunk, compromise} from "../../../redux/compromise";
import {IUserModel} from "../../../api/IUserModel";
import {LoadingButton} from "../../DataTemplates/LoadingButton/LoadingButton";
import {loadUsersThunk} from "../../../redux/user";
import {progress} from "../../../redux/progress";


export const AddGeneralCompromiseDialogComponent = () => {
    const dispatch = useDispatch();

    const {users} = useSelector((state: RootState) => state.user);
    const {isAddingGeneralCompromise} = useSelector((state: RootState) => state.compromise);

    const [title, setTitle] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<IUserModel>();

    const [isLoading, setIsLoading] = useState<boolean>()
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(false)

    useEffect(() => {
        setSelectedUser(null)

        async function loadData() {
            await dispatch(loadUsersThunk)
        }

        // noinspection JSIgnoredPromiseFromCall
        loadData()
    }, [isAddingGeneralCompromise])

    useEffect(() => {
        if (isEmpty(title) || selectedUser === null || selectedUser === undefined)
            setIsYesAvailable(false)
        else setIsYesAvailable(true)
    }, [title, selectedUser]);

    const handleNo = () => {
        dispatch(compromise.actions.closeGeneralCompromise());
    }

    const handleYes = async () => {
        setIsLoading(true)
        await dispatch(addGeneralCompromiseThunk(selectedUser.id, title))
        setIsLoading(false)
    }

    const titleChangedCallback = (event: any | HTMLInputElement) => {
        setTitle(event.target.value)
    }

    return <>
        <Dialog open={isAddingGeneralCompromise} onClose={handleNo} fullWidth>
            <DialogTitle>{Strings.NewCompromise}</DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.Title} onChange={titleChangedCallback} fullWidth/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          value={selectedUser}
                                          onChange={(event: any, newValue: IUserModel | null) => {
                                              setSelectedUser(newValue)
                                          }}
                                          id="user-autocomplete"
                                          options={users}
                                          getOptionLabel={(option) => `${option.email}`}
                                          renderInput={(params) =>
                                              <TextField {...params} label={Strings.Owner}
                                                         variant="outlined"/>}/>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleYes} inProgress={isLoading} text={Strings.Yes}
                               variant='contained' disabled={isLoading || !isYesAvailable} color='primary'
                               progressBarColor='secondary'/>
                <Button onClick={handleNo} color="secondary" variant='contained' disabled={isLoading}>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

