import React, {useEffect} from 'react'
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Strings} from "../../Resources/Strings";
import {Box, Button, Grid, Paper, Typography} from "@material-ui/core";
import {elevation, margin, padding} from "../../styles/styleConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import MaterialTable, {Column} from "material-table";
import {progress} from "../../redux/progress";
import {adminService} from "../../api/AdminService";
import moment from "moment";

interface IUsersPendingToUploadHoursRowData {
    email: string,
    name: string,
    surname: string,
}

function tableColumns() {
    let items: Column<IUsersPendingToUploadHoursRowData>[] = [];
    items.push({
        title: Strings.Email,
        field: 'email',
        editable: 'never'
    })
    items.push({
        title: Strings.Name,
        field: 'name',
        editable: 'never'
    })
    items.push({
        title: Strings.Surname,
        field: 'surname',
        editable: 'never'
    })

    return items;
}

export const UsersPendingToUploadHoursComponent = () => {
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const dispatch = useDispatch();

    const [fromDate, setFromDate] = React.useState<Date>();
    const [toDate, setToDate] = React.useState<Date>();
    const [rowData, setRowData] = React.useState<IUsersPendingToUploadHoursRowData[]>([])

    useEffect(() => {
        let today = moment().toDate();
        setToDate(new Date(today))
        today.setUTCDate(today.getUTCDate() - 7)
        setFromDate(new Date(today))
    }, [])


    const handleFromDataChanged = (date: Date) => {
        setFromDate(date);
    }

    const handleToDataChanged = (date: Date) => {
        setToDate(date);
    }


    const searchPendingUsersCallback = async () => {
        await dispatch(progress.actions.openProgressBar({}))
        let pendingUsers = await adminService.getUsers(fromDate, toDate);
        let newItems: IUsersPendingToUploadHoursRowData[] = []
        for (let pendingUser of pendingUsers) {
            newItems.push({
                email: pendingUser.email,
                name: pendingUser.name,
                surname: pendingUser.surname
            })
        }
        setRowData([...newItems])
        await dispatch(progress.actions.closeProgressBar())
    }

    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Grid container direction='row'
                      justifyContent='space-between'
                      alignItems='center'>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Typography variant='h6'>{Strings.InitialDate}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={fromDate} onChange={handleFromDataChanged}
                                            title={Strings.InitialDate} format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Typography variant='h6'>{Strings.FinalDate}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={toDate} onChange={handleToDataChanged}
                                            title={Strings.FinalDate} format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs='auto'>
                        <Box p={padding} m={margin}>
                            <Button variant='contained' color='primary' onClick={searchPendingUsersCallback} fullWidth>
                                {Strings.Search}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>

        <Box m={margin} p={padding}>
            <MaterialTable title={Strings.PendingUsers} isLoading={isOpen} columns={tableColumns()} data={rowData}
                           options={{
                               actionsColumnIndex: -1,
                               filtering: true,
                               paging: false,
                               pageSize: 5
                           }}>
            </MaterialTable>
        </Box>
    </>
}

