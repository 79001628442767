import {ICredentials} from "./ICredentials";

export class Credentials implements ICredentials {
    isAuthenticated: boolean;
    roles: string[];
    email: string;
    id: string;

    constructor() {
        this.isAuthenticated = false;
        this.roles = [];
        this.email = '';
        this.id = '';
    }

    setId(id: string) {
        this.id = id;
    }

    setAuthenticated() {
        this.isAuthenticated = true;
    }

    addRoles(role: string) {
        this.roles.push(role);
    }
}