import React, {ChangeEvent} from 'react'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {ProjectTypeEnum} from '../../api/ProjectTypeEnum';
import {GetProjectTypeString, isEmpty} from "../../utils/utils";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {question} from "../../redux/question";
import {notification} from "../../redux/notification";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {clientsService} from "../../api/ClientsService";
import {usersService} from "../../api/UsersService";
import {projectService} from "../../api/ProjectsService";

const margin = 1;
const padding = 1;
const elevation = 1;

export interface IProjectsManagerDialogItem {
    managerId: string,
    managerName: string,
    managerSurname: string,
    managerEmail: string,
    managerDisplay: string,
}

export interface IProjectsClientDialogItem {
    clientId: string,
    clientName: string,
}

export const AddProjectComponent = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [canCreate, setCanCreate] = React.useState<boolean>(false);

    const [selectedManager, setSelectedManager] = React.useState<IProjectsManagerDialogItem>(null);
    const [managers, setManagers] = React.useState([] as IProjectsManagerDialogItem[]);

    const [selectedClient, setSelectedClient] = React.useState<IProjectsClientDialogItem>(null);
    const [clients, setClients] = React.useState([] as IProjectsClientDialogItem[]);


    const [code, setCode] = React.useState<string>('')
    const [name, setName] = React.useState<string>('')
    const [isOrganizational, setIsOrganizational] = React.useState<boolean>(false);

    const [selectedProjectType, setSelectedProjectType] = React.useState<ProjectTypeEnum>(ProjectTypeEnum.Internal);
    const [projectTypes] =
        React.useState<ProjectTypeEnum[]>([ProjectTypeEnum.Internal, ProjectTypeEnum.Commercial, ProjectTypeEnum.Thesis]);

    React.useEffect(() => {
        usersService.getUsers().then(userModels => {
            let users = userModels.map(value => {
                return {
                    managerId: value.id,
                    managerDisplay: `${value.name} ${value.surname} - ${value.email}`,
                    managerName: value.name,
                    managerSurname: value.surname,
                    managerEmail: value.email,
                } as IProjectsManagerDialogItem
            });
            setManagers(users);
        });
    }, [])

    React.useEffect(() => {
        clientsService.getClients().then(clientModels => {
            let clients = clientModels.map(value => {
                return {
                    clientId: value.id,
                    clientName: value.name,
                } as IProjectsClientDialogItem
            });
            setClients(clients);
        });
    }, [])

    const createProjectCallback = () => {
        let action = () => {
            projectService.createProject(code, name, selectedManager.managerEmail, selectedClient.clientId, isOrganizational, selectedProjectType).then(() => {
                dispatch(notification.actions.openDialog({
                    title: Strings.Notification,
                    text: Strings.ProjectCreatedWithSuccess
                }))
            })
        }
        dispatch(question.actions.openDialog({
            text: Strings.AreYouSure,
            actionOnYes: action,
            title: Strings.Notification
        }))
    }


    React.useEffect(() => {
        if (!isEmpty(code) && !isEmpty(name) && selectedManager !== undefined
            && selectedClient !== undefined && selectedProjectType !== undefined)
            setCanCreate(true);
        else
            setCanCreate(false);
    }, [code, name, selectedManager, selectedClient, selectedProjectType])


    const updateCode = (event: ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
    }

    const updateName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const onCheckedIsOrganizacional = (isOrganizational) => {
        isOrganizational = !isOrganizational;
        setIsOrganizational(isOrganizational);
    }

    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <Typography variant='h6'>{Strings.AddProject}</Typography>
                </Box>
                <Grid container direction='row'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={code}
                                onChange={updateCode}
                                label={Strings.ProjectCode}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField
                                value={name}
                                onChange={updateName}
                                label={Strings.Name}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <FormControl fullWidth>
                                <InputLabel id="selectProjectTypeLabel">{Strings.ProjectType}</InputLabel>
                                <Select value={selectedProjectType} labelId='selectProjectTypeLabel'
                                        id='selectProjectType'
                                        onChange={event => {
                                            setSelectedProjectType(event.target.value as ProjectTypeEnum)
                                        }}>
                                    {projectTypes.map(value => {
                                        return <MenuItem key={GetProjectTypeString(value)}
                                                         value={value}>{GetProjectTypeString(value)}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container direction='row'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Autocomplete
                                value={selectedManager}
                                onChange={(event: any, newValue: IProjectsManagerDialogItem | null) => {
                                    setSelectedManager(newValue);
                                }}
                                id="combo-box-demo"
                                options={managers}
                                getOptionLabel={(option) => option.managerDisplay}
                                renderInput={(params) =>
                                    <TextField {...params} label={Strings.ManagerEmail} variant="outlined"/>}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>

                            <Autocomplete
                                value={selectedClient}
                                onChange={(event: any, newValue: IProjectsClientDialogItem | null) => {
                                    setSelectedClient(newValue);
                                }}
                                id="combo-box-demo"

                                options={clients}
                                getOptionLabel={(option) => option.clientName}
                                renderInput={(params) =>
                                    <TextField {...params} label={Strings.Client} variant="outlined"/>}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <FormControlLabel
                                checked={isOrganizational}
                                control={<Checkbox onClick={() => onCheckedIsOrganizacional(isOrganizational)}
                                                   inputProps={{'aria-label': 'primary checkbox'}}/>}
                                label={Strings.IsOrganizational}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Paper>
        </Box>

        <Grid item xs='auto'>
            <Box m={margin} p={padding}>
                <Button variant='contained' onClick={createProjectCallback}
                        disabled={!canCreate}>{Strings.Create}</Button>
            </Box>
        </Grid>

    </>
}