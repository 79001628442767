import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IProjectModel} from "./IProjectModel";
import {List} from "linq-typescript";
import {ProjectStatusEnum} from "./ProjectStatusEnum";
import {ITrackingHoursProject} from "./ITrackingHoursProject";
import {ProjectTypeEnum} from "./ProjectTypeEnum";
import {ICreateProjects} from "./ICreateProjects";
import {IMemberInput} from "./IMemberInput";

class ProjectsService extends BaseService {
    async getActiveProjectsByUser(userId: string): Promise<IProjectModel[]> {
        let host = apiUrl;
        let resource = `projects/active_projects?userId=${userId}`;
        let url = `${host}${resource}`;
        let token = await this.getToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        let models = new List(await response.json() as IProjectModel[]);
        return models.where(element => element.status === ProjectStatusEnum.Active).toArray();
    }


    async getAllProjects() {
        let host = apiUrl;
        let resource = `projects/all`;
        let url = `${host}${resource}`;

        let token = await this.getToken();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as IProjectModel[])
    }

    async getProjectById(id:string) {
        let host = apiUrl;
        let resource = `projects?projectIds=${id}`;
        let url = `${host}${resource}`;

        let token = await this.getToken();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as IProjectModel[])[0]
    }

    async addMember(projectId: string, email: string) {
        let token = await this.getToken();

        let host = apiUrl;
        let resource = `projects/members`;
        let url = `${host}${resource}`;

        let body = {
            memberEmail: email,
            hasHoursAssigned: false,
            hoursAssigned: 0,
            projectId: projectId
        } as IMemberInput

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        await fetch(`${url}`, requestOptions);
    }

    async getProjectByIds(projectIds: string[]): Promise<ITrackingHoursProject[]> {
        if (projectIds.length === 0)
            return new Promise<ITrackingHoursProject[]>((resolve, reject) => resolve([]));

        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'projects/ids?';
        let projectIdsList = new List<string>();
        projectIdsList.pushRange(projectIds);

        let projectIdsQuery = '';
        projectIds.forEach(projectId => projectIdsQuery = projectIdsQuery + `projectIds=${projectId}&`);
        projectIdsQuery = projectIdsQuery.slice(0, -1);
        let url = `${host}${resource}${projectIdsQuery}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as ITrackingHoursProject[])
    }

    async getProjectsByClient(clientId: string) {
        let token = await this.getToken();
        let url = `${apiUrl}projects?clientIds=${clientId}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as IProjectModel[])
    }

    async createProject(code: string, name: string, managerEmail: string, clientId: string, isOrganizational: boolean, projectType: ProjectTypeEnum) {
        let body = {
            projectCode: code,
            projectName: name,
            managerEmail: managerEmail,
            clientId: clientId,
            isOrganizationalProject: isOrganizational,
            projectType: projectType,
            projectStatus: ProjectStatusEnum.Active
        } as ICreateProjects;

        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let fetchResponse = await fetch(`${apiUrl}projects`, requestOptions);
        return await fetchResponse.json() as IProjectModel;
    }
}

export const projectService = new ProjectsService();



