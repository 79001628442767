import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {IExternalUser} from "../../api/ExternalUsersService";
import {Strings} from "../../Resources/Strings";
import {externalUser, loadExternalUsersThunk} from "../../redux/externalUser";
import MaterialTable, {Column} from "material-table";
import {AddExternalUserDialogComponent} from "../Dialogs/AddExternalUser/AddExternalUserDialogComponent";
import {progress} from "../../redux/progress";

function getTemplateColumns(): Column<IExternalUser>[] {
    return [{title: Strings.Email, field: 'email', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'}];
}

export const ExternalUsersComponent = () => {

    const dispatch = useDispatch();
    const {isOpen} = useSelector((state: RootState) => state.progress);
    const {externalUsers} = useSelector((state: RootState) => state.externalUser)
    const dataTable = externalUsers.map(value => ({...value}));
    const [externalUsersColumns] = React.useState(getTemplateColumns());

    useEffect(() => {
        async function loadData() {
            dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadExternalUsersThunk)
            dispatch(progress.actions.closeProgressBar())
        }
        // noinspection JSIgnoredPromiseFromCall
        loadData()
    }, [])
    const openAddExternalUser = () => {
        dispatch(externalUser.actions.openAddExternalUser())
    }
    return <>
        {!isOpen && <>
            <MaterialTable
                title={Strings.ExternalUsers}
                columns={externalUsersColumns}
                data={dataTable}
                options={{
                    paging: false,
                    actionsColumnIndex: -1
                }}

                actions={[{
                    tooltip: Strings.Add,
                    icon: 'add',
                    isFreeAction: true,
                    onClick: openAddExternalUser
                }]}
            />
            <AddExternalUserDialogComponent/>
        </>}
    </>
}