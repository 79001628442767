import React, {FC} from 'react'
import {Box, Button, CircularProgress, Grid, PropTypes} from "@material-ui/core";
import {margin} from "../../../styles/styleConstants";
import Center from 'react-center'

export interface LoadingButtonProps {
    onClick?: () => {},
    inProgress: boolean,
    text: string
    disabled?: boolean
    color?: PropTypes.Color
    progressBarColor?: 'primary' | 'secondary'
    variant?: 'text' | 'outlined' | 'contained'
}

export const LoadingButton: FC<LoadingButtonProps> = (props) => {
    return <>
        <Button onClick={props.onClick} variant={props.variant} color={props.color}
                disabled={props.disabled}>
            <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                {props.inProgress && <>
                    <Grid item xs='auto'>
                        <Center>
                            <CircularProgress size={20} color={props.progressBarColor}/>
                        </Center>
                    </Grid>
                    <Grid item xs>
                        <Box m={margin}/>
                    </Grid>
                </>}

                <Grid item xs='auto'>
                    {props.text}
                </Grid>
            </Grid>
        </Button>
    </>
}