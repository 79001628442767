import {TimeTrackingTypeEnum} from "../api/TimeTrackingTypeEnum";
import {IDateDays} from "./IDateDays";
import {Column} from "material-table";
import ITrackingHoursRowData from "./ITrackingHoursRowData";
import {IList, List} from "linq-typescript";
import {ITrackingHoursModel} from "../api/ITrackingHoursModel";
import {ITrackingHoursProject} from "../api/ITrackingHoursProject";
import moment from "moment";
import {Credentials} from "../api/Credentials";
import {trackingHoursService} from "../api/TrackingHoursService";
import {IUpdateTrackingHours} from "../api/IUpdateTrackingHours";
import {IRemoveTrackingHours} from "../api/IRemoveTrackingHours";
import {ICreateTrackingHours} from "../api/ICreateTrackingHours";
import {CredentialTypeEnum} from "../api/CredentialTypeEnum";
import {ProjectTypeEnum} from "../api/ProjectTypeEnum";
import {ProjectStatusEnum} from "../api/ProjectStatusEnum";
import {usersService} from "../api/UsersService";
import {CompromiseStatusEnum} from "../api/CompromiseStatusEnum";
import {Strings} from "../Resources/Strings";
import {ITrackingHoursCellData} from "./ITrackingHoursCellData";
import {IUserModel} from "../api/IUserModel";

export const redColor = "rgb(200,0,0)";
export const blueColor = "rgb(0,0,200)";
export const greenColor = "rgb(0,150,0)";
export const grayColor = "rgb(100,100,100)";

export function getCodeProjectName(projectCode: string, projectName: string): string {
    return `${projectCode} ${projectName}`;
}


export function getWeekNumber(date: Date) {
    // Copy date so don't modify original
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 2));
    // Set to the nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() - (date.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    // Calculate full weeks to the nearest Thursday
    const weekNumber = Math.ceil((((date.valueOf() - yearStart.valueOf()) / 86400000)) / 7);
    // Return array of year and week number
    return [date.getUTCFullYear(), weekNumber];
}

export function getDate(week: number, year: number) {
    let simple = new Date(year, 0, 1 + (week - 1) * 7);
    let dow = simple.getDay();
    let ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() - 1);
    else
        ISOweekStart.setDate(simple.getDate() + 6 - simple.getDay());
    return ISOweekStart;
}

export function getTimeTrackingTypeDisplay(trackingType: TimeTrackingTypeEnum): string {
    let timeTrackingTypeDisplay = '';
    if (trackingType === TimeTrackingTypeEnum.Extraordinary)
        timeTrackingTypeDisplay = 'Extraordinarias';
    else if (trackingType === TimeTrackingTypeEnum.Ordinary)
        timeTrackingTypeDisplay = 'Ordinarias';
    else throw new Error('missing enum')
    return timeTrackingTypeDisplay;
}

export function getDateFromSaturdayToFriday(startDate: Date): IDateDays {
    let saturdayDate = new Date(startDate);
    saturdayDate.setUTCDate(startDate.getUTCDate());

    let sundayDate = new Date(startDate);
    sundayDate.setUTCDate(startDate.getUTCDate() + 1);

    let mondayDate = new Date(startDate);
    mondayDate.setUTCDate(startDate.getUTCDate() + 2);

    let tuesdayDate = new Date(startDate);
    tuesdayDate.setUTCDate(startDate.getUTCDate() + 3);

    let wednesdayDate = new Date(startDate);
    wednesdayDate.setUTCDate(startDate.getUTCDate() + 4);

    let thursdayDate = new Date(startDate);
    thursdayDate.setUTCDate(startDate.getUTCDate() + 5);

    let fridayDate = new Date(startDate);
    fridayDate.setUTCDate(startDate.getUTCDate() + 6);
    return {
        fridayDate: fridayDate,
        saturdayDate: saturdayDate,
        sundayDate: sundayDate,
        thursdayDate: thursdayDate,
        tuesdayDate: tuesdayDate,
        mondayDate: mondayDate,
        wednesdayDate: wednesdayDate
    };
}

export function getTemplateColumns(): Column<ITrackingHoursRowData>[] {
    return [{title: 'Nombre', field: 'projectCodeName', type: 'string', editable: "never", width: 'auto'},
        {title: 'Sábado', field: 'saturday', type: 'numeric', width: 150},
        {title: 'Domingo', field: 'sunday', type: 'numeric', width: 150},
        {title: 'Lunes', field: 'monday', type: 'numeric', width: 150},
        {title: 'Martes', field: 'tuesday', type: 'numeric', width: 150},
        {title: 'Miércoles', field: 'wednesday', type: 'numeric', width: 150},
        {title: 'Jueves', field: 'thursday', type: 'numeric', width: 150},
        {title: 'Viernes', field: 'friday', type: 'numeric', width: 150}];
}

export function getColumns(): Column<ITrackingHoursRowData>[] {
    return [{title: 'Nombre', field: 'projectCodeName', editable: 'never', type: 'string', width: 'auto'},
        {title: 'Tipo', field: 'timeTrackingTypeDisplay', editable: 'never', type: 'string', width: 'auto'},
        {title: 'Sábado', field: 'saturday', type: 'numeric', width: 'auto'},
        {title: 'Domingo', field: 'sunday', type: 'numeric', width: 'auto'},
        {title: 'Lunes', field: 'monday', type: 'numeric', width: 'auto'},
        {title: 'Martes', field: 'tuesday', type: 'numeric', width: 'auto'},
        {title: 'Miércoles', field: 'wednesday', type: 'numeric', width: 'auto'},
        {title: 'Jueves', field: 'thursday', type: 'numeric', width: 'auto'},
        {title: 'Viernes', field: 'friday', type: 'numeric', width: 'auto'}];
}

export function datesAreEqual(dateA: Date, dateB: Date): boolean {
    return dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
        dateA.getUTCMonth() === dateB.getUTCMonth() &&
        dateA.getUTCDate() === dateB.getUTCDate();
}


export function getTrackingHoursRow(trackingHoursModels: IList<ITrackingHoursModel>,
                                    trackingHoursProjectModels: ITrackingHoursProject[], userId: string, startDate: Date) {
    // Bundle between ordinary and extraordinary
    let table = [] as ITrackingHoursRowData[];
    trackingHoursModels.groupBy(element => element.type).forEach(trackingHoursByTrackingType => {
        let trackingType = trackingHoursByTrackingType.key;
        trackingHoursByTrackingType.value.groupBy(element => element.project.id).forEach(trackingHoursByProject => {
            let project = trackingHoursProjectModels
                .find(value => value.id === trackingHoursByProject.key) as ITrackingHoursProject;

            let dateDays = getDateFromSaturdayToFriday(startDate);
            let trackingHoursRowData: ITrackingHoursRowData = {
                projectId: project.id,
                userId: userId,
                projectCode: project.code,
                projectName: project.name,
                timeTrackingTypeDisplay: getTimeTrackingTypeDisplay(trackingType),
                projectCodeName: getCodeProjectName(project.code, project.name),
                timeTrackingType: trackingType,
                saturdayDate: dateDays.saturdayDate,
                sundayDate: dateDays.sundayDate,
                mondayDate: dateDays.mondayDate,
                tuesdayDate: dateDays.tuesdayDate,
                wednesdayDate: dateDays.wednesdayDate,
                thursdayDate: dateDays.thursdayDate,
                fridayDate: dateDays.fridayDate
            };

            trackingHoursByProject.value.forEach(trackingHoursModel => {
                let hours = trackingHoursModel.hours;
                let date = moment(trackingHoursModel.insertionDate).toDate();
                let day = date.getUTCDay();
                let id = trackingHoursModel.id;
                if (day === 0) {
                    trackingHoursRowData.sundayId = id;
                    trackingHoursRowData.sunday = hours;
                    trackingHoursRowData.sundayDate = date;
                } else if (day === 1) {
                    trackingHoursRowData.mondayId = id;
                    trackingHoursRowData.monday = hours;
                    trackingHoursRowData.mondayDate = date;
                } else if (day === 2) {
                    trackingHoursRowData.tuesdayId = id;
                    trackingHoursRowData.tuesday = hours;
                    trackingHoursRowData.tuesdayDate = date;
                } else if (day === 3) {
                    trackingHoursRowData.wednesdayId = id;
                    trackingHoursRowData.wednesday = hours;
                    trackingHoursRowData.wednesdayDate = date;
                } else if (day === 4) {
                    trackingHoursRowData.thursdayId = id;
                    trackingHoursRowData.thursday = hours;
                    trackingHoursRowData.thursdayDate = date;
                } else if (day === 5) {
                    trackingHoursRowData.fridayId = id;
                    trackingHoursRowData.friday = hours;
                    trackingHoursRowData.fridayDate = date;
                } else if (day === 6) {
                    trackingHoursRowData.saturdayId = id;
                    trackingHoursRowData.saturday = hours;
                    trackingHoursRowData.saturdayDate = date;
                }
            });
            table.push(trackingHoursRowData);
        });
    })
    return table;
}

export function getTrackingModel(model: ITrackingHoursRowData): ITrackingHoursCellData[] {
    let trackingHoursCellData = [] as ITrackingHoursCellData[];
    if (model.monday !== undefined)
        trackingHoursCellData.push({
            id: model.mondayId,
            hours: model.monday,
            date: model.mondayDate,
            projectId: model.projectId
        } as ITrackingHoursCellData);
    if (model.tuesday !== undefined)
        trackingHoursCellData.push({
            id: model.tuesdayId,
            hours: model.tuesday,
            date: model.tuesdayDate,
            projectId: model.projectId
        });
    if (model.wednesday !== undefined)
        trackingHoursCellData.push({
            id: model.wednesdayId,
            hours: model.wednesday,
            date: model.wednesdayDate,
            projectId: model.projectId
        });
    if (model.thursday !== undefined)
        trackingHoursCellData.push({
            id: model.thursdayId,
            hours: model.thursday,
            date: model.thursdayDate,
            projectId: model.projectId
        });
    if (model.friday !== undefined)
        trackingHoursCellData.push({
            id: model.fridayId,
            hours: model.friday,
            date: model.fridayDate,
            projectId: model.projectId
        });
    if (model.saturday !== undefined)
        trackingHoursCellData.push({
            id: model.saturdayId,
            hours: model.saturday,
            date: model.saturdayDate,
            projectId: model.projectId
        });
    if (model.sunday !== undefined)
        trackingHoursCellData.push({
            id: model.sundayId,
            hours: model.sunday,
            date: model.sundayDate,
            projectId: model.projectId
        });
    return trackingHoursCellData;
}

export function updateTrackingHours(changes: Record<number, { oldData: ITrackingHoursRowData; newData: ITrackingHoursRowData }>, timeTrackingHours
    : TimeTrackingTypeEnum, credentials: Credentials, hoursTable: ITrackingHoursRowData[]
) {

    let newDataTable = [...hoursTable];

    let updateItems = [] as IUpdateTrackingHours[];
    let removeItems = [] as IRemoveTrackingHours[];
    let createItems = [] as ICreateTrackingHours[];
    let recordNumbers = Object.keys(changes);

    for (const recordNumber of recordNumbers) {
        let item = changes[+recordNumber];
        let projectId = item.newData.projectId;
        let userId = item.newData.userId;

        let newDataRow = item.newData;
        let oldDataRow = item.oldData;

        // Update item
        newDataTable[+recordNumber] = newDataRow;

        if (newDataRow.saturday === 0 || newDataRow.saturday === undefined ||
            newDataRow.saturday === null || isNaN(newDataRow.saturday)) {
            newDataTable[+recordNumber].saturday = undefined;
            newDataRow.saturday = undefined;
        }

        if (newDataRow.sunday === 0 || newDataRow.sunday === undefined ||
            newDataRow.sunday === null || isNaN(newDataRow.sunday)) {
            newDataTable[+recordNumber].sunday = undefined;
            newDataRow.sunday = undefined;
        }

        if (newDataRow.monday === 0 || newDataRow.monday === undefined ||
            newDataRow.monday === null || isNaN(newDataRow.monday)) {
            newDataTable[+recordNumber].monday = undefined;
            newDataRow.monday = undefined;
        }

        if (newDataRow.tuesday === 0 || newDataRow.tuesday === undefined ||
            newDataRow.tuesday === null || isNaN(newDataRow.tuesday)) {
            newDataTable[+recordNumber].tuesday = undefined;
            newDataRow.tuesday = undefined;
        }

        if (newDataRow.wednesday === 0 || newDataRow.wednesday === undefined ||
            newDataRow.wednesday === null || isNaN(newDataRow.wednesday)) {
            newDataTable[+recordNumber].wednesday = undefined;
            newDataRow.wednesday = undefined;
        }

        if (newDataRow.thursday === 0 || newDataRow.thursday === undefined ||
            newDataRow.thursday === null || isNaN(newDataRow.thursday)) {
            newDataTable[+recordNumber].thursday = undefined;
            newDataRow.thursday = undefined;
        }

        if (newDataRow.friday === 0 || newDataRow.friday === undefined ||
            newDataRow.friday === null || isNaN(newDataRow.friday)) {
            newDataTable[+recordNumber].friday = undefined;
            newDataRow.friday = undefined;
        }


        // --- Check if was deleted, updated or created ---

        // Friday
        if (newDataRow.friday !== oldDataRow.friday) {
            // Create a new item
            if (oldDataRow.fridayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.friday as number, newDataRow.fridayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.friday === undefined || newDataRow.friday <= 0) {
                removeItems.push({id: newDataRow.fridayId} as IRemoveTrackingHours);
                newDataRow.friday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.fridayId, hours: newDataRow.friday as number})
            }
        }

        // Thursday
        if (newDataRow.thursday !== oldDataRow.thursday) {
            // Create a new item
            if (oldDataRow.thursdayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.thursday as number, newDataRow.thursdayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.thursday === undefined || newDataRow.thursday <= 0) {
                removeItems.push({id: newDataRow.thursdayId} as IRemoveTrackingHours);
                newDataRow.thursday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.thursdayId, hours: newDataRow.thursday as number})
            }
        }

        // Wednesday
        if (newDataRow.wednesday !== oldDataRow.wednesday) {
            // Create a new item
            if (oldDataRow.wednesdayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.wednesday as number,
                    newDataRow.wednesdayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.wednesday === undefined || newDataRow.wednesday <= 0) {
                removeItems.push({id: newDataRow.wednesdayId} as IRemoveTrackingHours);
                newDataRow.wednesday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.wednesdayId, hours: newDataRow.wednesday as number})
            }
        }

        // Tuesday
        if (newDataRow.tuesday !== oldDataRow.tuesday) {
            // Create a new item
            if (oldDataRow.tuesdayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.tuesday as number,
                    newDataRow.tuesdayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.tuesday === undefined || newDataRow.tuesday <= 0) {
                removeItems.push({id: newDataRow.tuesdayId} as IRemoveTrackingHours);
                newDataRow.tuesday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.tuesdayId, hours: newDataRow.tuesday as number})
            }
        }

        // Monday
        if (newDataRow.monday !== oldDataRow.monday) {
            // Create a new item
            if (oldDataRow.mondayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.monday as number,
                    newDataRow.mondayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.monday === undefined || newDataRow.monday <= 0) {
                removeItems.push({id: newDataRow.mondayId} as IRemoveTrackingHours);
                newDataRow.monday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.mondayId, hours: newDataRow.monday as number})
            }
        }

        // Sunday
        if (newDataRow.sunday !== oldDataRow.sunday) {
            // Create a new item
            if (oldDataRow.sundayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.sunday as number,
                    newDataRow.sundayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.sunday === undefined || newDataRow.sunday <= 0) {
                removeItems.push({id: newDataRow.sundayId} as IRemoveTrackingHours);
                newDataRow.sunday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.sundayId, hours: newDataRow.sunday as number})
            }
        }

        // Saturday
        if (newDataRow.saturday !== oldDataRow.saturday) {
            // Create a new item
            if (oldDataRow.saturdayId === undefined) {
                createItems.push(getCreateItem(projectId, userId, newDataRow.saturday as number,
                    newDataRow.saturdayDate as Date));
            }
            // Delete an existing item
            else if (newDataRow.saturday === undefined || newDataRow.saturday <= 0) {
                removeItems.push({id: newDataRow.saturdayId} as IRemoveTrackingHours);
                newDataRow.saturday = undefined;
            }
            // Updating
            else {
                updateItems.push({id: newDataRow.saturdayId, hours: newDataRow.saturday as number})
            }
        }
    }
    // Set the type of hours
    createItems.forEach(value => value.type = timeTrackingHours);
    // Send the last update
    return trackingHoursService.updateTrackingHours(updateItems)
        .then(() => trackingHoursService.removeTrackingHours(removeItems))
        .then(() => trackingHoursService.createTrackingHours(createItems))
        .then(() => {
            return newDataTable;
        })
        .catch(reason => {
            console.error(reason);
            alert(reason);
            return newDataTable;
        });
}

export function deleteRow(rowData: ITrackingHoursRowData, credentials: Credentials) {
    return usersService.getUserByEmail(credentials.email).then(() => {
        let itemsToDelete = [] as IRemoveTrackingHours[];
        if (rowData.sundayId !== undefined) {
            itemsToDelete.push({id: rowData.sundayId} as IRemoveTrackingHours);
        }
        if (rowData.saturdayId !== undefined) {
            itemsToDelete.push({id: rowData.saturdayId} as IRemoveTrackingHours);
        }
        if (rowData.fridayId !== undefined) {
            itemsToDelete.push({id: rowData.fridayId} as IRemoveTrackingHours);
        }
        if (rowData.thursdayId !== undefined) {
            itemsToDelete.push({id: rowData.thursdayId} as IRemoveTrackingHours);
        }
        if (rowData.wednesdayId !== undefined) {
            itemsToDelete.push({id: rowData.wednesdayId} as IRemoveTrackingHours);
        }
        if (rowData.tuesdayId !== undefined) {
            itemsToDelete.push({id: rowData.tuesdayId} as IRemoveTrackingHours);
        }
        if (rowData.mondayId !== undefined) {
            itemsToDelete.push({id: rowData.mondayId} as IRemoveTrackingHours);
        }
        return trackingHoursService.removeTrackingHours(itemsToDelete);
    });
}

function getCreateItem(projectId: string, userId: string,
                       hours: number, date: Date) {
    let itemDate = moment(date).toDate();
    return {
        projectId: projectId,
        hours: hours as number,
        day: itemDate.getUTCDate() as number,
        month: itemDate.getUTCMonth() as number + 1,
        year: itemDate.getUTCFullYear() as number,
        type: TimeTrackingTypeEnum.Ordinary,
        userId: userId
    } as ICreateTrackingHours;
}

export function updateHeaders(trackingHoursRowData: ITrackingHoursRowData[], columns: Column<ITrackingHoursRowData>[]) {
    let lessHoursColor = "#d0b405";
    let equalHoursColor = "#25b100";
    let overHoursColor = "#fd0b0b";

    let columnsList = new List(columns);
    let trackingHoursList = new List(trackingHoursRowData);

    // Saturday
    let saturdayHours = trackingHoursList.where(element => element.saturday !== undefined
        && !isNaN(element.saturday))
        .sum(element => element.saturday);
    let saturdayColumn = columnsList.first(element => element.field === 'saturday');
    if (saturdayHours === 0)
        saturdayColumn.title = 'Sábado';
    else
        saturdayColumn.title = `Sábado (${saturdayHours})`;

    let maximumHoursSaturday = getHoursPerDay(6);
    let saturdayColor: string;
    if (saturdayHours < maximumHoursSaturday)
        saturdayColor = lessHoursColor;
    else if (saturdayHours > maximumHoursSaturday)
        saturdayColor = overHoursColor;
    else saturdayColor = equalHoursColor;
    saturdayColumn.headerStyle = {color: saturdayColor};

    // Sunday
    let sundayHours = trackingHoursList.where(element => element.sunday !== undefined
        && !isNaN(element.sunday))
        .sum(element => element.sunday);
    let sundayColumn = columnsList.first(element => element.field === 'sunday');
    if (sundayHours === 0)
        sundayColumn.title = 'Domingo';
    else
        sundayColumn.title = `Domingo (${sundayHours})`;

    let maximumHoursSunday = getHoursPerDay(0);
    let sundayColor: string;
    if (sundayHours < maximumHoursSunday)
        sundayColor = lessHoursColor;
    else if (sundayHours > maximumHoursSunday)
        sundayColor = overHoursColor;
    else sundayColor = equalHoursColor;
    sundayColumn.headerStyle = {color: sundayColor};

    // Monday
    let mondayHours = trackingHoursList.where(element => element.monday !== undefined
        && !isNaN(element.monday))
        .sum(element => element.monday);
    let mondayColumn = columnsList.first(element => element.field === 'monday');
    if (mondayHours === 0)
        mondayColumn.title = 'Lunes';
    else
        mondayColumn.title = `Lunes (${mondayHours})`;

    let maximumHoursMonday = getHoursPerDay(1);
    let mondayColor: string;
    if (mondayHours < maximumHoursMonday)
        mondayColor = lessHoursColor;
    else if (mondayHours > maximumHoursMonday)
        mondayColor = overHoursColor;
    else mondayColor = equalHoursColor;
    mondayColumn.headerStyle = {color: mondayColor};

    // Tuesday
    let tuesdayHours = trackingHoursList.where(element => element.tuesday !== undefined
        && !isNaN(element.tuesday))
        .sum(element => element.tuesday);
    let tuesdayColumn = columnsList.first(element => element.field === 'tuesday');
    if (tuesdayHours === 0)
        tuesdayColumn.title = 'Martes';
    else
        tuesdayColumn.title = `Martes (${tuesdayHours})`;

    let maximumHoursTuesday = getHoursPerDay(2);
    let tuesdayColor: string;
    if (tuesdayHours < maximumHoursTuesday)
        tuesdayColor = lessHoursColor;
    else if (tuesdayHours > maximumHoursTuesday)
        tuesdayColor = overHoursColor;
    else tuesdayColor = equalHoursColor;
    tuesdayColumn.headerStyle = {color: tuesdayColor};

    // Wednesday
    let wednesdayHours = trackingHoursList.where(element => element.wednesday !== undefined
        && !isNaN(element.wednesday))
        .sum(element => element.wednesday);
    let wednesdayColumn = columnsList.first(element => element.field === 'wednesday');
    if (wednesdayHours === 0)
        wednesdayColumn.title = 'Miércoles';
    else
        wednesdayColumn.title = `Miércoles (${wednesdayHours})`;

    let maximumHoursWednesday = getHoursPerDay(3);
    let wednesdayColor: string;
    if (wednesdayHours < maximumHoursWednesday)
        wednesdayColor = lessHoursColor;
    else if (wednesdayHours > maximumHoursWednesday)
        wednesdayColor = overHoursColor;
    else wednesdayColor = equalHoursColor;
    wednesdayColumn.headerStyle = {color: wednesdayColor};


    // Thursday
    let thursdayHours = trackingHoursList.where(element => element.thursday !== undefined
        && !isNaN(element.thursday))
        .sum(element => element.thursday);
    let thursdayColumn = columnsList.first(element => element.field === 'thursday');
    if (thursdayHours === 0)
        thursdayColumn.title = 'Jueves';
    else
        thursdayColumn.title = `Jueves (${thursdayHours})`;

    let maximumHoursThursday = getHoursPerDay(4);
    let thursdayColor: string;
    if (thursdayHours < maximumHoursThursday)
        thursdayColor = lessHoursColor;
    else if (thursdayHours > maximumHoursThursday)
        thursdayColor = overHoursColor;
    else thursdayColor = equalHoursColor;
    thursdayColumn.headerStyle = {color: thursdayColor};

    // Friday
    let fridayHours = trackingHoursList.where(element => element.friday !== undefined
        && !isNaN(element.friday))
        .sum(element => element.friday);
    let fridayColumn = columnsList.first(element => element.field === 'friday');
    if (fridayHours === 0)
        fridayColumn.title = 'Viernes';
    else
        fridayColumn.title = `Viernes (${fridayHours})`;

    let maximumHoursFriday = getHoursPerDay(5);
    let fridayColor: string;
    if (fridayHours < maximumHoursFriday)
        fridayColor = lessHoursColor;
    else if (fridayHours > maximumHoursFriday)
        fridayColor = overHoursColor;
    else fridayColor = equalHoursColor;
    fridayColumn.headerStyle = {color: fridayColor};

    return columnsList.toArray();
}

export function getHoursPerDay(numberOfDay: number): number {
    switch (numberOfDay) {
        case 0: // Sunday
            return 0;
        case 1:
            return 9;
        case 2:
            return 9;
        case 3:
            return 9;
        case 4:
            return 9;
        case 5:
            return 5;
        case 6:
            return 0;
        default:
            throw new Error(`El valor ${numberOfDay} no es válido`);
    }
}

export function getHoursPerDayFromDate(date: Date): number {
    let day = date.getUTCDay();
    return getHoursPerDay(day);
}

export function isEmpty(text: string): boolean {
    return (!text || text.length === 0);
}

export function GetCredentialTypeString(credentialType: CredentialTypeEnum) {
    let display = '';

    switch (credentialType) {
        case CredentialTypeEnum.Email:
            display = 'Email';
            break;
        case CredentialTypeEnum.Office365:
            display = 'Office 365';
            break;
        case CredentialTypeEnum.Office365B:
            display = 'Office 365 Basico';
            break; 
        case CredentialTypeEnum.OneDrive:
                display = 'OneDrive';
                break;    
        case CredentialTypeEnum.PowerBI:
            display = 'Power BI';
            break;  
        case CredentialTypeEnum.Visio:
            display = 'Visio';
            break;   
        case CredentialTypeEnum.ProjectPlan:
            display = 'Project Plan';
            break;    
            
    }

    return display;
}

export function GetCredentialType(credentialType: string) {
    let display = CredentialTypeEnum.Email;

    switch (credentialType) {
        case 'Email':
            display = CredentialTypeEnum.Email;
            break;
        case 'Office 365':
            display = CredentialTypeEnum.Office365;
            break;
        case 'Office 365 Basico':
            display = CredentialTypeEnum.Office365B;
            break;

        case 'One Drive':
            display = CredentialTypeEnum.OneDrive;
            break;

        case 'Power BI':
            display = CredentialTypeEnum.PowerBI;
            break;

        case 'Visio':
            display = CredentialTypeEnum.Visio;
            break;

        case 'Project Plan':
            display = CredentialTypeEnum.ProjectPlan;
            break;    
    }

    return display;
}

export function GetProjectTypeString(projectType: ProjectTypeEnum) {
    let display = '';

    switch (projectType) {
        case ProjectTypeEnum.Internal:
            display = 'Interno';
            break;
        case ProjectTypeEnum.Commercial:
            display = 'Comercial';
            break;
        case ProjectTypeEnum.Thesis:
            display = 'Tesis';
            break;
    }
    return display;
}

export function GetProjectStatusString(projectStatus: ProjectStatusEnum) {
    let display = '';

    switch (projectStatus) {
        case ProjectStatusEnum.Active:
            display = 'Activo';
            break;
        case ProjectStatusEnum.Finished:
            display = 'Finalizado';
            break;
        case ProjectStatusEnum.NotInitiated:
            display = 'No iniciado';
            break;
        case ProjectStatusEnum.Unknown:
            display = 'Desconocido';
            break;
    }
    return display;
}

export function GetCompromiseStatusString(compromiseStatus: CompromiseStatusEnum) {
    let display = '';
    switch (compromiseStatus) {
        case CompromiseStatusEnum.NotStarted:
            display = Strings.NotStarted
            break;
        case CompromiseStatusEnum.InProgress:
            display = Strings.InProgress
            break;
        case CompromiseStatusEnum.Completed:
            display = Strings.Completed
            break;
        case CompromiseStatusEnum.Deleted:
            display = Strings.Deleted
            break;
    }
    return display;
}

export function GetCompromiseStatus(compromiseStatusString: string) {
    let status = CompromiseStatusEnum.NotStarted;
    switch (compromiseStatusString) {
        case Strings.NotStarted:
            status = CompromiseStatusEnum.NotStarted
            break;
        case Strings.InProgress:
            status = CompromiseStatusEnum.InProgress
            break;
        case Strings.Completed:
            status = CompromiseStatusEnum.Completed
            break;
        case Strings.Deleted:
            status = CompromiseStatusEnum.Deleted
            break;
        default:
            throw new Error('Display is invalid')
    }
    return status;
}

export function PureBase64(base64: string): string {
    return base64.split(',')[1];
}

export function getQueryDate(date: Date) {
    return date.toISOString().split('T')[0];
}

export function getDateString(date: Date) {
    return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
}

export function getDateFromString(dateString: string) {
    return moment(dateString).toDate();
}

export function firstCharToUpper(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getNameAndSurname(model: IUserModel) {
    return `${firstCharToUpper(model.name)} ${firstCharToUpper(model.surname)}`
}
