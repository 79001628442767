import {BaseService} from "./BaseService";
import {IUserModel} from "./IUserModel";
import {apiUrl} from "./ApiConfig";
import {CredentialTypeEnum} from "./CredentialTypeEnum";
import {AddUserInput} from "./AddUserInput";

class UsersService extends BaseService {
    async getUsers() {
        let token = await this.getToken();


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(`${apiUrl}users/all`, requestOptions);
        return await response.json() as IUserModel[];

    }

    async getUserByEmail(email: string) {
        let token = await this.getToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(`${apiUrl}users/${email}`, requestOptions);
        return await response.json() as IUserModel
    }

    async setUserActive(userId: string, status: boolean) {
        let token = await this.getToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${apiUrl}users/active?userId=${userId}&status=${status}`, requestOptions);
    }

    async createUser(enterpriseEmail: string, personalEmail: string, credentialType: CredentialTypeEnum, withCopyEmails: string[]) {
        let token = await this.getToken();
        let body = {
            personalEmail: personalEmail,
            enterpriseEmail: enterpriseEmail,
            withCopyEmails: withCopyEmails,
            credentialType: credentialType
        } as AddUserInput;


        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${apiUrl}admin/adduser`, requestOptions);
    }
}

export const usersService = new UsersService();


