import {ISecondaryProjectApproverModel} from "../api/ISecondaryProjectApproverModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {secondaryProjectApproversService} from "../api/SecondaryProjectApproverService";
import {progress} from "./progress";
import {notification} from "./notification";
import {Strings} from "../Resources/Strings";
import {question} from "./question";

export interface SecondaryApproverState{
    secondaryApprovers: ISecondaryProjectApproverModel[],
    selectSecondaryApprover: ISecondaryProjectApproverModel
}

const initialState: SecondaryApproverState = {
    secondaryApprovers: [],
    selectSecondaryApprover: null
}

export const secondaryApprover = createSlice({
    name: 'secondaryApprover',
    initialState: initialState,
    reducers: {
        setSecondaryApprovers: (state: SecondaryApproverState, payload: PayloadAction<ISecondaryProjectApproverModel[]>) => {
            state.secondaryApprovers =  [...payload.payload]
            return state;
        },
        setSelectSecondaryApprover: (state: SecondaryApproverState, payload: PayloadAction<ISecondaryProjectApproverModel>) => {
            state.selectSecondaryApprover = payload.payload;
            return state;
        },
        unsetSelectedSecondaryApprover: (state: SecondaryApproverState) => {
            state.selectSecondaryApprover = null;
            return state;
        },
    }
})

export async function loadSecondaryApproversThunk(dispatch: AppDispatch) {
    let secondaryApprovers = await secondaryProjectApproversService.getSecondaryProjectApprovers();
    await dispatch(secondaryApprover.actions.setSecondaryApprovers(secondaryApprovers));
}

export function loadSecondaryApproverByIdThunk(userId: string){
    return async function loadSecondaryApproverById(dispatch: AppDispatch) {
        let secondaryApprovers = await secondaryProjectApproversService.getSecondaryProjectApproversByApproverId(userId);
        await dispatch(secondaryApprover.actions.setSecondaryApprovers(secondaryApprovers));
    }
}

export function addSecondaryApproverThunk(onBehalfOfEmail: string, toApproveEmail: string) {
    return async function addSecondaryApprover(dispatch: AppDispatch) {
        await dispatch(progress.actions.openProgressBar({}))
        dispatch(question.actions.openDialog({
            title: Strings.Notification,
            text: Strings.AreYouSure,
            actionOnYes:  async () => {
                await secondaryProjectApproversService.toApproveSecondaryUserEmail(onBehalfOfEmail, toApproveEmail).catch(reason => {
                    console.error(reason)
                }).then(() => {
                    dispatch(notification.actions.openDialog({
                        title: Strings.Notification,
                        text: Strings.SecondaryApproverSuccess
                    }))    
                });
                await loadSecondaryApproversThunk(dispatch);
            },     
            onFinally: async () => {
                await dispatch(progress.actions.closeProgressBar());
            },  
        }))      
    }
}

export function removeSecondaryApproverThunk(secondaryApprover: ISecondaryProjectApproverModel) {
    return async function removeSecondaryApprover(dispatch: AppDispatch) {
        await dispatch(progress.actions.openProgressBar({}))
        dispatch(question.actions.openDialog({
            title: Strings.Notification,
            text: Strings.AreYouSure,
            actionOnYes:  async () => {               
                await secondaryProjectApproversService.removeSecondaryUserApprover(secondaryApprover.id).catch(reason => {
                    console.error(reason)
                }).then(() => {
                    dispatch(notification.actions.openDialog({
                        title: Strings.Notification,
                        text: Strings.SecondaryApproverRemoved
                    }))    
                });
                await loadSecondaryApproversThunk(dispatch);
            },     
            onFinally: async () => {
                await dispatch(progress.actions.closeProgressBar());
            },  
        }))      
    }
}