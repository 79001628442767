import {apiUrl} from "./ApiConfig";
import { ISecondaryProjectApproverModel } from "./ISecondaryProjectApproverModel";
import {BaseService} from "./BaseService";

class SecondaryProjectApproversService extends BaseService{
    async getSecondaryProjectApprovers(){
        let token = this.getToken();
        let host = apiUrl;
        let resource = `secondaryProjectApprovers/all`;
      
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json()) as ISecondaryProjectApproverModel[]
    }
   
    async getSecondaryProjectApproversByApproverId(id: string) {
        let host = apiUrl;
        let resource = `SecondaryProjectApprovers/ids?onBehalfOfId=${id}`;
        let url = `${host}${resource}`;
        let token = await this.getToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as  ISecondaryProjectApproverModel[])
    }

    async toApproveSecondaryUserEmail(user: string, toApprove: string) {
        let host = apiUrl;
        let resource = `SecondaryProjectApprovers?onBehalfOfEmail=${user}&toApproveUserEmail=${toApprove}`;
        let url = `${host}${resource}`;
        let token = await this.getToken();

        const requestOptions = {
            method: 'POST',            
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json() as  ISecondaryProjectApproverModel);
    }

    async removeSecondaryUserApprover(id: string) {        
        let host = apiUrl;
        let resource = `SecondaryProjectApprovers?id=${id}`;
        let url = `${host}${resource}`;
        let token = await this.getToken();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions);
    }

}

export const secondaryProjectApproversService = new SecondaryProjectApproversService();

