import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {ICompromiseModel} from "./ICompromiseModel";
import {IUpdateCompromiseModel} from "./IUpdateCompromiseModel";
import {CompromiseStatusEnum} from "./CompromiseStatusEnum";
import {ICreateCompromiseModel} from "./ICreateCompromiseModel";
import {getQueryDate} from "../utils/utils";
import {IUserModel} from "./IUserModel";

class CompromiseService extends BaseService {
    async addActivityRecordCompromise(activityRecordId: string, userOwnerId: string, title: string) {
        const url = `${apiUrl}compromise`;
        let token = await this.getToken()

        let createCompromise = {
            activityRecordId: activityRecordId,
            title: title,
            hasActivityRecord: true,
            projectId: null,
            hasProject: false,
            userOwnerId: userOwnerId
        } as ICreateCompromiseModel

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(createCompromise)
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json()) as ICompromiseModel
    }

    async addGeneralCompromise(userOwnerId: string, title: string) {
        const url = `${apiUrl}compromise`;
        let token = await this.getToken()

        let createCompromise = {
            activityRecordId: null,
            title: title,
            hasActivityRecord: false,
            projectId: null,
            hasProject: false,
            userOwnerId: userOwnerId
        } as ICreateCompromiseModel

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(createCompromise)
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json()) as ICompromiseModel
    }

    async addProjectCompromise(userOwnerId: string, title: string, projectId: string) {
        const url = `${apiUrl}compromise`;
        let token = await this.getToken()

        let createCompromise = {
            activityRecordId: null,
            title: title,
            hasActivityRecord: false,
            projectId: projectId,
            hasProject: true,
            userOwnerId: userOwnerId
        } as ICreateCompromiseModel

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(createCompromise)
        };
        let response = await fetch(`${url}`, requestOptions);
        return (await response.json()) as ICompromiseModel
    }

    async removeCompromise(compromiseId: string) {
        const url = `${apiUrl}compromise?compromiseId=${compromiseId}`;
        let token = await this.getToken()
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions);
    }

    async getCompromises() {
        const url = `${apiUrl}compromise`;
        let token = await this.getToken()
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as ICompromiseModel[];
    }

    async updateCompromise(compromiseId: string, title: string, contentHtml: string,
                           hasDueDate: boolean, compromiseStatus: CompromiseStatusEnum,
                           percentageCompleted: number, dueDate: Date, associatedUsersIds: string[]) {
        const url = `${apiUrl}compromise`;
        let token = await this.getToken();
        let updateCompromise = {
            id: compromiseId,
            title: title,
            contentHtml: contentHtml,
            hasDueDate: hasDueDate,
            percentageCompleted: percentageCompleted,
            associatedUsersIds: associatedUsersIds,
            dueDate: getQueryDate(dueDate),
            compromiseStatus: compromiseStatus
        } as IUpdateCompromiseModel
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updateCompromise)
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as ICompromiseModel;
    }

    async getAvailableAssociatedUsers(id: string) {
        const url = `${apiUrl}compromise/availableAssociatedUsers?id=${id}`;
        let token = await this.getToken()
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserModel[];
    }
}

export const compromiseService = new CompromiseService();