import React, {useEffect} from "react";
import {Button, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {initializeAuthenticationThunk, signInThunk, signOutThunk} from "../../redux/authentication";
import {useHistory} from "react-router";

export const ForbiddenComponent = () => {

    const history = useHistory()

    const {identity} = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!identity)
            dispatch(signInThunk)
    }, []);

    useEffect(() => {
        if (identity) {
            if (identity.credentials.isAuthenticated)
                history.goBack()
        } else {
            dispatch(initializeAuthenticationThunk())
        }
    }, [identity])

    const signInCallback = () => {
        dispatch(signInThunk())
    }

    const signOutCallback = () => {
        dispatch(signOutThunk())
    }


    return (<div>
        <Typography paragraph>{Strings.YouDontHaveAccess}</Typography>
        {identity ?
            <Button onClick={signOutCallback} variant='contained'>{Strings.SignOut}</Button> :
            <Button onClick={signInCallback} variant='contained'>{Strings.SignIn}</Button>
        }

    </div>)
}