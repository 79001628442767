import {ISecurityReflectionModel} from "../api/ISecurityReflectionModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";
import {securityReflectionService} from "../api/SecurityReflection";
import {progress} from "./progress";
import {genericNotificationThunk} from "./notification";


export interface SecurityReflectionState {
    securityReflections: ISecurityReflectionModel[],
}

export const initialState: SecurityReflectionState = {
    securityReflections: []
}

export const securityReflection = createSlice({
    name: 'securityReflection',
    initialState: initialState,
    reducers: {
        setSecurityReflections(state: SecurityReflectionState, payload: PayloadAction<ISecurityReflectionModel[]>) {
            state.securityReflections = payload.payload;
            return state;
        }
    }
})

export async function loadSecurityReflectionThunk(dispatch: AppDispatch) {
    dispatch(progress.actions.openProgressBar({}))
    let securityReflectionModels = await securityReflectionService.getSecurityReflections();
    dispatch(securityReflection.actions.setSecurityReflections(securityReflectionModels));
    dispatch(progress.actions.closeProgressBar())
}

export async function getSecurityReflectionSampleThunk(dispatch: AppDispatch) {
    dispatch(progress.actions.openProgressBar({}))
    await securityReflectionService.getSecurityReflectionSampleFile();
    dispatch(progress.actions.closeProgressBar())
}


export function uploadSecurityReflectionFileThunk(base64File: string) {
    return async function uploadSecurityReflectionFile(dispatch: AppDispatch) {
        dispatch(progress.actions.openProgressBar({}))
        let response = await securityReflectionService.uploadSecurityReflectionFile(base64File)
        let customDispatch = dispatch as any
        if (!response.ok) {
            let message = await response.text()
            await customDispatch(genericNotificationThunk(message))
        } else {
            await customDispatch(loadSecurityReflectionThunk)
        }
        dispatch(progress.actions.closeProgressBar())
    }
}
