import {IProjectModel} from "../api/IProjectModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "./store";
import {projectService} from "../api/ProjectsService";
import {CompromiseStatusEnum} from "../api/CompromiseStatusEnum";
import {notification} from "./notification";
import {Strings} from "../Resources/Strings";
import {compromiseService} from "../api/CompromiseService";
import moment from "moment/moment";
import {loadCompromisesThunk} from "./compromise";

export interface ProjectState {
    selectedProject: IProjectModel,
    projects: IProjectModel[]
}

const initialState: ProjectState = {
    selectedProject: null,
    projects: []
}

export const project = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        setSelectedProject(state: ProjectState, payload: PayloadAction<IProjectModel>) {
            state.selectedProject = payload.payload;
            return state;
        },
        unsetSelectedProject(state: ProjectState) {
            state.selectedProject = null;
            return state;
        },
        setProjects(state: ProjectState, payload: PayloadAction<IProjectModel[]>) {
            state.projects = payload.payload;
            return state
        }
    }
})


export async function loadProjectsThunk(dispatch: AppDispatch) {
    let projects = await projectService.getAllProjects()
    await dispatch(project.actions.setProjects(projects))
}

export function navigateToProjectCompromisesThunk(projectId: string, history: any) {
    return async function (dispatch: AppDispatch, getState: () => RootState) {
        let projects = await getState().project.projects;
        let selectedProject = projects.find(item => item.id == projectId)
        await dispatch(project.actions.setSelectedProject(selectedProject))
        history.push("/projectCompromises")
    }
}