import React, {FC, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import moment from "moment";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    FormControlLabel,
    Grid, IconButton, List, ListItem,
    TextField, Tooltip
} from "@material-ui/core";
import {margin, padding} from "../../../styles/styleConstants";
import {Strings} from "../../../Resources/Strings";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactQuill from "react-quill";
import {compromise, updateCompromiseThunk} from "../../../redux/compromise";
import Center from 'react-center';
import {ICompromiseModel} from "../../../api/ICompromiseModel";
import {CompromiseStatusEnum} from "../../../api/CompromiseStatusEnum";
import {firstCharToUpper, GetCompromiseStatus, GetCompromiseStatusString} from "../../../utils/utils";
import {Autocomplete} from "@material-ui/lab";
import {compromiseService} from "../../../api/CompromiseService";
import {IUserModel} from "../../../api/IUserModel";
import {loadUsersThunk} from "../../../redux/user";
import {Add, Delete} from "@material-ui/icons";


export interface EditCompromiseDialogComponentProps {
    selectedCompromise: ICompromiseModel
}

export const EditCompromiseDialogComponent: FC<EditCompromiseDialogComponentProps> = (props) => {

    const {isEditingCompromise} = useSelector((state: RootState) => state.compromise);
    const {users} = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch()

    const [title, setTitle] = useState<string>()
    const [contentHtml, setContentHtml] = useState<string>()
    const [dueDate, setDueDate] = useState<Date>()
    const [hasDueDate, setHasDueDate] = useState<boolean>()

    const [selectedCompromiseStatusString, setSelectedCompromiseStatusString] = useState<string>()
    const [compromiseStatusAvailableString, setCompromiseStatusAvailableString] = useState<string[]>([])

    const [selectedAssociatedUser, setSelectedAssociatedUser] = useState<IUserModel>()
    const [availableAssociatedUsers, setAvailableAssociatedUsers] = useState<IUserModel[]>()
    const [associatedUsers, setAssociatedUsers] = useState<IUserModel[]>()

    const [percentageCompleted, setPercentageCompleted] = useState<number>()

    const [isSaveAvailable, setIsSaveAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isLoadingAssociatedUsers, setIsLoadingAssociatedUsers] = useState<boolean>()

    async function loadAvailableUsers() {
        await dispatch(loadUsersThunk)
        let id = props.selectedCompromise.id;
        let userModels = await compromiseService.getAvailableAssociatedUsers(id);
        setAvailableAssociatedUsers([...userModels])
        let associated = props.selectedCompromise.associatedUsers?.map(value => {
            return users.find(user => user.id === value.id) as IUserModel
        })
        setAssociatedUsers(associated)
        setIsLoadingAssociatedUsers(false)
    }

    useEffect(() => {
        setIsLoadingAssociatedUsers(true)
        setTitle(props.selectedCompromise.title)
        setContentHtml(props.selectedCompromise.contentHtml)
        setPercentageCompleted(props.selectedCompromise.percentageCompleted)
        setAssociatedUsers([])
        setAvailableAssociatedUsers([])
        setSelectedAssociatedUser(undefined)
        let date = moment(props.selectedCompromise.dueDate).toDate()
        setCompromiseStatusAvailableString(
            [GetCompromiseStatusString(CompromiseStatusEnum.NotStarted),
                GetCompromiseStatusString(CompromiseStatusEnum.InProgress),
                GetCompromiseStatusString(CompromiseStatusEnum.Completed),
                GetCompromiseStatusString(CompromiseStatusEnum.Deleted)])
        setSelectedCompromiseStatusString(GetCompromiseStatusString(props.selectedCompromise.compromiseStatus))

        date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        setDueDate(date)
        setHasDueDate(props.selectedCompromise.hasDueDate)
        loadAvailableUsers();
    }, [props.selectedCompromise])


    const onContentHtmlCallback = (value) => {
        setContentHtml(value)
        setIsSaveAvailable(true)
    }

    const onDueDateChangeCallback = (date: Date) => {
        setDueDate(date)
        setIsSaveAvailable(true)
    }

    const onChangeTitleCallback = (event: any | HTMLInputElement) => {
        setTitle(event.target.value);
        setIsSaveAvailable(true)
    }

    const onChangePercentageCompleted = (event: any | HTMLInputElement) => {
        setPercentageCompleted(event.target.value);
        setIsSaveAvailable(true)
    }

    const onDueDateChangedCallback = () => {
        setHasDueDate(!hasDueDate)
        setIsSaveAvailable(true);
    }

    const onSaveCallback = async () => {
        let id = props.selectedCompromise.id;
        setIsSaveAvailable(false)
        setIsLoading(true)
        await dispatch(updateCompromiseThunk(id, title, contentHtml, hasDueDate,
            GetCompromiseStatus(selectedCompromiseStatusString), percentageCompleted, dueDate,
            associatedUsers.map(value => value.id)))
        dispatch(compromise.actions.closeEditCompromise())
        setIsLoading(false)
    }


    function addAssociatedUser(user: IUserModel) {
        if (user === undefined)
            return;
        availableAssociatedUsers.splice(availableAssociatedUsers.indexOf(user), 1)
        setAvailableAssociatedUsers(availableAssociatedUsers)

        associatedUsers.push(user)
        setAssociatedUsers(associatedUsers)

        setSelectedAssociatedUser(undefined)
        setIsSaveAvailable(true)
    }

    function deleteAssociatedCallback(index: number) {
        let currentAssociatedUser = associatedUsers[index]
        associatedUsers.splice(index, 1)
        setAssociatedUsers(associatedUsers)
        let availableAssociatedUsersTemporal = [...availableAssociatedUsers]

        availableAssociatedUsersTemporal.push(currentAssociatedUser);
        setAvailableAssociatedUsers(availableAssociatedUsersTemporal)
        setIsSaveAvailable(true)
    }


    const handleClose = () => {
        dispatch(compromise.actions.closeEditCompromise())
    }


    return <>
        <Dialog open={isEditingCompromise} onClose={handleClose} fullWidth>
            <DialogTitle>{Strings.EditCompromise}</DialogTitle>
            <DialogContent>
                <Grid direction='column' container>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <TextField value={title} onChange={onChangeTitleCallback}
                                       label={Strings.Title} variant='outlined' fullWidth/>
                        </Box>
                    </Grid>

                    <Divider/>

                    <Grid item xs='auto'>
                        <Grid container alignContent='center'>
                            <Grid item xs>
                                <Box m={margin} p={padding}>
                                    <FormControlLabel
                                        control={<Checkbox checked={hasDueDate} onChange={onDueDateChangedCallback}/>}
                                        label={Strings.DueDate}/>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Box m={margin} p={padding}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker value={dueDate} onChange={onDueDateChangeCallback}
                                                    format="dd/MM/yyyy" disabled={!hasDueDate}/>
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs='auto'>
                        <Grid container alignContent='center'>
                            <Grid item xs>
                                <Box m={margin} p={padding}>
                                    <Autocomplete
                                        value={selectedCompromiseStatusString}
                                        onChange={(event: any, newValue: string | null) => {
                                            setSelectedCompromiseStatusString(newValue);
                                            setIsSaveAvailable(true)
                                        }}
                                        id="combo-box-demo"
                                        options={compromiseStatusAvailableString}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) =>
                                            <TextField {...params} label={Strings.Status} variant="outlined"/>}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs>
                                <Box m={margin} p={padding}>
                                    <TextField type='number' value={percentageCompleted}
                                               onChange={onChangePercentageCompleted}
                                               label={Strings.PercentageCompleted} fullWidth/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider/>

                    {associatedUsers && !isLoadingAssociatedUsers && <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Grid container direction='row' justifyContent='center' alignItems='center'>
                                <Grid item xs>
                                    <Autocomplete value={selectedAssociatedUser}
                                                  onChange={(event: any, newValue: IUserModel) => {
                                                      setSelectedAssociatedUser(newValue);
                                                  }}
                                                  options={availableAssociatedUsers}
                                                  getOptionLabel={(option) => option.email}
                                                  renderInput={(params) =>
                                                      <TextField {...params} variant="outlined"
                                                                 label={Strings.AssociatedUser}/>}
                                    />
                                </Grid>
                                <Grid item xs='auto'>
                                    <IconButton
                                        onClick={() => addAssociatedUser(selectedAssociatedUser)}><Add/></IconButton>
                                </Grid>
                            </Grid>
                            <List>
                                {associatedUsers.map((associatedUser, index) => <>
                                    <ListItem>
                                        <Grid container direction='row' justifyContent='center' alignItems='center'
                                              alignContent='center'>
                                            <Grid item xs>
                                                {firstCharToUpper(associatedUser.name)} {firstCharToUpper(associatedUser.surname)}
                                            </Grid>
                                            <Grid item xs='auto'>
                                                <Tooltip title={Strings.Delete}>
                                                    <IconButton
                                                        onClick={() => deleteAssociatedCallback(index)}>
                                                        <Delete/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </>)}
                            </List>
                        </Box>
                    </Grid>}

                    {isLoadingAssociatedUsers &&
                        <Grid container alignContent='center' justifyContent='center' alignItems='center'>
                            <Box m={margin} p={padding}>
                                <CircularProgress size={30}/>
                            </Box>
                        </Grid>}
                </Grid>

                <Divider/>
                <Box m={margin} p={padding}>
                    <ReactQuill value={contentHtml} onChange={onContentHtmlCallback}
                                theme='snow' placeholder={Strings.Notes}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onSaveCallback}
                        disabled={isLoading || !isSaveAvailable}>
                    <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                        {isLoading && <>
                            <Grid item xs='auto'>
                                <Center>
                                    <CircularProgress size={20}/>
                                </Center>
                            </Grid>

                            <Grid item xs>
                                <Box m={margin}/>
                            </Grid>
                        </>}
                        <Grid item xs='auto'>
                            {Strings.Save}
                        </Grid>
                    </Grid>
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

