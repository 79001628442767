import {ActivePauseAdminComponent} from "./ActivePauseAdminComponent";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import React, {useEffect} from "react";
import {appRoles} from "../../authentication/appRoles";
import {Box, List, ListItem, Paper, Typography} from "@material-ui/core";
import {elevation, margin, padding} from "../../styles/styleConstants";
import {Strings} from "../../Resources/Strings";
import {loadActivePauseThunk} from "../../redux/activePause";

export function ActivePauseComponent() {

    const {identity} = useSelector((state: RootState) => state.authentication)
    const dispatch = useDispatch()
    const {activePauses} = useSelector((state: RootState) => state.activePause)

    useEffect(() => {
        async function loadActivePauses() {
            await dispatch(loadActivePauseThunk)
        }
        // noinspection JSIgnoredPromiseFromCall
        loadActivePauses()
    }, [])


    return <>
        {/*Admin area*/}
        {identity.credentials.roles.includes(appRoles.admin) && <>
            <Box p={padding} m={margin}>
                <Paper elevation={elevation}>
                    <ActivePauseAdminComponent/>
                </Paper>
            </Box>
        </>}

        <Typography variant='h6'>{Strings.ActivePause}</Typography>
        <List>
            {activePauses.map(value => {
                return <ListItem key={value.id}>
                    <Typography
                        variant='body1'><b>{value.email}</b> {value.date.getUTCDate().toString().padStart(2, "0")+"-"+(value.date.getMonth()+1).toString().padStart(2, "0")+"-"+value.date.getFullYear()} {value.groupNumber} 
                    </Typography>
                </ListItem>
            })}
        </List>
    </>
}