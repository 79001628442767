import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {ITrackingHoursModel} from "./ITrackingHoursModel";
import {List} from "linq-typescript";
import {IUpdateTrackingHours} from "./IUpdateTrackingHours";
import {IRemoveTrackingHours} from "./IRemoveTrackingHours";
import {ICreateTrackingHours} from "./ICreateTrackingHours";
import {IApproveHoursModel} from "./IApproveHoursModel";
import {getQueryDate, getWeekNumber} from "../utils/utils";
import moment from "moment";
import {IUpdateTrackingHoursStatus} from "./IUpdateTrackingHoursStatus";
import {ISendHoursModel} from "./ISendHoursModel";
import {TrackingHoursStateEnum} from "./TrackingHoursStateEnum";
import {TimeTrackingTypeEnum} from "./TimeTrackingTypeEnum";

class TrackingHoursService extends BaseService {
    async getTrackingHours(userId: string, startDate: Date, endDate: Date): Promise<ITrackingHoursModel[]> {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'trackinghours?';

        let startDateString = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCDate()}`;
        let endDateString = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}`;


        let url = `${host}${resource}userIds=${userId}&startDate=${startDateString}&endDate=${endDateString}`;


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as ITrackingHoursModel[];
    }

    async getWaitingForApproval(userId: string) {
        let token = await this.getToken();

        let host = apiUrl;
        let resource = 'trackinghours?';
        let url = `${host}${resource}`;

        url += `managerIds=${userId}&trackingHoursStates=${TrackingHoursStateEnum.WaitingForApproval}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        let approveHours = new List(await response.json() as IApproveHoursModel[]);

        approveHours.forEach(element => {
            let [fullYear, numberOfWeek] = getWeekNumber(moment(element.insertionDate).toDate());
            element.fullYear = fullYear;
            //Cadan Se agrega para 2023 una semana más por diferencia bisiestos y no bisiestos 
            //element.numberOfWeek = numberOfWeek + 1;
            //Cadan 2024 se elimina la suma de la semana se hace para que no se genere eror en aprobacion de HH y obtencion de reporte de HH
              element.numberOfWeek = numberOfWeek;
        });
        return approveHours.toArray();
    }

    async getWaitingForApprovalSecondaryApprover(managersIds: string[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours?';
        let managerIdsQuery = `trackingHoursStates=${TrackingHoursStateEnum.WaitingForApproval}`;
        managersIds.forEach(managerId => 
            managerIdsQuery = `managerIds=${managerId}&` + managerIdsQuery);
        managerIdsQuery = managerIdsQuery;
        let url = `${host}${resource}${managerIdsQuery}`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        let approveHours = new List(await response.json() as IApproveHoursModel[]);

        approveHours.forEach(element => {
            let [fullYear, numberOfWeek] = getWeekNumber(moment(element.insertionDate).toDate());
            element.fullYear = fullYear;
            //Cadan Se agrega para 2023 una semana más por diferencia bisiestos y no bisiestos 
            //element.numberOfWeek = numberOfWeek + 1;
            //Cadan 2024 se elimina la suma de la semana se hace para que no se genere eror en aprobacion de HH y obtencion de reporte de HH
              element.numberOfWeek = numberOfWeek;
        });
        return approveHours.toArray();
    }

    async updateTrackingHoursStatusByManager(updateTrackingHoursStatus: IUpdateTrackingHoursStatus[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'trackinghours/status';
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(updateTrackingHoursStatus),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions);
    }

    async sendHours(sendHourModels: ISendHoursModel[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours/status';
        let url = `${host}${resource}`;

        let updateTrackingHoursStatusArray: IUpdateTrackingHoursStatus[] = [];
        sendHourModels.forEach(value => {
            updateTrackingHoursStatusArray.push({
                id: value.id,
                status: TrackingHoursStateEnum.WaitingForApproval
            })
        });

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(updateTrackingHoursStatusArray),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        await fetch(`${url}`, requestOptions)
    }

    async approveHours(projectId: string, userId: string, fromDate: Date, toDate: Date, type: TimeTrackingTypeEnum) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours/approveHours?';

        let fromDateString = `${fromDate.getUTCFullYear()}-${fromDate.getUTCMonth() + 1}-${fromDate.getUTCDate()}`;
        let toDateString = `${toDate.getUTCFullYear()}-${toDate.getUTCMonth() + 1}-${toDate.getUTCDate()}`;

        let url = `${host}${resource}projectId=${projectId}&userId=${userId}&fromDate=${fromDateString}&toDate=${toDateString}&type=${type}`;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        return await fetch(`${url}`, requestOptions);
    }

    async rejectHours(userId: string, projectId: string, fromDate: Date, toDate: Date, reasons: string) {
        let token = await this.getToken();

        let host = apiUrl;
        let resource = 'TrackingHours/rejectHours?';

        let fromDateString = `${fromDate.getUTCFullYear()}-${fromDate.getUTCMonth() + 1}-${fromDate.getUTCDate()}`;
        let toDateString = `${toDate.getUTCFullYear()}-${toDate.getUTCMonth() + 1}-${toDate.getUTCDate()}`;

        let url = `${host}${resource}projectId=${projectId}&userId=${userId}&fromDate=${fromDateString}&toDate=${toDateString}&reasons=${reasons}`;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        return await fetch(`${url}`, requestOptions);
    }

    async updateTrackingHours(updateTrackingHours: IUpdateTrackingHours[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours/hours';
        let url = `${host}${resource}`;

        if (updateTrackingHours.length === 0)
            return new Promise<Response>(resolve => {
                let response = new Response();
                resolve(response);
                return response;
            });

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(updateTrackingHours),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        return await fetch(`${url}`, requestOptions)
    }

    async removeTrackingHours(removeTrackingHours: IRemoveTrackingHours[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours?';
        let url = `${host}${resource}`;

        var trackingHoursIdsQuery = '';
        removeTrackingHours.map(value => value.id).forEach(trackingHourId => trackingHoursIdsQuery = trackingHoursIdsQuery + `ids=${trackingHourId}&`);
        trackingHoursIdsQuery = trackingHoursIdsQuery.slice(0, -1);
        url += trackingHoursIdsQuery;
        if (removeTrackingHours.length === 0)
            return new Promise<Response>(resolve => {
                let response = new Response();
                resolve(response);
                return resolve;
            });

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions);
    }

    async createTrackingHours(createTrackingHours: ICreateTrackingHours[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = 'TrackingHours';
        let url = `${host}${resource}`;

        if (createTrackingHours.length === 0)
            return new Promise<Response>(resolve => {
                let response = new Response();
                resolve(response);
                return response;
            });

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(createTrackingHours),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions)
    }

    async resetHoursAdmin(startDate: Date, endDate: Date, email: string) {
        let token = await this.getToken();
        let host = apiUrl;
        let startQueryDate = getQueryDate(startDate)
        let endQueryDate = getQueryDate(endDate)
        let resource = `/Admin/trackingHoursState?trackingHoursState=2&emails=${email}&startDate=${startQueryDate}&endDate=${endQueryDate}`;
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions)
    }
}

export const trackingHoursService = new TrackingHoursService()
