import {apiUrl} from "./ApiConfig";
import {PureBase64} from "../utils/utils";

interface IPelambresCheckInput {

    mineToDestinationBase64: string,
    destinationToMineBase64: string

}

interface IPelambresReportInput {
    cuts: string[],
    uniqueDays: number[],
    mineToDestinationBase64: string,
    destinationToMineBase64: string
}


export interface IPelambresCutsAndDaysModel {
    cuts: string[],
    uniqueDays: number[]
}

export class PelambresService {

    async checkData(mineToDestinationBase64: string, destinationToMineBase64: string) {
        let url = `${apiUrl}pelambres/uniqueDaysAndCuts`
        let body = {
            mineToDestinationBase64: PureBase64(mineToDestinationBase64),
            destinationToMineBase64: PureBase64(destinationToMineBase64)
        } as IPelambresCheckInput
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        } as RequestInit;
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IPelambresCutsAndDaysModel;
    }

    async getReport(mineToDestinationBase64: string, destinationToMineBase64: string,
                    cuts: string[], uniqueDays: number[]) {
        let url = `${apiUrl}pelambres/carkeetReport`
        let body = {
            cuts: cuts,
            uniqueDays: uniqueDays,
            mineToDestinationBase64: PureBase64(mineToDestinationBase64),
            destinationToMineBase64: PureBase64(destinationToMineBase64)
        } as IPelambresReportInput;
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
        } as RequestInit;
        let response = await fetch(`${url}`, requestOptions);
        return await response.blob();
    }
}



