import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux/store";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {question} from "../../../redux/question";
import {Strings} from "../../../Resources/Strings";

export const YesNoCancelDialogComponent = () => {

    const dispatch = useDispatch<AppDispatch>();
    const {isOpen, text, title, actionOnYes, onFinally} = useSelector((state: RootState) => state.question)


    function handleYes() {
        dispatch(question.actions.closeDialog())
        actionOnYes();
        if (onFinally)
            onFinally();
    }

    function handleNo() {
        dispatch(question.actions.closeDialog())
        if (onFinally)
            onFinally();
    }

    return <>
        <Dialog open={isOpen} onClose={handleYes} fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography variant='h6'>{text}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes} color="primary" variant='contained'>
                    {Strings.Yes}
                </Button>
                <Button onClick={handleNo} color="secondary" variant='contained'>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}