import React from 'react'

import {Button, Grid, Typography} from "@material-ui/core";
import {usersService} from "../../api/UsersService";


export const TokenComponent = () => {
    const [token, setToken] = React.useState<string>();

    React.useEffect(() => {
        usersService.getToken().then(value => setToken(value));
    }, [])

    async function copyBearer() {
        await navigator.clipboard.writeText(`Bearer ${token}`)
    }

    return <div>
        <Grid>
            <Typography variant='h6' noWrap={false}
                        paragraph>Bearer {token}</Typography>
            <Button onClick={copyBearer}>Copy</Button>
        </Grid>
    </div>
}