import React from 'react'
import {IApproveHoursRowData} from "./IApproveHoursRowData";
import {List} from "linq-typescript";
import {Credentials} from "../../api/Credentials";
import {ITrackingHoursProject} from "../../api/ITrackingHoursProject";
import moment from "moment";
import {getDate, getCodeProjectName, isEmpty, getTimeTrackingTypeDisplay} from "../../utils/utils";
import MaterialTable, {Column} from "material-table";
import {IApproveHoursModel} from "../../api/IApproveHoursModel";
import {IUpdateTrackingHoursStatus} from "../../api/IUpdateTrackingHoursStatus";
import {TrackingHoursStateEnum} from "../../api/TrackingHoursStateEnum";
import {RejectHoursDialogComponent} from "../Dialogs/RejectHours/RejectHoursDialogComponent";
import {trackingHoursService} from "../../api/TrackingHoursService";
import {usersService} from "../../api/UsersService";
import {projectService} from "../../api/ProjectsService";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Strings} from "../../Resources/Strings";
import {secondaryProjectApproversService} from "../../api/SecondaryProjectApproverService";
import { render } from 'react-dom';

export const ApproveHoursComponent = () => {        
    const {identity} = useSelector((state: RootState) => state.authentication);
    const [credentials] = React.useState(identity.credentials);
    const [dataTable, setDataTable] = React.useState([] as IApproveHoursRowData[]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [approverDataTable, setApproverDataTable] = React.useState([] as IApproveHoursRowData[]);
    const [secondaryIsLoading, setSecondaryIsLoading] = React.useState(false);
    const [isOpenRejected, setIsOpenRejected] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<IApproveHoursRowData>(null);
    const [userIsSecondaryApprover, setUserIsSecondaryApprover] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsLoading(true);
        initializeApproveHours(credentials).then(value => {
            setDataTable(value);
            setIsLoading(false);
        });
        setSecondaryIsLoading(true);
        isUserSecondaryApprover();
        if(userIsSecondaryApprover){
            initializeSecondaryApproveHours(credentials).then(value => {
                setApproverDataTable(value);
                setSecondaryIsLoading(false);
            });
        }
    }, [userIsSecondaryApprover])

    async function isUserSecondaryApprover(){
        let user = await usersService.getUserByEmail(credentials.email);
        let isSeconsaryApprover = await secondaryProjectApproversService.getSecondaryProjectApproversByApproverId(user.id);
        if(isSeconsaryApprover.length > 0){
            setUserIsSecondaryApprover(true)    
        }
        else{
            setUserIsSecondaryApprover(false)
        }        
    }
    
    function approveHours(rowData: IApproveHoursRowData) {
        setIsLoading(true);
        let index = dataTable.indexOf(rowData);
        let newTable = [...dataTable];
        newTable.splice(index, 1);
        setDataTable(newTable);

        let updateTrackingHoursStatusArray = [] as IUpdateTrackingHoursStatus[];

        if (rowData.saturdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.saturdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.sundayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.sundayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.mondayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.mondayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.tuesdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.tuesdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.wednesdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.wednesdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.thursdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.thursdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.fridayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.fridayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        let endDate = moment(rowData.startDate).toDate()
        endDate.setUTCDate(endDate.getUTCDate() + 6);

        trackingHoursService.approveHours(rowData.projectId, rowData.userId, rowData.startDate, endDate, rowData.type)
            .then(() => {
                setIsLoading(false);
            });
    }

    function secondaryApproveHours(rowData: IApproveHoursRowData) {
        setSecondaryIsLoading(true);
        let index = approverDataTable.indexOf(rowData);
        let newTable = [...approverDataTable];
        newTable.splice(index, 1);
        setApproverDataTable(newTable);

        let updateTrackingHoursStatusArray = [] as IUpdateTrackingHoursStatus[];

        if (rowData.saturdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.saturdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.sundayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.sundayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.mondayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.mondayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.tuesdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.tuesdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.wednesdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.wednesdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.thursdayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.thursdayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        if (rowData.fridayId !== undefined) {
            updateTrackingHoursStatusArray.push({
                id: rowData.fridayId,
                status: TrackingHoursStateEnum.Approved,
                log: ''
            } as IUpdateTrackingHoursStatus)
        }

        let endDate = moment(rowData.startDate).toDate()
        endDate.setUTCDate(endDate.getUTCDate() + 6);

        trackingHoursService.approveHours(rowData.projectId, rowData.userId, rowData.startDate, endDate, rowData.type)
            .then(() => {
                setSecondaryIsLoading(false);
            });
    }

    function rejectHours(rowData: IApproveHoursRowData) {
        setSelectedRow(rowData);
        setIsOpenRejected(true);
    }

    function onRejectedHoursDialogClose(status: boolean, reasons: string, rowData: IApproveHoursRowData) {
        setIsOpenRejected(false);
        if (!status || isEmpty(reasons))
            return;

        setIsLoading(true);
        let index = dataTable.indexOf(rowData);
        let newTable = [...dataTable];
        newTable.splice(index, 1);
        setDataTable(newTable);

        let endDate = moment(rowData.startDate).toDate()
        endDate.setUTCDate(endDate.getUTCDate() + 6);
        trackingHoursService.rejectHours(rowData.userId, rowData.projectId, rowData.startDate, endDate, reasons).then(() => {
            setIsLoading(false);
        });
    }

    function onRejectedSecondaryHoursDialogClose(status: boolean, reasons: string, rowData: IApproveHoursRowData) {
        setIsOpenRejected(false);
        if (!status || isEmpty(reasons))
            return;

        setSecondaryIsLoading(true);
        let index = approverDataTable.indexOf(rowData);
        let newTable = [...approverDataTable];
        newTable.splice(index, 1);
        setApproverDataTable(newTable);

        let endDate = moment(rowData.startDate).toDate()
        endDate.setUTCDate(endDate.getUTCDate() + 6);
        trackingHoursService.rejectHours(rowData.userId, rowData.projectId, rowData.startDate, endDate, reasons).then(() => {
            setSecondaryIsLoading(false);
        });
    }

    if(userIsSecondaryApprover){
        return <div>
            <MaterialTable columns={getColumns()} data={dataTable} title={Strings.ApproveHours} options={{
                paging: false,
                actionsColumnIndex: -1
            }} actions={[{
                icon: 'checked',
                tooltip: 'Aprobar',
                onClick: (event, rowData: IApproveHoursRowData) => approveHours(rowData)
            }, {
                icon: 'cancel',
                tooltip: 'Rechazar',
                onClick: (event, rowData: IApproveHoursRowData) => rejectHours(rowData)
            }]} isLoading={isLoading}/>
            <RejectHoursDialogComponent onClose={onRejectedHoursDialogClose} isOpen={isOpenRejected} row={selectedRow}/>
            <br/>                
            <MaterialTable columns={getColumns()} data={approverDataTable} title={Strings.SecondaryProjectApproverHours} options={{
                paging: false,
                actionsColumnIndex: -1
            }} actions={[{
                icon: 'checked',
                tooltip: 'Aprobar',
                onClick: (event, rowData: IApproveHoursRowData) =>  secondaryApproveHours(rowData)
            }, {
                icon: 'cancel',
                tooltip: 'Rechazar',
                onClick: (event, rowData: IApproveHoursRowData) => rejectHours(rowData)
            }]} isLoading={secondaryIsLoading}/>            
            <RejectHoursDialogComponent onClose={onRejectedSecondaryHoursDialogClose} isOpen={isOpenRejected} row={selectedRow}/>
        </div>           
    }
    else{
        return <div>
            <MaterialTable columns={getColumns()} data={dataTable} title={Strings.ApproveHours} options={{
                paging: false,
                actionsColumnIndex: -1
            }} actions={[{
                icon: 'checked',
                tooltip: 'Aprobar',
                onClick: (event, rowData: IApproveHoursRowData) => approveHours(rowData)
            }, {
                icon: 'cancel',
                tooltip: 'Rechazar',
                onClick: (event, rowData: IApproveHoursRowData) => rejectHours(rowData)
            }]} isLoading={isLoading}/>
            <RejectHoursDialogComponent onClose={onRejectedHoursDialogClose} isOpen={isOpenRejected} row={selectedRow}/>
        </div>
    }        
};

function getColumns(): Column<IApproveHoursRowData>[] {
    return [{title: 'Usuario', field: 'userEmail', type: 'string', editable: "never", width: 'auto'},
        {title: 'Proyecto', field: 'projectCodeName', type: 'string', editable: "never", width: 'auto'},
        {title: 'Tipo', field: 'timeTrackingTypeDisplay', type: 'string', editable: "never", width: 'auto'},
        {title: 'Fecha', field: 'dateDisplay', type: 'string', editable: "never", width: 'auto'},
        {title: 'Sábado', field: 'saturdayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Domingo', field: 'sundayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Lunes', field: 'mondayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Martes', field: 'tuesdayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Miércoles', field: 'wednesdayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Jueves', field: 'thursdayHours', type: 'string', editable: "never", width: 'auto'},
        {title: 'Viernes', field: 'fridayHours', type: 'string', editable: "never", width: 'auto'},
    ];
}

async function initializeApproveHours(credentials: Credentials) {
    let approveHoursData = [] as IApproveHoursRowData[];
    
    let user = await usersService.getUserByEmail(credentials.email);
    let managerId = user.id;

    let trackingHours = await trackingHoursService.getWaitingForApproval(managerId);
 
    // Create the list and push the elements
    const trackingHourModels = new List<IApproveHoursModel>();
    trackingHourModels.pushRange(trackingHours);
    let users = new List(await usersService.getUsers());
   
    // Get all distinct projects
    let distinctProjectIds = trackingHourModels.select(element => element.project.id).distinct().toArray();
    let trackingHoursProjectModels = await projectService.getProjectByIds(distinctProjectIds);
    // Bundle between userId
    trackingHourModels.groupBy(element => element.user.id).forEach(element => {
        let userId = element.key;
        let trackingHoursModelsByUserId = element.value.toList();
        // Bundle between projects
        trackingHoursModelsByUserId.groupBy(element => element.project.id).forEach(trackingHoursProject => {
            let project = trackingHoursProjectModels
                .find(value => value.id === trackingHoursProject.key) as ITrackingHoursProject;
            let trackingHoursModelsByProjects = trackingHoursProject.value;
            trackingHoursModelsByProjects.groupBy(element => element.type).forEach(trackingHoursType => {
                let type = trackingHoursType.key
                let trackingHoursModelByType = trackingHoursType.value;
                trackingHoursModelByType.groupBy(element => element.fullYear).forEach(trackingHoursByInsertionYear => {
                    trackingHoursByInsertionYear.value.groupBy(element => element.numberOfWeek)
                        .forEach(trackingHoursByNumberOfWeek => {
                            let approveHoursRowData: IApproveHoursRowData = {
                                projectId: project.id,
                                userId: userId,
                                startDate: getDate(trackingHoursByNumberOfWeek.key, trackingHoursByInsertionYear.key),
                                dateDisplay: getDate(trackingHoursByNumberOfWeek.key, trackingHoursByInsertionYear.key).toDateString(),
                                userEmail: users.where(user => user.id === userId).first().email,
                                projectCode: project.code,
                                projectName: project.name,
                                projectCodeName: getCodeProjectName(project.code, project.name),
                                timeTrackingTypeDisplay: getTimeTrackingTypeDisplay(type),
                                type: type
                            };

                            trackingHoursByNumberOfWeek.value.toList().forEach(trackingHoursModel => {
                                let hours = trackingHoursModel.hours;
                                let date = moment(trackingHoursModel.insertionDate).toDate();
                                let day = date.getUTCDay();
                                let id = trackingHoursModel.id;
                                if (day === 0) {
                                    approveHoursRowData.sundayId = id;
                                    approveHoursRowData.sundayHours = hours;
                                    approveHoursRowData.sundayDate = date;
                                } else if (day === 1) {
                                    approveHoursRowData.mondayId = id;
                                    approveHoursRowData.mondayHours = hours;
                                    approveHoursRowData.mondayDate = date;
                                } else if (day === 2) {
                                    approveHoursRowData.tuesdayId = id;
                                    approveHoursRowData.tuesdayHours = hours;
                                    approveHoursRowData.tuesdayDate = date;
                                } else if (day === 3) {
                                    approveHoursRowData.wednesdayId = id;
                                    approveHoursRowData.wednesdayHours = hours;
                                    approveHoursRowData.wednesdayDate = date;
                                } else if (day === 4) {
                                    approveHoursRowData.thursdayId = id;
                                    approveHoursRowData.thursdayHours = hours;
                                    approveHoursRowData.thursdayDate = date;
                                } else if (day === 5) {
                                    approveHoursRowData.fridayId = id;
                                    approveHoursRowData.fridayHours = hours;
                                    approveHoursRowData.fridayDate = date;
                                } else if (day === 6) {
                                    approveHoursRowData.saturdayId = id;
                                    approveHoursRowData.saturdayHours = hours;
                                    approveHoursRowData.saturdayDate = date;
                                }
                            });
                            approveHoursData.push(approveHoursRowData);
                        })
                });
            });
        });
    });
    return approveHoursData;
}

async function initializeSecondaryApproveHours(credentials: Credentials) {
    let secondaryApproveHoursData = [] as IApproveHoursRowData[];

    let user = await usersService.getUserByEmail(credentials.email);
    let userId = user.id;

    let trackingHours = await secondaryProjectApproversService.getSecondaryProjectApproversByApproverId(userId);
    let managersId: string[]=[];

    trackingHours.forEach(element =>
        managersId.push(element.toApproveUserId.id)
    );
    let secondaryApproverTrackingHours = await trackingHoursService.getWaitingForApprovalSecondaryApprover(managersId);

    // Create the list and push the elements
    const approverTrackingHourModels = new List<IApproveHoursModel>();
    approverTrackingHourModels.pushRange(secondaryApproverTrackingHours);
    let users = new List(await usersService.getUsers());
    // Get all distinct projects
    let approverDistinctProjectIds = approverTrackingHourModels.select(element => element.project.id).distinct().toArray();
    let approverTrackingHoursProjectModels = await projectService.getProjectByIds(approverDistinctProjectIds);
    // Bundle between userId
    approverTrackingHourModels.groupBy(element => element.user.id).forEach(element => {
        let userId = element.key;
        let trackingHoursModelsByUserId = element.value.toList();
        // Bundle between projects
        trackingHoursModelsByUserId.groupBy(element => element.project.id).forEach(trackingHoursProject => {
            let project = approverTrackingHoursProjectModels
                .find(value => value.id === trackingHoursProject.key) as ITrackingHoursProject;
            let trackingHoursModelsByProjects = trackingHoursProject.value;
            trackingHoursModelsByProjects.groupBy(element => element.type).forEach(trackingHoursType => {
                let type = trackingHoursType.key
                let trackingHoursModelByType = trackingHoursType.value;
                trackingHoursModelByType.groupBy(element => element.fullYear).forEach(trackingHoursByInsertionYear => {
                    trackingHoursByInsertionYear.value.groupBy(element => element.numberOfWeek)
                        .forEach(trackingHoursByNumberOfWeek => {
                            let approveHoursRowData: IApproveHoursRowData = {
                                projectId: project.id,
                                userId: userId,
                                startDate: getDate(trackingHoursByNumberOfWeek.key, trackingHoursByInsertionYear.key),
                                dateDisplay: getDate(trackingHoursByNumberOfWeek.key, trackingHoursByInsertionYear.key).toDateString(),
                                userEmail: users.where(user => user.id === userId).first().email,
                                projectCode: project.code,
                                projectName: project.name,
                                projectCodeName: getCodeProjectName(project.code, project.name),
                                timeTrackingTypeDisplay: getTimeTrackingTypeDisplay(type),
                                type: type
                            };

                            trackingHoursByNumberOfWeek.value.toList().forEach(trackingHoursModel => {
                                let hours = trackingHoursModel.hours;
                                let date = moment(trackingHoursModel.insertionDate).toDate();
                                let day = date.getUTCDay();
                                let id = trackingHoursModel.id;
                                if (day === 0) {
                                    approveHoursRowData.sundayId = id;
                                    approveHoursRowData.sundayHours = hours;
                                    approveHoursRowData.sundayDate = date;
                                } else if (day === 1) {
                                    approveHoursRowData.mondayId = id;
                                    approveHoursRowData.mondayHours = hours;
                                    approveHoursRowData.mondayDate = date;
                                } else if (day === 2) {
                                    approveHoursRowData.tuesdayId = id;
                                    approveHoursRowData.tuesdayHours = hours;
                                    approveHoursRowData.tuesdayDate = date;
                                } else if (day === 3) {
                                    approveHoursRowData.wednesdayId = id;
                                    approveHoursRowData.wednesdayHours = hours;
                                    approveHoursRowData.wednesdayDate = date;
                                } else if (day === 4) {
                                    approveHoursRowData.thursdayId = id;
                                    approveHoursRowData.thursdayHours = hours;
                                    approveHoursRowData.thursdayDate = date;
                                } else if (day === 5) {
                                    approveHoursRowData.fridayId = id;
                                    approveHoursRowData.fridayHours = hours;
                                    approveHoursRowData.fridayDate = date;
                                } else if (day === 6) {
                                    approveHoursRowData.saturdayId = id;
                                    approveHoursRowData.saturdayHours = hours;
                                    approveHoursRowData.saturdayDate = date;
                                }
                            });
                            secondaryApproveHoursData.push(approveHoursRowData);
                        })
                });
            });
        });
    });
    return secondaryApproveHoursData;
}
