import React, {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import moment from "moment";
import {
    addAdminHolidaysThunk,
    loadApprovingUsersThunk,
    requestUserHolidaysThunk,
    userHoliday
} from "../../../redux/userHolidays";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {margin, padding} from "../../../styles/styleConstants";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {LoadingButton} from "../../DataTemplates/LoadingButton/LoadingButton";
import {
    getHolidayTypeFromString,
    getHolidayTypeString,
    getHolidayTypeStrings,
    HolidayTypeEnum
} from "../../../api/HolidayTypeEnum";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IUserModel} from "../../../api/IUserModel";
import {loadUsersThunk} from "../../../redux/user";

interface AddUserHolidayDaysDialogComponentProps {
    isAdmin
}

export const AddUserHolidayDaysDialogComponent: FC<AddUserHolidayDaysDialogComponentProps> = (props) => {
    const dispatch = useDispatch();

    const {
        selectedUserForHolidays,
        isAddingUserHolidays,
        availableApprovingUsers
    } = useSelector((state: RootState) => state.userHolidays);


    const [toDate, setToDate] = useState<Date>(moment().toDate())
    const [fromDate, setFromDate] = useState<Date>(moment().toDate())
    const [comment, setComment] = useState<string>('')
    const [daysUsed, setDaysUsed] = useState<number>(1)


    // Available user
    const [selectedApprovingUser, setSelectedApprovingUser] = useState<IUserModel>()

    const [selectedHolidayTypeString, setSelectedHolidayTypeString] = useState(getHolidayTypeString(HolidayTypeEnum.Holiday))
    const [holidayTypesString] = useState<string[]>(getHolidayTypeStrings())

    const [isLoading, setIsLoading] = useState<boolean>()
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(true)

    useEffect(() => {
        async function loadData() {
            await dispatch(loadUsersThunk)
            await dispatch(loadApprovingUsersThunk)
        }

        if (isAddingUserHolidays) {
            loadData()
        }
    }, [isAddingUserHolidays])

    useEffect(() => {
        setComment('')
        setDaysUsed(1)
        setToDate(moment().toDate())
        setFromDate(moment().toDate())
    }, [isAddingUserHolidays])

    const handleNo = () => {
        dispatch(userHoliday.actions.closeAddUserHolidaysDialog());
    }

    useEffect(() => {
        if (props.isAdmin) {
            if (holidayTypesString === undefined)
                setIsYesAvailable(false)
            else
                setIsYesAvailable(true)
        } else {
            if (holidayTypesString === undefined || selectedApprovingUser === undefined)
                setIsYesAvailable(false)
            else
                setIsYesAvailable(true)
        }

    }, [selectedApprovingUser, holidayTypesString])

    const handleYes = async () => {
        setIsLoading(true)
        let userId = selectedUserForHolidays.id;

        if (props.isAdmin) {
            await dispatch(addAdminHolidaysThunk(userId, fromDate, toDate, daysUsed,
                comment, getHolidayTypeFromString(selectedHolidayTypeString)))
        } else {
            await dispatch(requestUserHolidaysThunk(userId, fromDate, toDate, daysUsed,
                comment, getHolidayTypeFromString(selectedHolidayTypeString), selectedApprovingUser.id))
        }
        setIsLoading(false)
    }

    const daysUsedChangedCallback = (event: any | HTMLInputElement) => {
        let text = event.target.value;
        let value = text.replace(/[^0-9]/g, '')
        setDaysUsed(value)
    }


    const fromDateChangedCallback = (date: Date) => {
        setFromDate(date)
    }
    const toDateChangedCallback = (date: Date) => {
        setToDate(date)
    }

    const commentChangedCallback = (event: any | HTMLInputElement) => {
        setComment(event.target.value)
    }

    return <>
        <Dialog open={isAddingUserHolidays} onClose={handleNo} fullWidth>
            <DialogTitle>
                {Strings.AddHolidays} {selectedUserForHolidays.name} {selectedUserForHolidays.surname}
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.BusinessDaysUsed}
                                       value={daysUsed}
                                       type='numeric'
                                       onChange={daysUsedChangedCallback}
                                       multiline
                                       fullWidth/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={fromDate} onChange={fromDateChangedCallback}
                                            label={Strings.InitialDate}
                                            format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={toDate} onChange={toDateChangedCallback}
                                            label={Strings.FinalDate} allowKeyboardControl
                                            format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.Comments}
                                       value={comment}
                                       onChange={commentChangedCallback}
                                       multiline
                                       fullWidth/>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box m={margin} p={padding}>
                            <Autocomplete
                                value={selectedHolidayTypeString}
                                onChange={(event: any, newValue: string) => {
                                    setSelectedHolidayTypeString(newValue);
                                }}
                                options={holidayTypesString}
                                getOptionLabel={(option) => option}
                                renderInput={(params) =>
                                    <TextField {...params} variant="outlined" label={`${Strings.Type}`}/>}
                            />
                        </Box>
                    </Grid>
                    {!props.isAdmin && <Grid item>
                        <Box m={margin} p={padding}>
                            <Autocomplete
                                value={selectedApprovingUser}
                                onChange={(event: any, newValue: IUserModel) => {
                                    setSelectedApprovingUser(newValue);
                                }}
                                options={availableApprovingUsers}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) =>
                                    <TextField {...params} variant="outlined" label={`${Strings.ApprovingUser}`}/>}
                            />
                        </Box>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleYes} inProgress={isLoading} text={Strings.Yes}
                               variant='contained' disabled={isLoading || !isYesAvailable} color='primary'
                               progressBarColor='secondary'/>
                <Button onClick={handleNo} color="secondary" variant='contained'
                        disabled={isLoading}>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}