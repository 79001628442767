import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IUserRefModel} from "./IUserRefModel";
import {IUserModel} from "./IUserModel";

export interface IUserSignatureModel {
    id: string
    user: IUserRefModel
    signatureImage: string
}

class SignatureService extends BaseService {
    async createSignature(signatureUrl: string) {
        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({content: signatureUrl}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${apiUrl}userSignature`, requestOptions);
    }

    async getSignature() {
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response =  await fetch(`${apiUrl}userSignature`, requestOptions);
        return await response.json() as IUserSignatureModel
    }

    async deleteSignature() {
        let token = await this.getToken();
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${apiUrl}userSignature`, requestOptions);
    }
}

export const signatureService = new SignatureService();