import React from 'react'
import {LinearProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

export const ProgressComponent = () => {
    const {isOpen, text, progress, isIndeterminate} = useSelector((state: RootState) => state.progress)
    return <>
        {isOpen && <LinearProgress color='secondary' variant={isIndeterminate ? 'indeterminate' : 'determinate'}/>}
    </>
}