import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {loadCompromisesThunk} from "../../redux/compromise";
import {progress} from "../../redux/progress";
import {loadUsersThunk} from "../../redux/user";
import {loadProjectsThunk} from "../../redux/project";
import {
    CompromisesTableComponent,
    CompromiseTableType
} from "../DataTemplates/CompromisesTable/CompromisesTableComponent";

export const ProjectCompromisesComponent = () => {
    const {selectedProject} = useSelector((state: RootState) => state.project)

    const dispatch = useDispatch()

    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadCompromisesThunk)
        await dispatch(loadUsersThunk)
        await dispatch(loadProjectsThunk)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])


    return <> {selectedProject &&
        <CompromisesTableComponent title={selectedProject.code}
                                   tableType={CompromiseTableType.Project}/>
    }    </>
}