import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";


export interface IExternalUser {
    id: string
    email: string,
    name: string,
    surname: string,
    clientId: string
}

class ExternalUsersService extends BaseService {
    async addExternalUser(email: string, name: string, surname: string, clientId: string) {
        const url = `${apiUrl}externalUser?email=${email}&name=${name}&surname=${surname}&clientid=${clientId}`;
        let token = await this.getToken();
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        let response = await fetch(url, request);
        return (await response.json()) as IExternalUser
    }

    async getExternalUsers(){
        const url = `${apiUrl}externalUser`;
        let token = await this.getToken();
        const request = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        let response = await fetch(url, request);
        return (await response.json()) as IExternalUser[]
    }
}

export const externalUsersService = new ExternalUsersService();