import {Strings} from "../Resources/Strings";

export enum AccountRequestStatusEnum {
    Pending = 0,
    Approved = 1,
    Rejected = 2
}

export function getAccountRequestStatusString(accountStatus: AccountRequestStatusEnum) {
    switch (accountStatus) {
        case AccountRequestStatusEnum.Pending:
            return Strings.Pending
        case AccountRequestStatusEnum.Approved:
            return Strings.Approved
        case AccountRequestStatusEnum.Rejected:
            return Strings.Rejected
    }
}

export function getAccountRequestStatus(accountStatusString: string) {
    switch (accountStatusString) {
        case Strings.Pending:
            return AccountRequestStatusEnum.Pending
        case Strings.Approved:
            return AccountRequestStatusEnum.Approved
        case Strings.Rejected:
            return AccountRequestStatusEnum.Rejected
    }
}