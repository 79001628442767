import {Column} from "material-table";
import {Strings} from "../../../Resources/Strings";
import {
    blueColor,
    GetCompromiseStatusString,
    getDateString,
    grayColor,
    greenColor,
    redColor
} from "../../../utils/utils";
import moment from "moment";
import {CompromiseStatusEnum} from "../../../api/CompromiseStatusEnum";
import React from "react";
import {ICompromiseRowData} from "./ICompromiseRowData";
import {ICompromiseModel} from "../../../api/ICompromiseModel";
import {IUserModel} from "../../../api/IUserModel";
import {ITableFilter} from "./CompromisesTableComponent";
import {IProjectModel} from "../../../api/IProjectModel";
import {Box, Typography} from "@material-ui/core";
import {margin, padding} from "../../../styles/styleConstants";
import ReactQuill from "react-quill";

export function getGeneralCompromisesTemplateColumns(filters?: ITableFilter[]) {
    let columns: Column<ICompromiseRowData>[] = [];
    columns.push({
        title: Strings.Title,
        field: 'title',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Owner,
        field: 'ownerEmail',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Created,
        field: 'creationDate',
        dateSetting: {
            format: 'dd/MM/yyyy',
        },
        render: (rowData: ICompromiseRowData) => {
            return <>{getDateString(rowData.creationDate)}</>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Due,
        field: 'dueDate',
        dateSetting: {
            format: 'dd/MM/yyyy'
        },
        render: (rowData: ICompromiseRowData) => {
            let dueDate = rowData.dueDate
            let currentDate = moment().toDate()
            if (rowData.hasDueDate) {
                let dateString = getDateString(dueDate)
                if (currentDate > dueDate && (rowData.compromiseStatus !== CompromiseStatusEnum.Completed
                    && rowData.compromiseStatus !== CompromiseStatusEnum.Deleted))
                    return <div style={{color: redColor}}><b>{dateString}</b></div>
                else return <div>{dateString}</div>
            }
            return <></>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Status,
        field: 'compromiseStatus',
        type: 'string',
        lookup: {0: Strings.NotStarted, 1: Strings.InProgress, 2: Strings.Completed, 3: Strings.Deleted},
        render: (rowData: ICompromiseRowData) => {
            let display = "";
            let color = "";
            switch (rowData.compromiseStatus) {
                case CompromiseStatusEnum.NotStarted:
                    display = Strings.NotStarted
                    color = redColor
                    break;
                case CompromiseStatusEnum.InProgress:
                    display = Strings.InProgress
                    color = blueColor
                    break;
                case CompromiseStatusEnum.Completed:
                    display = Strings.Completed
                    color = greenColor
                    break;
                case CompromiseStatusEnum.Deleted:
                    display = Strings.Deleted
                    color = grayColor
                    break;
            }
            return <div style={{color: color}}><b>{display}</b></div>
        },
        editable: 'always',
    });

    applyFilters(columns, filters)
    return columns;
}


// Get the corresponding table
export function getGeneralCompromiseRowData(compromises: ICompromiseModel[], users: IUserModel[]) {
    let rowData: ICompromiseRowData[] = []
    for (const compromise of compromises.filter(value => value.isGeneralCompromise)) {
        let owner = users.find(value => value.id === compromise.owner.id);
        let creator = users.find(value => value.id === compromise.creator.id);

        if (!owner || !creator)
            continue;

        rowData.push({
            compromiseStatusString: GetCompromiseStatusString(compromise.compromiseStatus),
            compromiseStatus: compromise.compromiseStatus,
            contentHtml: compromise.contentHtml,
            creator: creator,
            dueDate: moment(compromise.dueDate).toDate(),
            creationDate: moment(compromise.creationDate).toDate(),
            creatorEmail: creator.email,
            ownerEmail: owner.email,
            hasDueDate: compromise.hasDueDate,
            id: compromise.id,
            owner: owner,
            percentageCompleted: compromise.percentageCompleted,
            hasPercentageCompleted: compromise.hasPercentageCompleted,
            title: compromise.title,
            hasProject: false, isGeneralCompromise: false, projectCode: "", projectId: "", projectName: "",
        })
    }
    return rowData;
}

export function getUserCompromiseRowData(compromises: ICompromiseModel[], users: IUserModel[], projects: IProjectModel[]) {
    let rowData: ICompromiseRowData[] = []

    for (const compromise of compromises) {
        let owner = users.find(value => value.id === compromise.owner.id);
        let creator = users.find(value => value.id === compromise.creator.id);

        if (!owner || !creator)
            continue;

        let rowDataItem: ICompromiseRowData = {
            projectCode: "",
            projectId: "",
            projectName: "",
            hasProject: false,
            isGeneralCompromise: compromise.isGeneralCompromise,
            compromiseStatusString: GetCompromiseStatusString(compromise.compromiseStatus),
            compromiseStatus: compromise.compromiseStatus,
            contentHtml: compromise.contentHtml,
            creator: creator,
            dueDate: moment(compromise.dueDate).toDate(),
            creationDate: moment(compromise.creationDate).toDate(),
            creatorEmail: creator.email,
            ownerEmail: owner.email,
            hasDueDate: compromise.hasDueDate,
            id: compromise.id,
            owner: owner,
            percentageCompleted: compromise.percentageCompleted,
            hasPercentageCompleted: compromise.hasPercentageCompleted,
            title: compromise.title
        }


        if (compromise.hasProject) {
            let project = projects.find(value => compromise.hasProject && value.id === compromise.project.id)
            rowDataItem.hasProject = true;
            rowDataItem.projectCode = project.code;
            rowDataItem.projectName = project.name;
            rowDataItem.projectId = project.id;
        }
        rowData.push(rowDataItem)
    }
    return rowData;
}

export function getProjectCompromiseRowData(compromises: ICompromiseModel[], users: IUserModel[], projects: IProjectModel[]) {
    let rowData: ICompromiseRowData[] = []

    for (const compromise of compromises.filter(value => value.hasProject)) {
        let owner = users.find(value => value.id === compromise.owner.id);
        let creator = users.find(value => value.id === compromise.creator.id);
        let project = projects.find(value => compromise.hasProject && value.id === compromise.project.id)

        if (!owner || !creator)
            continue;

        rowData.push({
            projectId: project.id,
            hasProject: true,
            isGeneralCompromise: false,
            projectName: project.name,
            projectCode: project.code,
            compromiseStatusString: GetCompromiseStatusString(compromise.compromiseStatus),
            compromiseStatus: compromise.compromiseStatus,
            contentHtml: compromise.contentHtml,
            creator: creator,
            dueDate: moment(compromise.dueDate).toDate(),
            creationDate: moment(compromise.creationDate).toDate(),
            creatorEmail: creator.email,
            ownerEmail: owner.email,
            hasDueDate: compromise.hasDueDate,
            id: compromise.id,
            owner: owner,
            percentageCompleted: compromise.percentageCompleted,
            hasPercentageCompleted: compromise.hasPercentageCompleted,
            title: compromise.title
        })
    }
    return rowData;
}

export function getOwnedCompromisesColumns(filters?: ITableFilter[]) {
    let columns: Column<ICompromiseRowData>[] = [];
    columns.push({
        title: Strings.Title,
        field: 'title',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Creator,
        field: 'creatorEmail',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Project,
        field: 'projectCode',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Created,
        field: 'creationDate',
        dateSetting: {
            format: 'dd/MM/yyyy',
        },
        render: (rowData: ICompromiseRowData) => {
            return <>{getDateString(rowData.creationDate)}</>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Due,
        field: 'dueDate',
        dateSetting: {
            format: 'dd/MM/yyyy',
            locale: 'es-cl'
        },
        render: (rowData: ICompromiseRowData) => {
            let dueDate = rowData.dueDate
            let currentDate = moment().toDate()
            if (rowData.hasDueDate) {
                let dateString = getDateString(dueDate)
                if (currentDate > dueDate && rowData.compromiseStatus !== CompromiseStatusEnum.Completed)
                    return <div style={{color: redColor}}><b>{dateString}</b></div>
                else return <div>{dateString}</div>
            }
            return <></>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Status,
        field: 'compromiseStatus',
        type: 'string',
        lookup: {0: Strings.NotStarted, 1: Strings.InProgress, 2: Strings.Completed, 3: Strings.Deleted},
        render: (rowData: ICompromiseRowData) => {
            let display = "";
            let color = "";
            switch (rowData.compromiseStatus) {
                case CompromiseStatusEnum.NotStarted:
                    display = Strings.NotStarted
                    color = redColor
                    break;
                case CompromiseStatusEnum.InProgress:
                    display = Strings.InProgress
                    color = blueColor
                    break;
                case CompromiseStatusEnum.Completed:
                    display = Strings.Completed
                    color = greenColor
                    break;
                case CompromiseStatusEnum.Deleted:
                    display = Strings.Deleted
                    color = grayColor
                    break;
            }
            return <div style={{color: color}}><b>{display}</b></div>
        },
        editable: 'always',
    });
    applyFilters(columns, filters)
    return columns;
}

export function getCreatedCompromisesColumns(filters?: ITableFilter[]) {
    let columns: Column<ICompromiseRowData>[] = [];
    columns.push({
        title: Strings.Title,
        field: 'title',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Owner,
        field: 'ownerEmail',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Project,
        field: 'projectCode',
        type: 'string',
        editable: 'never'
    });


    columns.push({
        title: Strings.Created,
        field: 'creationDate',
        dateSetting: {
            format: 'dd/MM/yyyy'
        },
        render: (rowData: ICompromiseRowData) => {
            return <>{getDateString(rowData.creationDate)}</>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Due,
        field: 'dueDate',
        dateSetting: {
            format: 'dd/MM/yyyy'
        },
        render: (rowData: ICompromiseRowData) => {
            let dueDate = rowData.dueDate
            let currentDate = moment().toDate()
            if (rowData.hasDueDate) {
                let dateString = getDateString(dueDate)
                if (currentDate > dueDate &&
                    (rowData.compromiseStatus !== CompromiseStatusEnum.Completed &&
                        rowData.compromiseStatus !== CompromiseStatusEnum.Deleted))
                    return <div style={{color: redColor}}><b>{dateString}</b></div>
                else return <div>{dateString}</div>
            }
            return <></>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Status,
        field: 'compromiseStatus',
        type: 'string',
        lookup: {0: Strings.NotStarted, 1: Strings.InProgress, 2: Strings.Completed, 3: Strings.Deleted},
        render: (rowData: ICompromiseRowData) => {
            let display = "";
            let color = "";
            switch (rowData.compromiseStatus) {
                case CompromiseStatusEnum.NotStarted:
                    display = Strings.NotStarted
                    color = redColor
                    break;
                case CompromiseStatusEnum.InProgress:
                    display = Strings.InProgress
                    color = blueColor
                    break;
                case CompromiseStatusEnum.Completed:
                    display = Strings.Completed
                    color = greenColor
                    break;
                case CompromiseStatusEnum.Deleted:
                    display = Strings.Deleted
                    color = grayColor
                    break;
            }
            return <div style={{color: color}}><b>{display}</b></div>
        },
        editable: 'always',
    });
    applyFilters(columns, filters)
    return columns;
}

export function getProjectCompromisesTemplateColumns(filters?: ITableFilter[]) {
    let columns: Column<ICompromiseRowData>[] = [];
    columns.push({
        title: Strings.Title,
        field: 'title',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Owner,
        field: 'ownerEmail',
        type: 'string',
        editable: 'never'
    });

    columns.push({
        title: Strings.Created,
        field: 'creationDate',
        dateSetting: {
            format: 'dd/MM/yyyy'
        },
        render: (rowData: ICompromiseRowData) => {
            return <>{getDateString(rowData.creationDate)}</>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Due,
        field: 'dueDate',
        dateSetting: {
            format: 'dd/MM/yyyy'
        },
        render: (rowData: ICompromiseRowData) => {
            let dueDate = rowData.dueDate
            let currentDate = moment().toDate()
            if (rowData.hasDueDate) {
                let dateString = getDateString(dueDate)
                if (currentDate > dueDate && (rowData.compromiseStatus !== CompromiseStatusEnum.Completed
                    && rowData.compromiseStatus !== CompromiseStatusEnum.Deleted))
                    return <div style={{color: redColor}}><b>{dateString}</b></div>
                else return <div>{dateString}</div>
            }
            return <></>
        },
        type: 'date',
        editable: 'never',
    });

    columns.push({
        title: Strings.Status,
        field: 'compromiseStatus',
        type: 'string',
        lookup: {0: Strings.NotStarted, 1: Strings.InProgress, 2: Strings.Completed, 3: Strings.Deleted},
        render: (rowData: ICompromiseRowData) => {
            let display = "";
            let color = "";
            switch (rowData.compromiseStatus) {
                case CompromiseStatusEnum.NotStarted:
                    display = Strings.NotStarted
                    color = redColor
                    break;
                case CompromiseStatusEnum.InProgress:
                    display = Strings.InProgress
                    color = blueColor
                    break;
                case CompromiseStatusEnum.Completed:
                    display = Strings.Completed
                    color = greenColor
                    break;
                case CompromiseStatusEnum.Deleted:
                    display = Strings.Deleted
                    color = grayColor
                    break;
            }
            return <div style={{color: color}}><b>{display}</b></div>
        },
        editable: 'always',
    });
    applyFilters(columns, filters)
    return columns;
}

function applyFilters(columns: Column<ICompromiseRowData>[], filters?: ITableFilter[]) {
    if (filters)
        for (let filter of filters) {
            let column = columns.find(column => column.field === filter.field)
            column.defaultFilter = filter.filterValue
        }
}

export const userDetailsPanel = (rowData: ICompromiseRowData) => {
    if (rowData) return <>
        {rowData.isGeneralCompromise && <Box m={margin} p={padding}>
            <Typography variant='body1'>{Strings.GeneralCompromise}</Typography>
        </Box>}
        {rowData.hasProject && <Box m={margin} p={padding}>
            <Typography variant='h6'><b>{Strings.Project}</b> {rowData.projectCode} {rowData.projectName}
            </Typography>
        </Box>}
        <ReactQuill value={rowData.contentHtml} readOnly={true}
                    theme='snow' modules={{"toolbar": false}}/>
    </>
    else return null
}

export const projectDetailsPanel = (rowData: ICompromiseRowData) => {
    if (rowData) return <ReactQuill value={rowData.contentHtml} readOnly={true}
                                    theme='snow' modules={{"toolbar": false}}/>
    return null
}

export function generalDetailsPanel(rowData: ICompromiseRowData) {
    if (rowData)
        return <ReactQuill value={rowData.contentHtml} readOnly={true}
                           theme='snow' modules={{"toolbar": false}}/>
    else return null;
}


