import {InteractionRequiredAuthError, PublicClientApplication, SilentRequest} from "@azure/msal-browser";
import {msalConfig} from "../authentication/appConfig";
import Identity from "./Identity";

export class AuthService {
    protected msalConfig: any = msalConfig;
    protected signInOptions: SilentRequest;
    protected msalInstance: PublicClientApplication

    constructor() {
        this.signInOptions = {
            scopes: ['api://12727705-920a-46d1-a931-190121339dcd/Bmining.APi'],
        };
        this.msalInstance = new PublicClientApplication(msalConfig);
    }


    async signIn() {
        let authenticationResult = await this.msalInstance.loginPopup();
        this.msalInstance.setActiveAccount(authenticationResult.account)
        return new Identity(authenticationResult);
    }

    async signOut() {
        await this.msalInstance.logoutPopup();
    }

    async getIdentity() {
        const response = await this.msalInstance.acquireTokenSilent(this.signInOptions).catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return await this.msalInstance.acquireTokenPopup(this.signInOptions);
            } else {
                return null;
            }
        })
        if (response == null)
            return null;
        return new Identity(response);
    }
}

export const authService = new AuthService();