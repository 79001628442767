import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {progress} from "../../redux/progress";
import {loadCompromisesThunk} from "../../redux/compromise";
import {loadUsersThunk} from "../../redux/user";
import {Strings} from "../../Resources/Strings";
import {loadProjectsThunk} from "../../redux/project";
import {Box} from "@material-ui/core";
import {margin, padding} from "../../styles/styleConstants";
import {AddGeneralCompromiseDialogComponent} from "../Dialogs/AddGeneralCompromise/AddGeneralCompromiseDialogComponent";
import {EditCompromiseDialogComponent} from "../Dialogs/EditCompromise/EditCompromiseDialogComponent";
import {
    CompromisesTableComponent,
    CompromiseTableType
} from "../DataTemplates/CompromisesTable/CompromisesTableComponent";


export const UserCompromisesComponent = () => {
    const dispatch = useDispatch()

    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadCompromisesThunk)
        await dispatch(loadUsersThunk)
        await dispatch(loadProjectsThunk)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    return <>
        <Box m={margin} p={padding}>
            <CompromisesTableComponent title={Strings.OwnedCompromises}
                                       tableType={CompromiseTableType.UserOwnedCompromises}/>
        </Box>
        <Box m={margin} p={padding}>
            <CompromisesTableComponent title={Strings.CreatedCompromises}
                                       tableType={CompromiseTableType.UserCreatedCompromises}/>
        </Box>
    </>
}