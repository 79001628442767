import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";
import {securityReflectionService} from "../api/SecurityReflection";
import {progress} from "./progress";
import {genericNotificationThunk, notification} from "./notification";
import {IActivePauseModel} from "../api/IActivePauseModel";
import {activePauseService} from "../api/ActivePauseService";


export interface ActivePauseState {
    activePauses: IActivePauseModel[],
}

export const initialState: ActivePauseState = {
    activePauses: []
}

export const activePause = createSlice({
    name: 'activePause',
    initialState: initialState,
    reducers: {
        setActivePauses(state: ActivePauseState, payload: PayloadAction<IActivePauseModel[]>) {
            state.activePauses = payload.payload;
            return state;
        }
    }
})

export async function loadActivePauseThunk(dispatch: AppDispatch) {
    dispatch(progress.actions.openProgressBar({}))
    let activePauseModels = await activePauseService.getActivePauses();
    dispatch(activePause.actions.setActivePauses(activePauseModels));
    dispatch(progress.actions.closeProgressBar())
}

export async function getActivePauseSampleThunk(dispatch: AppDispatch) {
    dispatch(progress.actions.openProgressBar({}))
    await activePauseService.getActivePauseSampleFile()
    dispatch(progress.actions.closeProgressBar())
}


export function uploadActivePauseFileThunk(base64File: string) {
    return async function uploadActivePauseFile(dispatch: AppDispatch) {
        dispatch(progress.actions.openProgressBar({}))
        let response = await activePauseService.uploadActivePauseFile(base64File)
        let customDispatch = dispatch as any
        if (!response.ok) {
            let message = await response.text()
            await customDispatch(genericNotificationThunk(message))
        } else {
            await customDispatch(loadActivePauseThunk)
        }
        dispatch(progress.actions.closeProgressBar())
    }
}