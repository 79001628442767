import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IReportProjectItem} from "../components/Report/IReportProjectItem";

class ReportService extends BaseService {
    async makeTrackingHoursReport(startDate: Date, endDate: Date, projectsSelected: IReportProjectItem[]) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = `reports/tracking_hours?`;
        let url = `${host}${resource}`;

        url = url + `startDate=${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
        url = url + `&endDate=${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;

        if (projectsSelected.length > 0) {
            projectsSelected.forEach(value => {
                url = url + `&projectIds=${value.id}`;
            });
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.blob()
    }

    async makeActivityRecordReport(activityRecordId: string) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = `reports/activityRecord?id=${activityRecordId}`;
        let url = `${host}${resource}`;


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.blob()
    }
}

export const reportService = new ReportService();

