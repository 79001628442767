import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "./store";
import {Strings} from "../Resources/Strings";

export interface NotificationDialogPayload {
    text: string,
    title: string
}

export interface NotificationDialogState {
    isOpen: boolean
    text?: string
    title?: string
}

const initialState: NotificationDialogState = {
    isOpen: false
}
export const notification = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        openDialog: (state: NotificationDialogState, action: PayloadAction<NotificationDialogPayload>) => {
            state.isOpen = true;
            state.text = action.payload.text;
            state.title = action.payload.title;
            return state;
        },
        closeDialog: (state: NotificationDialogState) => {
            state.isOpen = false;
            state.text = ''
            state.title = ''
            return state;
        }
    },
});

export function genericNotificationThunk(message: string) {
    return async function genericNotification(dispatch: AppDispatch) {
        await dispatch(notification.actions.openDialog({
            title: Strings.Notification,
            text: message
        }))
    }
}


