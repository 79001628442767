import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {margin, padding} from "../../../styles/styleConstants";
import {LoadingButton} from "../../DataTemplates/LoadingButton/LoadingButton";
import {createUserHolidayProfileThunk, userHoliday} from "../../../redux/userHolidays";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";

export const CreateUserHolidayProfileDialogComponent = () => {
    const dispatch = useDispatch();

    const {
        selectedUserForHolidays,
        isCreatingUserHolidayProfile
    } = useSelector((state: RootState) => state.userHolidays);

    const [monthsOfExperienceAtIncorporation, setMonthsOfExperienceAtIncorporation] = useState<number>(null)
    const [rut, setRut] = useState<number>(null)
    const [verificationNumber, setVerificationNumber] = useState<number>(null)
    const [incorporationDate, setIncorporationDate] = useState<Date>(moment().toDate())
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>()

    const [isLoading, setIsLoading] = useState<boolean>()

    const handleNo = () => {
        dispatch(userHoliday.actions.closeCreateUserHolidayProfileDialog());
    }

    useEffect(() => {
        if (monthsOfExperienceAtIncorporation != null && rut != null &&
            verificationNumber != null && incorporationDate != null)
            setIsYesAvailable(true)
        else setIsYesAvailable(false)

    }, [monthsOfExperienceAtIncorporation, rut, verificationNumber, incorporationDate])

    const handleYes = async () => {
        setIsLoading(true)
        let userId = selectedUserForHolidays.id;
        await dispatch(createUserHolidayProfileThunk(userId, incorporationDate,
            monthsOfExperienceAtIncorporation,rut,verificationNumber))
        setIsLoading(false)
    }

    const monthOfExperienceChangedCallback = (event: any | HTMLInputElement) => {
        let text = event.target.value;
        let value = text.replace(/[^0-9]/g, '')
        setMonthsOfExperienceAtIncorporation(value)
    }

    const incorporationDateChangedCallback = (date: Date) => {
        setIncorporationDate(date)
    }

    const rutChangedCallback = (event: any | HTMLInputElement) => {
        let text = event.target.value;
        let value = text.replace(/[^0-9]/g, '')
        setRut(value)
    }

    const verificationNumberChangedCallback = (event: any | HTMLInputElement) => {
        let text = event.target.value;
        let value = text.replace(/[^0-9]/g, '')
        setVerificationNumber(value)
    }

    return <>
        <Dialog open={isCreatingUserHolidayProfile} onClose={handleNo} fullWidth>
            <DialogTitle>
                {Strings.CreateUserHolidayProfile} {selectedUserForHolidays.name} {selectedUserForHolidays.surname}
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.MonthsOfExperienceAtIncorporation}
                                       value={monthsOfExperienceAtIncorporation}
                                       type='numeric'
                                       onChange={monthOfExperienceChangedCallback} fullWidth/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding} sx={{flexDirection: 'row'}}>
                            <Grid container alignItems='center' alignContent='center' justifyContent='center'>
                                <Grid item xs>
                                    <TextField label={Strings.RUT}
                                               value={rut}
                                               type='numeric'
                                               onChange={rutChangedCallback} fullWidth/>
                                </Grid>

                                <Grid item xs='auto'>
                                    <Box m={margin} p={padding}>
                                        <b>-</b>
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField label={Strings.VerificationCode}
                                               value={verificationNumber}
                                               type='numeric'
                                               onChange={verificationNumberChangedCallback} fullWidth/>
                                </Grid>
                            </Grid>


                        </Box>
                    </Grid>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker value={incorporationDate} onChange={incorporationDateChangedCallback}
                                            label={Strings.IncorporationDate}
                                            format="dd/MM/yyyy"/>
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleYes} inProgress={isLoading} text={Strings.Yes}
                               variant='contained' disabled={isLoading || !isYesAvailable} color='primary'
                               progressBarColor='secondary'/>
                <Button onClick={handleNo} color="secondary" variant='contained' disabled={isLoading}>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}