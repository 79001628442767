import React from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ITrackingHoursProjectDialogItem} from "../TrackingHours/TrackingHoursAddProjectDialog";
import {Box, Button, Grid, TextField} from "@material-ui/core";
import {IReportProjectItem} from "../Report/IReportProjectItem";
import {Strings} from "../../Resources/Strings";
import {padding} from "../../styles/styleConstants";

export interface ProjectSelectorTemplateProps {
    onSelected: (project: IReportProjectItem) => void,
    projectItems: IReportProjectItem[],
    disabled?:boolean
}

export const ProjectSelectorTemplate: React.FC<ProjectSelectorTemplateProps> = ({onSelected, projectItems}) => {

    const [selectedProject, setSelectedProject] = React.useState<IReportProjectItem>();

    return <>
        <Grid container direction='row' alignItems='center'>
            <Grid item xs>
                <Box p={padding}>
                    <Autocomplete value={selectedProject}
                                  onChange={(event: any, newValue: ITrackingHoursProjectDialogItem | null) => {
                                      setSelectedProject(newValue);
                                  }}
                                  id="autocomplete"
                                  options={projectItems}
                                  getOptionLabel={(option) => option.projectDisplay}
                                  renderInput={(params) =>
                                      <TextField {...params} variant="outlined"/>}/>
                </Box>
            </Grid>

            <Grid item xs='auto'>
                <Box p={1}>
                    <Button
                        disabled={(selectedProject === null || selectedProject === undefined)}
                        variant="contained" color="primary" fullWidth onClick={() => {
                        if (selectedProject === null || selectedProject === undefined)
                            return;
                        onSelected(selectedProject)
                    }}>{Strings.Add}</Button>
                </Box>
            </Grid>
        </Grid>
    </>
}