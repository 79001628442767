import React, {useEffect, useState, MutableRefObject,useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Strings} from "../../Resources/Strings";
import {getDateFromString, getDateString} from "../../utils/utils";
import {progress} from "../../redux/progress";
import { IUserHolidayRegisterDisplayModel } from '../../api/IUserHolidayRegisterDisplayModel';
import { loadUsersHolidayRegisterDisplayThunk } from '../../redux/userHolidays';
import MaterialTable, {Column} from "material-table";
import ReactQuill from "react-quill";

export interface ITableFilter {
    field: string,
    filterValue: any
}

export const UsersOnHolidaysComponent = () => {
    const dispatch = useDispatch();
    const [filters, setFilters] = useState<ITableFilter[]>()
    const tableRef = useRef<MutableRefObject<any>>()
    const {usersHolidayRegister} = useSelector((state: RootState) => state.userHolidays);
    const [usersHolidayRegisterDataTable, setUsersHolidayRegisterDataTable] = useState<IUserHolidayRegisterDisplayModel[]>([])    
    const [usersHolidayRegisterColumns, setUsersHolidayRegisterColumns] = useState<Column<IUserHolidayRegisterDisplayModel>[]>([])
    const [detailsPanel, setDetailsPanel] = useState<any>(generalDetailsPanel);
    const {isOpen} = useSelector((state: RootState) => state.progress);

    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadUsersHolidayRegisterDisplayThunk)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        setFilters(getFilters(tableRef))
        if (usersHolidayRegister) {
            let rowData = getUsersHolidayRegisterDisplayRowData()
            setUsersHolidayRegisterDataTable([...rowData])
        }
    }, [usersHolidayRegister])

    function getUsersHolidayRegisterDisplayRowData() {
        let rowData: IUserHolidayRegisterDisplayModel[] = [];
        for (const userHoliday of usersHolidayRegister) {
            rowData.push({
                userName: capitalizeFirstLetter(userHoliday.userName),
                userSurname: capitalizeFirstLetter(userHoliday.userSurname),
                fromDate: getDateString(getDateFromString(userHoliday.fromDate)),
                toDate: getDateString(getDateFromString(userHoliday.toDate))   
            })
        }
        return rowData;           
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      

    function getFilters(tableRef: MutableRefObject<any>) {
        let newFilters: ITableFilter[] = tableRef?.current?.state.columns
            .filter(value => value.tableData.filterValue).map((column: any) => ({
                field: column.field,
                filterValue: column.tableData.filterValue
            }));
        return newFilters;
    }

    useEffect(() => {
        setUsersHolidayRegisterColumns([...getUsersHolidayRegisterTemplateColumns(filters)])
        setUsersHolidayRegisterDataTable([...usersHolidayRegisterDataTable])
    }, [filters])

    function getUsersHolidayRegisterTemplateColumns(filters?: ITableFilter[]) {
        let columns: Column<IUserHolidayRegisterDisplayModel>[] = [];
        columns.push({
            title: Strings.Name,
            field: 'userName',
            editable: 'never'
        });    
        columns.push({
            title: Strings.Surname,
            field: 'userSurname',
            editable: 'never'
        });
        columns.push({
            title: Strings.From,
            field: 'fromDate',
            editable: 'never'            
        });
        columns.push({
            title: Strings.To, 
            field: 'toDate',
            editable: 'never'
        })
        applyFilters(columns, filters)
        return columns;
    }

    function applyFilters(columns: Column<IUserHolidayRegisterDisplayModel>[], filters?: ITableFilter[]) {
        if (filters)
            for (let filter of filters) {
                let column = columns.find(column => column.field === filter.field)
                column.defaultFilter = filter.filterValue
            }
    } 
    
    function generalDetailsPanel(rowData: IUserHolidayRegisterDisplayModel) {
        if (rowData)
            return <ReactQuill value={null} readOnly={true}
                               theme='snow' modules={{"toolbar": false}}/>
        else return null;
    }

    useEffect(() => {
        setDetailsPanel(generalDetailsPanel)        
    }, [])

    return <>
        <MaterialTable 
            tableRef={tableRef}
            title={Strings.OnHolidaysAndSoon}
            isLoading={isOpen}
            columns={usersHolidayRegisterColumns}
            data={usersHolidayRegisterDataTable}
            detailPanel={detailsPanel}
            options={{
                actionsColumnIndex: -1,
                filtering: true,
                paging: true,
                pageSize: 5,
            }}
        /> 
    </>      
}