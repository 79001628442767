import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import React, {useEffect, useState} from "react";
import {loadUsersThunk} from "../../redux/user";
import {
    acceptHoursThunk,
    loadUserHolidaysThunk, rejectUserHolidayRegisterThunk,
} from "../../redux/userHolidays";
import {progress} from "../../redux/progress";
import {IUserModel} from "../../api/IUserModel";
import {IUserHolidayModel} from "../../api/IUserHolidayModel";
import {getDateFromString, getDateString} from "../../utils/utils";
import {getHolidayTypeString} from "../../api/HolidayTypeEnum";
import MaterialTable, {Column} from "material-table";
import {Strings} from "../../Resources/Strings";
import {Cancel, CancelOutlined, Check, CheckCircleOutline, Delete} from "@material-ui/icons";

interface IAcceptHolidayRowData {
    userHolidayRegisterId: string,
    email: string,
    fromDate: string,
    toDate: string,
    daysUsed: number,
    availableDays: number,
    holidayTypeString: string
    comment: string
}


export const AcceptHolidayComponent = () => {
    const dispatch = useDispatch()

    const {identity} = useSelector((state: RootState) => state.authentication)
    const {users} = useSelector((state: RootState) => state.user)
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {userHolidays} = useSelector((state: RootState) => state.userHolidays)
    const [acceptHolidayRowData, setAcceptHolidayRowData] = useState<IAcceptHolidayRowData[]>([])

    async function loadData() {
        await dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadUsersThunk)
        await dispatch(loadUserHolidaysThunk)
        await dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (users && userHolidays) {
            let data = [...getData(identity.credentials.id, users, userHolidays)]
            setAcceptHolidayRowData([...data])
        }
    }, [users, userHolidays])

    return <>
        {!isOpen && <MaterialTable columns={getColumns()} title={Strings.PendingHolidays}
                                   isLoading={isOpen} data={acceptHolidayRowData}
                                   options={{paging: false, filtering: true, actionsColumnIndex: -1}}
                                   actions={[
                                       () => ({
                                           icon: CheckCircleOutline,
                                           tooltip: Strings.Accept,
                                           onClick: async (event, rowData: IAcceptHolidayRowData) => {
                                               dispatch(acceptHoursThunk(rowData.userHolidayRegisterId))
                                           },
                                       }), () => ({
                                           icon: CancelOutlined,
                                           tooltip: Strings.Reject,
                                           onClick: async (event, rowData: IAcceptHolidayRowData) => {
                                               dispatch(rejectUserHolidayRegisterThunk(rowData.userHolidayRegisterId))
                                           },
                                       })]}
        />}
    </>
}


function getColumns(): Column<IAcceptHolidayRowData>[] {
    let columns: Column<IAcceptHolidayRowData>[] = []

    columns.push({
        title: Strings.Email,
        editable: 'never',
        field: 'email'
    })

    columns.push({
        title: Strings.From,
        editable: 'never',
        field: 'fromDate',
    })

    columns.push({
        title: Strings.To,
        editable: 'never',
        field: 'toDate'
    })

    columns.push({
        title: Strings.DaysRequested,
        editable: 'never',
        field: 'daysUsed'
    })

    columns.push({
        title: Strings.Type,
        editable: 'never',
        field: 'holidayTypeString'
    })

    columns.push({
        title: Strings.Comments,
        editable: 'never',
        field: 'comment'
    })

    columns.push({
        title: Strings.AvailableDays,
        editable: 'never',
        field: 'availableDays'
    })
    return columns
}

function getData(approvingUserId: string, users: IUserModel[], userHolidays: IUserHolidayModel[]): IAcceptHolidayRowData[] {

    let rowsData: IAcceptHolidayRowData[] = [];
    for (const userHoliday of userHolidays) {
        for (const userHolidayRegister of userHoliday.userHolidayRegisters) {
            if (userHolidayRegister.isAccepted) continue;
            if (userHolidayRegister.approvingUser.id !== approvingUserId) continue;

            let user = users.find(value => value.id === userHoliday.user.id)
            rowsData.push({
                email: user.email,
                holidayTypeString: getHolidayTypeString(userHolidayRegister.holidayType),
                fromDate: getDateString(getDateFromString(userHolidayRegister.fromDate)),
                toDate: getDateString(getDateFromString(userHolidayRegister.toDate)),
                userHolidayRegisterId: userHolidayRegister.id,
                comment: userHolidayRegister.comment,
                availableDays: +userHoliday.availableDays.toFixed(2),
                daysUsed: +userHolidayRegister.daysUsed.toFixed(2)
            })
        }
    }
    return rowsData;
}