import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {progress} from "../../redux/progress";
import {loadCompromisesThunk} from "../../redux/compromise";
import {ActivityRecordTableComponent} from "../DataTemplates/ActivityRecordTable/ActivityRecordTableComponent";
import {loadUsersThunk} from "../../redux/user";
import {loadProjectsThunk} from "../../redux/project";
import {loadExternalUsersThunk} from "../../redux/externalUser";

export const ActivityRecordByProjectComponent = () => {

    const dispatch = useDispatch()
    const {selectedProject, projects} = useSelector((state: RootState) => state.project)
    const {externalUsers} = useSelector((state: RootState) => state.externalUser)
    const {activityRecords} = useSelector((state: RootState) => state.activityRecord)
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {users} = useSelector((state: RootState) => state.user)

    const validActivityRecords = activityRecords.filter(value => value.project.id === selectedProject.id)
        .map(value => ({...value}))

    useEffect(() => {
        async function loadData() {
            await dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadCompromisesThunk)
            await dispatch(loadUsersThunk)
            await dispatch(loadExternalUsersThunk)
            await dispatch(loadProjectsThunk)
            await dispatch(progress.actions.closeProgressBar())
        }

        // noinspection JSIgnoredPromiseFromCall
        loadData()
    }, [selectedProject])

    return <>
        {!isOpen && <>
            <ActivityRecordTableComponent activityRecords={validActivityRecords} projects={projects}
                                          externalUsers={externalUsers} selectedProject={selectedProject}
                                          users={users}/>
        </>}
    </>
}