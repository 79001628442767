import {Strings} from "../Resources/Strings";

export enum RequestTypeEnum {
    Add = 0,
    Remove = 1,
    Change = 2
}

export function getRequestTypeString(accountStatus: RequestTypeEnum) {
    switch (accountStatus) {
        case RequestTypeEnum.Add:
            return Strings.Add
        case RequestTypeEnum.Remove:
            return Strings.Remove
        case RequestTypeEnum.Change:
            return Strings.Change
    }
}

export function getRequestType(accountStatusString: string) {
    switch (accountStatusString) {
        case Strings.Add:
            return RequestTypeEnum.Add
        case Strings.Remove:
            return RequestTypeEnum.Remove
        case Strings.Change:
            return RequestTypeEnum.Change
    }
}