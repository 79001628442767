import React from 'react'
import {NotificationDialogComponent} from "../Notification/NotificationDialogComponent";
import {useDispatch, useSelector} from "react-redux";
import {notification} from "../../../redux/notification";
import {AppDispatch, RootState} from "../../../redux/store";
import {YesNoCancelDialogComponent} from "../YesNoCancel/YesNoCancelDialogComponent";

export const MainDialogsComponent = () => {
    const dispatch = useDispatch<AppDispatch>()
    const {isOpen, text, title} = useSelector((state: RootState) => state.notification)
    const {selectedActivityRecord} = useSelector((state: RootState) => state.activityRecord)

    function closeCallback() {
        dispatch(notification.actions.closeDialog())
    }

    return <>
        <NotificationDialogComponent isOpen={isOpen} title={title} text={text}
                                     onClose={closeCallback}/>
        <YesNoCancelDialogComponent/>
    </>
}




