import React, {ChangeEvent} from 'react'
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {useDispatch,useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {IUserModel} from "../../api/IUserModel";
import {MutableRefObject, useEffect, useRef, useState} from 'react';
import MaterialTable, {Column} from "material-table";
import ReactQuill from "react-quill";
import {Delete} from "@material-ui/icons";
import { progress } from '../../redux/progress';
import {loadUsersThunk} from "../../redux/user";
import { loadSecondaryApproversThunk, addSecondaryApproverThunk,removeSecondaryApproverThunk } from '../../redux/SecondaryApprover';

const margin = 1;
const padding = 1;
const elevation = 1;

export interface ITableFilter {
    field: string,
    filterValue: any
}

export interface ISecondaryApproverRow {
    id: string,    
    onbehalfOfEmail: string,
    onbehalfOf: IUserModel,
    managerEmail: string,
    manager: IUserModel
}

export const SecondaryApproverAdminComponent = () => {
    const dispatch = useDispatch();
    const {users} = useSelector((state: RootState) => state.user);
    const {secondaryApprovers} = useSelector((state: RootState) => state.secondaryApprover);
    const [canCreate, setCanCreate] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState< IUserModel>(null);
    const [selectedManager, setSelectedManager] = React.useState<IUserModel>(null);
    const {isOpen} = useSelector((state: RootState) => state.progress);
    const [secondaryApproversDataTable, setSecondaryApproverDataTable] = useState<ISecondaryApproverRow[]>([])    
    const [secondaryApproversColumns, setSecondaryApproversColumns] = useState<Column<ISecondaryApproverRow>[]>([])
    const [detailsPanel, setDetailsPanel] = useState<any>(generalDetailsPanel);
    const [filters, setFilters] = useState<ITableFilter[]>()
    const tableRef = useRef<MutableRefObject<any>>()
   
    async function loadData() {
        dispatch(progress.actions.openProgressBar({}))
        await dispatch(loadUsersThunk)
        await dispatch(loadSecondaryApproversThunk)
        dispatch(progress.actions.closeProgressBar())
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        setFilters(getFilters(tableRef))
        if (secondaryApprovers && users) {
            let rowData = getSecondaryApproverRowData()
            setSecondaryApproverDataTable([...rowData])
        }
    }, [secondaryApprovers, users])
   
    //Can or not create
    React.useEffect(() => {
        if (selectedUser !== null && selectedManager !== null)
            setCanCreate(true);
        else
            setCanCreate(false);
    }, [selectedUser, selectedManager])

    //Get rows
    function getSecondaryApproverRowData() {
        let rowData: ISecondaryApproverRow[] = [];
        let onBehalfOf;
        let manager;
        for (const seconadryApprover of secondaryApprovers) {
            onBehalfOf = users.find(value => value.id === seconadryApprover.onBehalfOfId.id);
            manager = users.find(value => value.id === seconadryApprover.toApproveUserId.id);
    
            if (!onBehalfOf || !manager)
                continue;
            rowData.push({
                id: seconadryApprover.id,
                onbehalfOf: onBehalfOf,
                onbehalfOfEmail: onBehalfOf.email,
                manager: manager,
                managerEmail: manager.email,            
            })
        }
        return rowData;           
    }

    useEffect(() => {
        setSecondaryApproversColumns([...getSecondaryApproverTemplateColumns(filters)])
        setSecondaryApproverDataTable([...secondaryApproversDataTable])
    }, [filters])

    //get columns
    function getSecondaryApproverTemplateColumns(filters?: ITableFilter[]) {
        let columns: Column<ISecondaryApproverRow>[] = [];
        columns.push({
            title: Strings.ProjectManager,
            field: 'managerEmail',
            type: 'string',
            editable: 'never'
        });
    
        columns.push({
            title: Strings.SecondaryApprover,
            field: 'onbehalfOfEmail',
            type: 'string',
            editable: 'never'
        });
        applyFilters(columns, filters)
        return columns;
    }
    
    function applyFilters(columns: Column<ISecondaryApproverRow>[], filters?: ITableFilter[]) {
        if (filters)
            for (let filter of filters) {
                let column = columns.find(column => column.field === filter.field)
                column.defaultFilter = filter.filterValue
            }
    }    
    
    function getFilters(tableRef: MutableRefObject<any>) {
        let newFilters: ITableFilter[] = tableRef?.current?.state.columns
            .filter(value => value.tableData.filterValue).map((column: any) => ({
                field: column.field,
                filterValue: column.tableData.filterValue
            }));
        return newFilters;
    }
    
    function generalDetailsPanel(rowData: ISecondaryApproverRow) {
        if (rowData)
            return <ReactQuill value={null} readOnly={true}
                               theme='snow' modules={{"toolbar": false}}/>
        else return null;
    }

    useEffect(() => {
        setDetailsPanel(generalDetailsPanel)        
    }, [])
    
    return <>
        <Box p={padding} m={margin}>
            <Paper elevation={elevation}>
                <Box m={margin} p={padding}>
                    <Typography variant='h6'>{Strings.AddSecondaryApprover}</Typography>
                </Box>
                <Grid container direction='row' alignContent='center' alignItems='center'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Autocomplete
                                value={selectedManager}
                                onChange={(event: any, newValue: IUserModel | null) => {
                                    setSelectedManager(newValue);
                                }}
                                id="combo-box-demo"
                                options={users}
                                getOptionLabel={(option) => option.email}
                                renderInput={(params) =>
                                    <TextField {...params} label={Strings.ManagerEmail} variant="outlined"/>}
                            />
                        </Box>
                    </Grid>                   
                    <Grid item xs>
                            <Box m={margin} p={padding}>
                                <Autocomplete
                                    value={selectedUser}
                                    onChange={(event: any, newValue: IUserModel | null) => {
                                        setSelectedUser(newValue);
                                    }}
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.email}
                                    renderInput={(params) =>
                                        <TextField {...params} label={Strings.Replacement} variant="outlined"/>}
                                />
                            </Box>
                    </Grid>                    
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button variant='contained' 
                                onClick ={ async () => {                                
                                    await dispatch(addSecondaryApproverThunk(selectedUser.email,selectedManager.email))
                                    setSelectedUser(null)
                                    setSelectedManager(null)
                                }}
                                disabled={!canCreate}>{Strings.Add}
                            </Button>
                        </Box>
                    </Grid>
                </Grid> 
            </Paper>
        </Box>
    
        <Box p={padding} m={margin}>        
            <MaterialTable 
                tableRef={tableRef}
                title={Strings.SecondaryApprovers}
                isLoading={isOpen}
                columns={secondaryApproversColumns}
                data={secondaryApproversDataTable}
                detailPanel={detailsPanel}
                options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    paging: true,
                    pageSize: 10,
                }}
                actions={[ {
                    icon: Delete,
                    tooltip: `${Strings.Delete}`,
                    onClick: async (event, rowData: ISecondaryApproverRow) => {
                        let secondaryApproverItem = secondaryApprovers.find(s => s.id === rowData.id)
                        await dispatch(removeSecondaryApproverThunk(secondaryApproverItem))                  
                    }
                }]}
            /> 
        </Box>
    </>      
}

