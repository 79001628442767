import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IUserHolidayModel} from "./IUserHolidayModel";
import {getQueryDate} from "../utils/utils";
import {IUserHolidayAvailableDaysModel} from "./IUserHolidayAvailableDaysModel";
import {IUserHolidayRegisterModel} from "./IUserHolidayRegisterModel";
import {HolidayTypeEnum} from "./HolidayTypeEnum";
import {AddUserHolidayRegisterInput} from "./AddUserHolidayRegisterInput";
import {IUserModel} from "./IUserModel";
import {IUserHolidayRegisterDisplayModel} from "./IUserHolidayRegisterDisplayModel";

class UserHolidayService extends BaseService {
    async getUsersHolidays() {
        let token = await this.getToken();
        // Get the user holiday
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${apiUrl}userHoliday/admin`, requestOptions);
        let userHolidayModels = await response.json() as IUserHolidayModel[];

        // Get the user available days register
        response = await fetch(`${apiUrl}userHoliday/admin/availableDays`, requestOptions);
        let userHolidayAvailableDaysModels = await response.json() as IUserHolidayAvailableDaysModel[];
        for (let userHolidayModel of userHolidayModels) {
            let model = userHolidayAvailableDaysModels.find(value => value.userHoliday.id === userHolidayModel.id)
            if (model) {
                userHolidayModel.availableDays = model.availableDays
                userHolidayModel.progressiveDays = model.progressiveDays
                userHolidayModel.totalHolidayDaysFromBeginning = model.totalHolidayDaysFromBeginning
            }
        }

        // Get the user holiday records
        response = await fetch(`${apiUrl}userHolidayRegister/admin`, requestOptions);
        let userHolidayRegisterModels = await response.json() as IUserHolidayRegisterModel[];
        for (let userHolidayModel of userHolidayModels) {
            let models = userHolidayRegisterModels.filter(value => value.userHoliday.id === userHolidayModel.id)
            if (models) {
                userHolidayModel.userHolidayRegisters = models
            } else {
                userHolidayModel.userHolidayRegisters = []
            }
        }
        return userHolidayModels;
    }

    async createUserHolidayProfile(userId: string, incorporationDate: Date, monthsOfExperienceAtIncorporation: number,
                                   rut: number, verificationNumber) {
        let url = `${apiUrl}userHoliday/admin?`
        url += `userId=${userId}`
        url += `&incorporationDate=${getQueryDate(incorporationDate)}`
        url += `&monthsOfExperienceAtIncorporation=${monthsOfExperienceAtIncorporation}`
        url += `&rut=${rut}`
        url += `&verificationNumber=${verificationNumber}`

        let token = await this.getToken();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayModel;
    }


    async addHolidays(userId: string, fromDate: Date, toDate: Date, usedDays: number,
                      comment: string, accepted: boolean, holidayType: HolidayTypeEnum, approveUserId: string) {

        let url = ""
        if (accepted)
            url = `${apiUrl}userHolidayRegister/admin`
        else
            url = `${apiUrl}userHolidayRegister`

        let newFromDate =  new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000)
        let newToDate =  new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000)

        let model: AddUserHolidayRegisterInput = {
            userId: userId,
            fromDate: getQueryDate(newFromDate),
            toDate: getQueryDate(newToDate),
            daysUsed: usedDays,
            comment: comment,
            holidayType: holidayType,
            isAccepted: true,
            approvingUserId: approveUserId
        }

        let token = await this.getToken();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(model)
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayModel;
    }

    async hasUserHoliday() {
        let url = `${apiUrl}userHoliday/hasUserHoliday`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as boolean;
    }

    async getUserHoliday() {
        // Get base model
        let url = `${apiUrl}userHoliday`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let model = await response.json() as IUserHolidayModel;


        let holidayAvailableDaysModel = await this.getUserAvailableDays();
        model.availableDays = holidayAvailableDaysModel.availableDays;
        model.totalHolidayDaysFromBeginning = holidayAvailableDaysModel.totalHolidayDaysFromBeginning;
        model.progressiveDays = holidayAvailableDaysModel.progressiveDays;

        model.userHolidayRegisters = await this.getUserHolidayRegister();
        return model;
    }

    async getUserAvailableDays() {
        let url = `${apiUrl}userHoliday/availableDays`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayAvailableDaysModel;
    }

    async getUserHolidayRegister() {
        let url = `${apiUrl}userHolidayRegister`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayRegisterModel[];
    }

    async acceptUserHolidayRegister(userHolidayRegisterId: string) {
        let url = `${apiUrl}userHolidayRegister/admin/status`
        url += `?id=${userHolidayRegisterId}&status=true`
        let token = await this.getToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayRegisterModel;
    }

    async rejectUserHolidayRegister(userHolidayRegisterId: string) {
        let url = `${apiUrl}userHolidayRegister/admin/status`
        url += `?id=${userHolidayRegisterId}&status=false`
        let token = await this.getToken();
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayRegisterModel;
    }

    async getAvailableApprovingUsers() {
        let url = `${apiUrl}userHoliday/availableApprovingUsers`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserModel[];
    }

    async getUsersHolidayRegisterDisplay(){
        let url = `${apiUrl}UserHolidayRegister/usersOnHolidays?accepted=true`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayRegisterDisplayModel[]
    }


    async downloadUserHolidayRegisterDocument(userHolidayRegisterId: string) {
        let url = `${apiUrl}userHolidayRegisterDocument/document?userHolidayRegisterId=${userHolidayRegisterId}`
        let token = await this.getToken();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        let blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `vacaciones.pdf`;
        link.click();
    }

    async deleteUserHolidayRegister(userHolidayRegisterId: string) {
        let url = `${apiUrl}userHolidayRegister/admin?id=${userHolidayRegisterId}`
        let token = await this.getToken();
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`${url}`, requestOptions);
        return await response.json() as IUserHolidayRegisterModel;
    }    
}


export const userHolidayService = new UserHolidayService();