import React, {FC, useEffect, useState} from 'react'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {margin, padding} from "../../../styles/styleConstants";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {activityRecord, addActivityRecordThunk} from "../../../redux/activityRecord";
import {IProjectModel} from "../../../api/IProjectModel";
import {isEmpty} from "../../../utils/utils";
import {LoadingButton} from "../../DataTemplates/LoadingButton/LoadingButton";


export interface AddActivityRecordDialogComponentProps {
    selectedProject?: IProjectModel,
    projects: IProjectModel[]
}


export const AddActivityRecordDialogComponent: FC<AddActivityRecordDialogComponentProps> = (props) => {
    const dispatch = useDispatch();
    const {isAddingActivityRecord} = useSelector((state: RootState) => state.activityRecord);
    const projectTable = props.projects.map(value => ({...value}));

    const [title, setTitle] = useState<string>('');
    const [currentSelectedProject, setSelectedProject] = useState<IProjectModel>();
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    useEffect(() => {
        if (props.selectedProject)
            setSelectedProject(props.selectedProject)
    }, [props.selectedProject])

    useEffect(() => {
        if (isEmpty(title) || currentSelectedProject === undefined || currentSelectedProject === null)
            setIsYesAvailable(false)
        else setIsYesAvailable(true)
    }, [title, currentSelectedProject]);

    const handleNo = () => {
        dispatch(activityRecord.actions.closeAddActivityRecord());
    }

    const handleYes = async () => {
        setIsLoading(true)
        await dispatch(addActivityRecordThunk(title, currentSelectedProject.id))
        setIsLoading(false)
    }

    const titleChangedCallback = (event: any | HTMLInputElement) => {
        setTitle(event.target.value)
    }

    return <>
        <Dialog open={isAddingActivityRecord} onClose={handleNo} fullWidth>
            <DialogTitle>{Strings.NewActivityRecord}</DialogTitle>
            <DialogContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <TextField label={Strings.Title} onChange={titleChangedCallback} fullWidth/>
                        </Box>
                    </Grid>
                    {!props.selectedProject &&
                    <Grid item>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          value={currentSelectedProject}
                                          onChange={(event: any, newValue: IProjectModel | null) => {
                                              setSelectedProject(newValue)
                                          }}
                                          id="project-autocomplete"
                                          options={projectTable}
                                          getOptionLabel={(option) => `${option.code} ${option.name}`}
                                          renderInput={(params) =>
                                              <TextField {...params} label={Strings.Project} variant="outlined"/>}/>
                        </Box>
                    </Grid>}
                </Grid>

            </DialogContent>
            <DialogActions>
                <LoadingButton inProgress={isLoading} text={Strings.Yes} disabled={!isYesAvailable} color="primary"
                               progressBarColor='secondary' variant='contained' onClick={handleYes}/>
                <Button onClick={handleNo} color="secondary" variant='contained'>
                    {Strings.No}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}