import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface YesNoCancelDialogPayload {
    text: string,
    title: string,
    actionOnYes: () => void
    onFinally?: () => void;
}

export interface YesNoCancelDialogState {
    isOpen: boolean,
    actionOnYes: () => void
    onFinally?: () => void
    text?: string
    title?: string
}


const initialState: YesNoCancelDialogState = {
    isOpen: false,
    actionOnYes: () => {
    },
}
export const question = createSlice({
    name: "question",
    initialState: initialState,
    reducers: {
        openDialog: (state: YesNoCancelDialogState, action: PayloadAction<YesNoCancelDialogPayload>) => {
            state.isOpen = true;
            state.text = action.payload.text;
            state.title = action.payload.title;
            state.actionOnYes = action.payload.actionOnYes;
            state.onFinally = action.payload.onFinally;
            return state;
        },
        closeDialog: (state: YesNoCancelDialogState) => {
            state.isOpen = false;
            state.text = ''
            state.title = ''
            state.actionOnYes = () => {
            };
            state.onFinally = () => {

            };
            return state;
        }
    },
});