import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import MaterialTable from "material-table";
import ITrackingHoursRowData from "../../utils/ITrackingHoursRowData";
import {Box, Button, ButtonGroup, Divider, Grid, IconButton, Typography} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ArrowBack, ArrowForward, Help} from "@material-ui/icons";
import {ITrackingHoursProjectDialogItem, TrackingHoursAddProjectDialog} from "./TrackingHoursAddProjectDialog";
import {Credentials} from "../../api/Credentials";
import {TimeTrackingTypeEnum} from "../../api/TimeTrackingTypeEnum";
import {
    deleteRow,
    getCodeProjectName,
    getColumns,
    getDateFromSaturdayToFriday,
    getHoursPerDay,
    getTemplateColumns,
    getTimeTrackingTypeDisplay,
    getTrackingHoursRow,
    getTrackingModel,
    getWeekNumber,
    updateHeaders,
    updateTrackingHours
} from "../../utils/utils";
import {ISendHoursModel} from "../../api/ISendHoursModel";
import {TrackingHoursStateEnum} from "../../api/TrackingHoursStateEnum";
import {ITrackingHoursModel} from "../../api/ITrackingHoursModel";
import {trackingHoursConfirmationMessage} from "./TrackingHoursConstants";
import {List} from "linq-typescript";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux/store";
import {notification} from "../../redux/notification";
import {Strings} from "../../Resources/Strings";
import {useHistory, useParams} from "react-router";
import {progress} from "../../redux/progress";
import {IRemoveTrackingHours} from "../../api/IRemoveTrackingHours";
import {ICreateTrackingHours} from "../../api/ICreateTrackingHours";
import moment from "moment";
import {margin, padding} from "../../styles/styleConstants";
import {trackingHoursService} from "../../api/TrackingHoursService";
import {usersService} from "../../api/UsersService";
import {projectService} from "../../api/ProjectsService";

interface RouterParams {
    year?: string,
    month?: string,
    day?: string
}

export const TrackingHoursComponent = () => {
    // Dispatch item
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();

    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {identity} = useSelector((state: RootState) => state.authentication)
    const {day, month, year} = useParams<RouterParams>()

    // Add project dialog
    const [openDialogBox, setOpenDialogBox] = React.useState(false);
    const [isAddingOrdinaryProject, setIsAddingOrdinaryProject] = React.useState(true);

    const [credentials] = React.useState(identity.credentials);

    const [userId, setUserId] = React.useState<string>();
    const [currentDate, setCurrentDate] = React.useState<Date>(new Date());
    const [startDate, setStartDate] = React.useState<Date>(new Date());
    const [endDate, setEndDate] = React.useState<Date>(new Date());
    const [weekOfYear, setWeekOfYear] = React.useState<number>(0);
    const [columnsTable] = React.useState(getTemplateColumns);
    const [ordinaryHoursTable, setOrdinaryHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [ordinaryHoursColumns, setOrdinaryHoursColumns] = React.useState(getTemplateColumns);
    const [extraordinaryHoursTable, setExtraOrdinaryHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [waitingForApprovalHoursTable, setWaitingForApprovalHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [approvedHoursTable, setApprovedHoursTable] = React.useState([] as ITrackingHoursRowData[])
    const [hoursHasBeenSent, setHoursHasBeenSent] = React.useState(false);

    React.useEffect(() => {
        dispatch(progress.actions.openProgressBar({}));
        usersService.getUserByEmail(credentials.email).then(user => setUserId(user.id)).finally(() => {
            if (day !== undefined && month !== undefined && year !== undefined) {
                handleDateChange(new Date(+year, +month, +day))
            } else {
                handleDateChange(currentDate);
            }
            dispatch(progress.actions.closeProgressBar());
        });
    }, []);

    const handleDateChange = (date: Date) => {
        dispatch(progress.actions.openProgressBar({}));
        let [, weekOfYear] = getWeekNumber(date);
        const pivotDay = 6;
        let delta = 0;
        let newStartDate = new Date(date);
        newStartDate = new Date(Date.UTC(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate()))
        let newEndDate = new Date(newStartDate);
        if (newStartDate.getUTCDay() !== pivotDay)
            delta = newStartDate.getUTCDay() + 1;
        newStartDate.setUTCDate(newStartDate.getUTCDate() - delta)
        newEndDate.setUTCDate(newEndDate.getUTCDate() - delta + 6)
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setCurrentDate(date);
        setWeekOfYear(weekOfYear);
        getTrackingHours(credentials, newStartDate, newEndDate)
            .then(tables => {
                // Update ordinary table
                setOrdinaryHoursTable(tables.newOrdinaryDataTable);
                setOrdinaryHoursColumns(updateHeaders(tables.newOrdinaryDataTable, ordinaryHoursColumns));
                setExtraOrdinaryHoursTable(tables.newExtraOrdinaryDataTable);
                setApprovedHoursTable(tables.newApprovedDataTable);
                setWaitingForApprovalHoursTable(tables.newWaitingForApprovalDataTable);
                dispatch(progress.actions.closeProgressBar())
                setHoursHasBeenSent(tables.hoursHasBeenSent);
            }).catch(reason => {
            dispatch(notification.actions.openDialog({title: Strings.Notification, text: reason}))
        });

    }

    const bulkUpdateOrdinaryCallback = (changes: Record<number, {
        oldData: ITrackingHoursRowData;
        newData: ITrackingHoursRowData
    }>) => {
        return updateTrackingHours(changes, TimeTrackingTypeEnum.Ordinary, credentials, ordinaryHoursTable)
            .then(() => {
                handleDateChange(currentDate);
            });
    };

    const bulkUpdateExtraordinaryCallback = (changes: Record<number, {
        oldData: ITrackingHoursRowData;
        newData: ITrackingHoursRowData
    }>) => {
        return updateTrackingHours(changes, TimeTrackingTypeEnum.Extraordinary,
            credentials, extraordinaryHoursTable).then(() => {
            handleDateChange(currentDate);
        });
    };

    function setPreviousWeekCallback() {
        let date = new Date(currentDate);
        date.setDate(date.getDate() - 7);
        handleDateChange(date);
    }

    function setNextWeekCallback() {
        let date = new Date(currentDate);
        date.setDate(date.getDate() + 7);
        handleDateChange(date);
    }

    function sendHoursCallback() {
        dispatch(progress.actions.openProgressBar({}))
        if (extraordinaryHoursTable.length === 0 && ordinaryHoursTable.length === 0) {
            alert('No se han colocado proyectos y/o horas')
            dispatch(progress.actions.closeProgressBar())
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        let status = confirm(trackingHoursConfirmationMessage());
        if (!status) {
            dispatch(progress.actions.closeProgressBar())
            return;
        }
        let ids = [] as ISendHoursModel[];
        let ordinaryHoursList = new List(ordinaryHoursTable);
        let extraOrdinaryHoursList = new List(extraordinaryHoursTable);

        let ordinaryHoursCells = ordinaryHoursList.selectMany(element => getTrackingModel(element));
        let extraOrdinaryHoursCells = extraOrdinaryHoursList.selectMany(element => getTrackingModel(element));

        // Check the hours
        let cancel = false;
        let uniqueDays = new List<number>();
        ordinaryHoursCells.groupBy(element => element.date.getUTCDay()).forEach(groupByDayOfWeek => {
            if (cancel) return;
            let dayOfTheWeek = groupByDayOfWeek.key;
            let hoursPerDay = getHoursPerDay(dayOfTheWeek);
            if (hoursPerDay !== groupByDayOfWeek.value.sum(element => element.hours)) {
                alert(`Las horas del día  ${groupByDayOfWeek.value.first().date.toUTCString()} no equivalen a ${hoursPerDay}.`);
                cancel = true;
                return;
            }
            uniqueDays.push(dayOfTheWeek);
        });
        if (cancel) {
            handleDateChange(currentDate);
            return;
        }

        if (!uniqueDays.contains(1) ||
            !uniqueDays.contains(2) ||
            !uniqueDays.contains(3) ||
            !uniqueDays.contains(4) ||
            !uniqueDays.contains(5)) {
            alert('Hay días sin contener horas');
            return;
        }

        ordinaryHoursCells.forEach(element => ids.push({id: element.id}));
        extraOrdinaryHoursCells.forEach(element => ids.push({id: element.id}));

        trackingHoursService.sendHours(ids).finally(() => {
            dispatch(progress.actions.closeProgressBar())
            handleDateChange(currentDate);
        });
    }

    const handleCloseDialogBox = (value: ITrackingHoursProjectDialogItem) => {
        setOpenDialogBox(false);
        if (value === null || value === undefined)
            return;
        let newData: ITrackingHoursRowData[];
        let timeTrackingType: TimeTrackingTypeEnum;
        if (isAddingOrdinaryProject) {
            newData = [...ordinaryHoursTable];
            timeTrackingType = TimeTrackingTypeEnum.Ordinary;
        } else {
            newData = [...extraordinaryHoursTable];
            timeTrackingType = TimeTrackingTypeEnum.Extraordinary;
        }

        // Check if data exists
        let projectIds = newData.map(trackingHoursRowData => trackingHoursRowData.projectId);
        if (projectIds.includes(value.id)) {
            alert('El proyecto ya existe')
            return;
        }

        let dateDays = getDateFromSaturdayToFriday(startDate);
        let trackingHoursRowData: ITrackingHoursRowData = {
            projectId: value.id,
            userId: userId,
            projectCode: value.projectCode,
            projectCodeName: getCodeProjectName(value.projectCode, value.projectName),
            projectName: value.projectName,
            timeTrackingTypeDisplay: getTimeTrackingTypeDisplay(timeTrackingType),
            timeTrackingType: timeTrackingType,
            saturdayDate: dateDays.saturdayDate,
            sundayDate: dateDays.sundayDate,
            mondayDate: dateDays.mondayDate,
            tuesdayDate: dateDays.tuesdayDate,
            wednesdayDate: dateDays.wednesdayDate,
            thursdayDate: dateDays.thursdayDate,
            fridayDate: dateDays.fridayDate
        };

        newData.push(trackingHoursRowData);
        if (isAddingOrdinaryProject)
            setOrdinaryHoursTable(newData);
        else
            setExtraOrdinaryHoursTable(newData);
    };

    const deleteRowAndUpdate = (rowData: ITrackingHoursRowData) => {
        dispatch(progress.actions.openProgressBar({}));
        deleteRow(rowData, credentials).then(() => handleDateChange(currentDate))
            .catch(reason => console.error(reason));
    }

    async function copyLastWeek() {
        dispatch(progress.actions.openProgressBar({}));

        let lastWeekStartDate = new Date(startDate.getTime())
        lastWeekStartDate.setDate(lastWeekStartDate.getUTCDate() - 7)

        let lastWeekEndDate = new Date(startDate.getTime());
        lastWeekEndDate.setDate(lastWeekEndDate.getUTCDate() - 1)

        // Remove tracking horus
        let toRemoveCurrentTrackingHours = [] as IRemoveTrackingHours[]
        ordinaryHoursTable.forEach(row => {
            let cells = getTrackingModel(row);
            cells.forEach(value => {
                toRemoveCurrentTrackingHours.push({id: value.id})
            });
        });
        await trackingHoursService.removeTrackingHours(toRemoveCurrentTrackingHours);

        let user = await usersService.getUserByEmail(credentials.email);
        let trackingHoursModels = await trackingHoursService.getTrackingHours(user.id, lastWeekStartDate, lastWeekEndDate)

        let createTrackingHours = [] as ICreateTrackingHours[];
        trackingHoursModels.forEach(value => {
            if (value.type === TimeTrackingTypeEnum.Ordinary) {
                let insertionDate = moment(value.insertionDate).toDate();
                insertionDate.setDate(insertionDate.getDate() + 7)
                createTrackingHours.push({
                    day: insertionDate.getUTCDate(),
                    month: insertionDate.getUTCMonth() + 1,
                    projectId: value.project.id,
                    type: value.type,
                    userId: user.id,
                    year: insertionDate.getUTCFullYear(),
                    hours: value.hours
                })
            }
        });

        await trackingHoursService.createTrackingHours(createTrackingHours).catch(reason => {
            dispatch(notification.actions.openDialog({title: Strings.Error, text: reason}))
            console.error(reason);
        })
        handleDateChange(currentDate)
    }

    function goToHelpCallback(){
        history.push('/activityRecordHelp')
    }

    return <>
        {!isOpen && <>
            <Grid container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='flex-start'>
                <Grid item xs>
                    <IconButton color="primary" onClick={setPreviousWeekCallback}>
                        <ArrowBack/>
                    </IconButton>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker value={currentDate} onChange={handleDateChange}
                                    format="dd/MM/yyyy"/>
                    </MuiPickersUtilsProvider>
                    <IconButton color="primary" onClick={setNextWeekCallback}>
                        <ArrowForward/>
                    </IconButton>
                </Grid>

                <Grid item xs>
                    <Typography variant='h6' align='center'
                                paragraph>{Strings.Week} {weekOfYear} {startDate.getUTCDate()}/{startDate.getUTCMonth() + 1}/{startDate.getUTCFullYear()} - {endDate.getUTCDate()}/{endDate.getUTCMonth() + 1}/{endDate.getUTCFullYear()}</Typography>
                </Grid>

                <Grid item xs>
                    {!hoursHasBeenSent && <Grid container justifyContent="flex-end">
                        <ButtonGroup>
                            <Button onClick={copyLastWeek} variant='contained' color='secondary'>{Strings.Copy}</Button>
                            <Button onClick={sendHoursCallback} variant='contained'
                                    color='primary'>{Strings.Send}</Button>
                            <IconButton onClick={goToHelpCallback} size='small'><Help/></IconButton>
                        </ButtonGroup>
                    </Grid>}

                    {hoursHasBeenSent && <Grid container justifyContent="flex-end">
                        <Typography variant='h6' align='center' color='secondary'
                                    paragraph>Las horas ya han sido enviadas</Typography>
                    </Grid>}
                </Grid>
            </Grid>
            {!hoursHasBeenSent && <>
                <Box m={margin} p={padding}>
                    <MaterialTable
                        title={Strings.OrdinaryHours}
                        columns={ordinaryHoursColumns}
                        data={ordinaryHoursTable}
                        options={{
                            paging: false,
                            actionsColumnIndex: -1
                        }}
                        editable={{
                            onBulkUpdate: bulkUpdateOrdinaryCallback,
                        }}
                        actions={[{
                            tooltip: Strings.AddProject,
                            icon: 'add',
                            isFreeAction: true,
                            onClick: () => {
                                setIsAddingOrdinaryProject(true);
                                setOpenDialogBox(true);
                            }
                        }, {
                            icon: 'delete',
                            tooltip: Strings.Delete,
                            onClick: (event, rowData: ITrackingHoursRowData) => deleteRowAndUpdate(rowData)
                        }]}
                    />
                </Box>
                {/* Dmesa 01042024 se deshabilita carga de hh extraordinarias
                <Divider light/>
                <Box m={margin} p={padding}>
                    <MaterialTable
                        title={Strings.ExtraordinaryHours}
                        columns={columnsTable}
                        data={extraordinaryHoursTable}
                        options={{
                            paging: false,
                            actionsColumnIndex: -1
                        }}
                        editable={{
                            onBulkUpdate: bulkUpdateExtraordinaryCallback,
                        }}
                        actions={[{
                            tooltip: Strings.AddProject,
                            icon: 'add',
                            isFreeAction: true,
                            onClick: () => {
                                setIsAddingOrdinaryProject(false);
                                setOpenDialogBox(true);
                            }
                        }, {
                            icon: 'delete',
                            tooltip: Strings.Delete,
                            onClick: (event, rowData: ITrackingHoursRowData) => deleteRowAndUpdate(rowData)
                        }]}
                    />
                </Box> */}
            </>}
            {hoursHasBeenSent && <>
                <Box m={margin} p={padding}>
                    <MaterialTable
                        title={Strings.HoursWaitingForApproval}
                        columns={getColumns()}
                        data={waitingForApprovalHoursTable}
                        options={{
                            paging: false,
                            actionsColumnIndex: -1
                        }}
                    />
                </Box>
                <Divider light/>
                <Box m={margin} p={padding}>
                    <MaterialTable
                        title={Strings.ApprovedHours}
                        columns={getColumns()}
                        data={approvedHoursTable}
                        options={{
                            paging: false,
                            actionsColumnIndex: -1
                        }}
                    />
                </Box>
            </>}
            <TrackingHoursAddProjectDialog open={openDialogBox} onClose={handleCloseDialogBox}/>
        </>}
    </>
}


interface IInitializeTrackingHoursResult {
    newOrdinaryDataTable: ITrackingHoursRowData[];
    newExtraOrdinaryDataTable: ITrackingHoursRowData[];
    newWaitingForApprovalDataTable: ITrackingHoursRowData[];
    newApprovedDataTable: ITrackingHoursRowData[];
    hoursHasBeenSent: boolean
}


async function getTrackingHours(credentials: Credentials,
                                newStartDate: Date,
                                newEndDate: Date) {
    let newOrdinaryDataTable: ITrackingHoursRowData[];
    let newExtraOrdinaryDataTable: ITrackingHoursRowData[];
    let newWaitingForApprovalDataTable: ITrackingHoursRowData[];
    let newApprovedDataTable: ITrackingHoursRowData[];

    let user = await usersService.getUserByEmail(credentials.email);
    let userId = user.id;

    let trackingHours = await trackingHoursService.getTrackingHours(userId, newStartDate, newEndDate);
    // Create the list and push the elements
    const trackingHourModels = new List<ITrackingHoursModel>();
    trackingHourModels.pushRange(trackingHours);

    // Get all distinct projects
    let distinctProjectIds = trackingHourModels.select(element => element.project.id).distinct().toArray();
    let trackingHoursProjectModels = await projectService.getProjectByIds(distinctProjectIds);

    let hoursHasBeenSent = false;
    if (trackingHourModels.where(element => element.status === TrackingHoursStateEnum.WaitingForApproval ||
        element.status === TrackingHoursStateEnum.Approved).count() > 0) {
        hoursHasBeenSent = true;
    }
    // Bundle between ordinary and extraordinary
    newOrdinaryDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.type === TimeTrackingTypeEnum.Ordinary && element.status === TrackingHoursStateEnum.Template).toList(),
        trackingHoursProjectModels, userId, newStartDate);
    newExtraOrdinaryDataTable = getTrackingHoursRow(trackingHourModels.where
    (element => element.type === TimeTrackingTypeEnum.Extraordinary
        && element.status === TrackingHoursStateEnum.Template).toList(), trackingHoursProjectModels, userId, newStartDate);

    newWaitingForApprovalDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.status === TrackingHoursStateEnum.WaitingForApproval).toList(),
        trackingHoursProjectModels, userId, newStartDate);

    newApprovedDataTable = getTrackingHoursRow(trackingHourModels.where
        (element => element.status === TrackingHoursStateEnum.Approved).toList(),
        trackingHoursProjectModels, userId, newStartDate);

    return {
        newExtraOrdinaryDataTable: newExtraOrdinaryDataTable,
        newOrdinaryDataTable: newOrdinaryDataTable,
        newApprovedDataTable: newApprovedDataTable,
        newWaitingForApprovalDataTable: newWaitingForApprovalDataTable,
        hoursHasBeenSent: hoursHasBeenSent
    } as IInitializeTrackingHoursResult;
}



