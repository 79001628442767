import {Strings} from "../Resources/Strings";

export enum HolidayTypeEnum {
    Administrative = 0,
    Holiday = 1
}

export function getHolidayTypeString(holidayType: HolidayTypeEnum) {
    switch (holidayType) {
        case HolidayTypeEnum.Administrative:
            return Strings.Administrative
        case HolidayTypeEnum.Holiday:
            return Strings.Holiday
    }
}

export function getHolidayTypeFromString(holidayString: string) {
    switch (holidayString) {
        case Strings.Administrative:
            return HolidayTypeEnum.Administrative
        case Strings.Holiday:
            return HolidayTypeEnum.Holiday
    }
}

export function getHolidayTypeStrings() {
    return [getHolidayTypeString(HolidayTypeEnum.Holiday), getHolidayTypeString(HolidayTypeEnum.Administrative)]
}