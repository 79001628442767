import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {IUserModel} from "./IUserModel";

class AdminService extends BaseService {
    async getUsers(startDate: Date, endDate: Date) {
        let token = await this.getToken();
        let startDateString = `${startDate.getUTCFullYear()}-${startDate.getUTCMonth() + 1}-${startDate.getUTCDate()}`;
        let endDateString = `${endDate.getUTCFullYear()}-${endDate.getUTCMonth() + 1}-${endDate.getUTCDate()}`;
        let url = `${apiUrl}Admin/pendingToUpload?startDate=${startDateString}&endDate=${endDateString}`

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(url, requestOptions);
        return await response.json() as IUserModel[];
    }
}

export const adminService = new AdminService()