import {combineReducers, createStore, applyMiddleware} from "redux"
import {notification} from "./notification"
import {question} from "./question"
import {progress} from "./progress"
import thunk from "redux-thunk"
import {authentication} from "./authentication"
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {externalUser} from "./externalUser";
import {activityRecord} from "./activityRecord";
import {user} from "./user";
import {compromise} from "./compromise";
import {project} from "./project";
import {securityReflection} from "./securityReflection";
import {userHoliday} from "./userHolidays";
import {activePause} from "./activePause";
import { secondaryApprover } from "./SecondaryApprover"
import {accountRequest} from "./accountRequest"

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authentication', 'projects', 'activityRecord', 'compromise', 'project', 'user', 'secondaryApprover']
}

let reducers = combineReducers({
    notification: notification.reducer,
    question: question.reducer,
    progress: progress.reducer,
    authentication: authentication.reducer,
    externalUser: externalUser.reducer,
    activityRecord: activityRecord.reducer,
    user: user.reducer,
    compromise: compromise.reducer,
    project: project.reducer,
    securityReflection: securityReflection.reducer,
    userHolidays: userHoliday.reducer,
    activePause : activePause.reducer,
    secondaryApprover : secondaryApprover.reducer,
    accountRequest : accountRequest.reducer,
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch







