export class Strings {
    public static Notification = "Notificación";
    public static Files = "Archivos";
    public static Add = "Añadir";
    public static AddProject = "Añadir proyecto";
    public static AddMembers = "Añadir miembros";
    public static SpecificProjects = "Proyectos específicos";
    public static ThisProjectAlreadyExists = "Este proyecto ya existe";
    public static ReportGenerator = "Generar reporte";
    public static Report = "Reporte";
    public static InitialDate = "Fecha inicial";
    public static FinalDate = "Fecha final";
    public static Date = "Fecha";
    public static BminingPpt = "Bmining Power Point Template 2023";
    public static BminingGraphics = "Gráficas";
    public static BminingInformeSemilla= "BM-PY-IN-012-ver01 Informe Semilla ";
    public static BminingIcon = "Bmining icono"
    public static BminingWord = "Bmining Word Template";
    public static Template = "Template";
    public static Codes = "Códigos";
    public static DefaultEmails = "Default emails";
    public static SpecificEmails = "Emails específicos";
    public static Create = "Crear";
    public static EnterpriseEmail = "Correo empresa";
    public static PersonalEmail = "Correo personal"
    public static CredentialType = "Tipo de credencial"
    public static Yes = "Sí";
    public static No = "No";
    public static Ok = "Ok";
    public static UserCreatedWithSuccess = "Usuario creado con éxito";
    public static AreYouSure = "¿Está seguro?"
    public static ProjectCreatedWithSuccess = "Proyecto creado con éxito";
    public static ProjectCode = "Código Proyecto";
    public static Code = "Código"
    public static Client = "Cliente";
    public static Name = "Nombre";
    public static ProjectType = "Tipo de proyecto";
    public static Type = "Tipo";
    public static ManagerEmail = "Jefe de proyecto";
    public static IsOrganizational = "Es organizacional";
    public static Load = "Cargar";
    public static UploadGoing = "Subir ida";
    public static UploadLeaving = "Subir vuelta";
    public static PriorityCuts = "Cortes prioritarios";
    public static BadWeatherDays = "Días de mal clima";
    public static Projects = "Proyectos";
    public static Process = "Procesar";
    public static Error = "Error";
    public static AddMember = "Añadir miembro";
    public static Project = "Proyecto";
    public static User = "Usuario";
    public static AssociatedUser = "Usuario asociado"
    public static Members = "Miembros";
    public static ProjectManager = "Jefe de proyecto";
    public static Added = "agregado";
    public static DoYouWishToAddUser = "¿Desea agregar al usuario?";
    public static Question = "Pregunta";
    public static CopyLastWeek = "Copiar la semana pasada";
    public static Copy = "Copiar";
    public static Send = "Enviar";
    public static YouDontHaveAccess = "Usted no esta ingresado o carece de los permisos";
    public static Login = "Login";
    public static Logout = "Logout";
    public static SignIn = "Sign In";
    public static SignOut = "Sign Out";
    public static Clients = "Clientes";
    public static NewExternalUser = "Nuevo usuario externo"
    public static Surname = "Apellido"
    public static Email = "Email"
    public static ExternalUsers = "Usuarios externos"
    public static ExternalUser = "External user"
    public static Records = "Registros"
    public static ActivityRecords = "Registros de actividad"
    public static ActivityRecord = "Registro de actividad"
    public static Title = "Título"
    public static Id = "Id"
    public static DurationHours = "Duración (horas)"
    public static Save = "Guardar"
    public static Notes = "Notas"
    public static Edit = "Editar"
    public static Close = "Cerrar"
    public static Remove = "Remover"
    public static AddExternalUser = "Añadir usuario externo"
    public static BminingUser = "Usuario Bmining"
    public static AddBminingUser = "Agregar usuario Bmining"
    public static NewCompromise = "Nuevo compromiso"
    public static Compromises = "Compromisos"
    public static MyCompromises = "Mis Compromisos"
    public static CreatedCompromises = "Compromisos creados"
    public static Owner = "Dueño"
    public static DueDate = "Fecha termino"
    public static PercentageCompleted = "Completado %"
    public static IsFinished = "Finalizado"
    public static EditCompromise = "Editar compromiso"
    public static Status = "Estado"
    public static AddCompromise = "Añadir compromiso"
    public static NewActivityRecord = "Nuevo registro de actividad"
    public static Week = "Semana"
    public static SecurityReflection = "Reflexión de Seguridad"
    public static InChargeOfAnnotations = "Responsable apuntes reunión"
    public static NotStarted = "No iniciado"
    public static InProgress = "En progreso"
    public static Completed = "Completado"
    public static GeneralCompromises = "Compromisos generales"
    public static GeneralCompromise = "Compromisos general"
    public static Delete = "Borrar"
    public static ExtraordinaryHours = "Horas Extraordinarias"
    public static OrdinaryHours = "Horas Ordinarias"
    public static HoursWaitingForApproval = "Horas Esperando Aprobación"
    public static ApprovedHours = "Horas Aprobadas"
    public static Created = "Creado"
    public static Due = "Vencimiento"
    public static Wiki = "Wiki"
    public static HoursRegister = "Registro de horas"
    public static OwnedCompromises = "Compromisos propios"
    public static Creator = "Creador"
    public static Search = "Buscar"
    public static PendingUsers = "Usuarios pendientes";
    public static UserHasAlreadyAProfile = "El usuario ya tiene un perfil";
    public static Holidays = "Vacaciones"
    public static IncorporationDate = "Fecha de incorporación"
    public static MonthsOfExperienceAtIncorporation = "Meses de experiencia al ingresar"
    public static CreateUserHolidayProfiles = "Crear perfil de vacaciones del usuario"
    public static CreateUserHolidayProfile = "Crear perfil de vacaciones"
    public static AddHolidays = "Añadir vacaciones"
    public static Comments = "Comentarios"
    public static BusinessDaysUsed = "Días hábiles usados"
    public static From = "Desde"
    public static To = "Hasta"
    public static NoRecords = "Sin registro"
    public static AvailableDays = "Días disponibles"
    public static YouDontHaveHolidayProfileOrSignature = "Usted no tiene un perfil de vacaciones o actualizada su firma"
    public static Administrative = "Administrativo"
    public static Administration = "Administración"
    public static Holiday = "Vacación"
    public static Accepted = "Aceptadas"
    public static AcceptHours = "Aceptar horas"
    public static ProgressiveDays = "Días progresivos"
    public static TotalHolidaysFromIncorporation = "Días obtenidos de vacaciones desde incorporación"
    public static Waiting = "En espera"
    public static DaysRequested = "Días solicitados"
    public static PendingHolidays = "Vacaciones pendientes";
    public static ApprovingUser = "Usuario aprobador"
    public static HolidaysDashboard = "Dashboard vacaciones"
    public static Deleted = "Eliminado"
    public static Bmining = "Bmining"
    public static Accept = "Aceptar"
    public static Reject = "Reject"
    public static ApproveHours = "Aprobar horas"
    public static RUT = "Rut"
    public static VerificationCode = "Código verificador"
    public static Signatures = "Firmas"
    public static SignedByBothPersons = "Firmado por ambas personas"
    public static SignedByApprovingUser = "Firmado por el usuario aprobador"
    public static SignedByRequestingUser = "Firmado por el usuario solicitante"
    public static WithoutSignatures = "Sin firmas"
    public static DownloadAvailableDocument = "Descargar documento disponible"
    public static UploadSignedDocument = "Subir archivo firmado"
    public static Welcome = "Bienvenido"
    public static YouDontHaveASignature = "No tienes cargada una firma"
    public static UploadSignature = "Subir firma"
    public static SignatureSample = "Firma de muestra"
    public static Signature = "Firma"
    public static Reset = "Reset"
    public static UploadSecurityReflectionFile = "Subir archivo"
    public static InvalidFileExtension = "Extensión de archivo invalida"
    public static UpdateList = "Actualizar lista"
    public static GetSample = "Obtener muestra"
    public static ActivePause = "Pausa activa"
    public static SecondaryProjectApproverHours = "Aprobar horas como aprobador secundario"
    public static SecondaryApproverSuccess = "Aprobador secundario añadido con éxito."
    public static AddSecondaryApprover = "Añadir aprobador secundario"
    public static SecondaryApprover = "Aprobador secundario"
    public static SecondaryApprovers = "Aprobadores secundarios"
    public static Replacement = "Reemplazo"
    public static SecondaryApproverRemoved = "Aprobador secundario eleminado."
    public static OnHolidays = "De vacaciones"
    public static OnHolidaysAndSoon = "De vacaciones y próximamente"
    public static SgiHelp = "SGI"
    public static ProjectManagerCompromises = "Compromisos JP"
    public static HumanResourcesCompromises = "Compromisos HR"
    public static Help = "Ayuda"
    public static AccountRequest = "Solicitud de Cuentas"
    public static Pending = "Pending"
    public static Approved = "Approved"
    public static Rejected = "Rejected"
    public static NewAccountRequest = "Añadir Solicitud de Cuenta"
    public static AccountRequestedWithSuccess = "Cuenta solicitada con éxito";
    public static AccountApprovedWithSuccess = "Cuentas aprobadas";
    public static AccountRejectedWithSuccess = "Cuentas rechazadas";
    public static RequestUser = "Usuario solicitante";
    public static AccountRequestApproval = "Aprobar Cuentas"
    public static AccountRequestPending = "Solicitud de Cuentas Pendientes"
    public static AccountRequestHistory = "Historial de Solicitud de Cuentas"
    public static ApproveAccount = "Aprobar"
    public static RejectAccount = "Rechazar"
    public static MicrosoftActiveAccounts = "Cuentas Activas Microsoft"
    public static MicrosoftActiveAccountsBmining = "Cuentas Activas Microsoft Bmining"
    public static MicrosoftActiveAccountsMuroc = "Cuentas Activas Microsoft Muroc"
    public static WithCopyEmails = "Enviar con copia:"
    public static CreatedDate = "Fecha de Creación"
    public static UserType = "Tipo de Usuario"
    public static DeleteRequest = "Solicitar Eliminación"
    public static ChangeRequest = "Solicitar Cambio de Credencial"
    public static RequestType = "Tipo de solicitud"
    public static Change = "Cambio"
    public static DownloadCsvFile = "Descargar Archivo CSV"

}
