import React, {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {
    activityRecord,
    addExternalUserFromActivityRecordThunk,
    removeExternalUserFromActivityRecordThunk
} from "../../../redux/activityRecord";
import MaterialTable, {Column} from "material-table";
import {IExternalUser} from "../../../api/ExternalUsersService";
import {Autocomplete} from "@material-ui/lab";
import {margin, padding} from "../../../styles/styleConstants";
import {List} from "linq-typescript";
import {Add} from "@material-ui/icons";
import {IActivityRecord} from "../../../api/IActivityRecord";

function getTemplateColumns(): Column<IExternalUser>[] {
    return [{title: Strings.Email, field: 'email', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
    ];
}

export interface ActivityRecordExternalUsersDialogComponentProps {
    selectedActivityRecord: IActivityRecord,
    externalUsers: IExternalUser[]
}

export const ActivityRecordExternalUsersDialogComponent: FC<ActivityRecordExternalUsersDialogComponentProps> = (props) => {

    const {isEditingActivityRecordExternalUsers} = useSelector((state: RootState) => state.activityRecord)

    const [availableExternalUsers, setAvailableExternalUsers] = useState<IExternalUser[]>([])
    const [selectedExternalUser, setSelectedExternalUser] = useState<IExternalUser>()
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const activityRecordExternalUserTable = props.selectedActivityRecord.externalAssistants.map
    (value => ({...props.externalUsers.find(externalUser => externalUser.id === value.id)})) as IExternalUser[]


    const dispatch = useDispatch()

    useEffect(() => {
        async function loadData() {
            setSelectedExternalUser(null)
            updateAvailableExternalUsers()
        }

        if (isEditingActivityRecordExternalUsers) {
            // noinspection JSIgnoredPromiseFromCall
            loadData();
        }
    }, [isEditingActivityRecordExternalUsers])

    useEffect(() => {
        selectedExternalUser != null ? setIsYesAvailable(true) : setIsYesAvailable(false)
    }, [selectedExternalUser])

    useEffect(() => {
        updateAvailableExternalUsers()
    }, [props.selectedActivityRecord])

    function updateAvailableExternalUsers() {
        let listAllExternalUsers = new List(props.externalUsers)
        let listExternalUserDataTable = new List(activityRecordExternalUserTable)
        let availableExternalUsers = listAllExternalUsers.where(firstElement => !listExternalUserDataTable.any
        (secondElement => secondElement.id === firstElement.id));
        setAvailableExternalUsers(availableExternalUsers.toArray());
    }

    async function handleClose() {
        dispatch(activityRecord.actions.closeEditActivityRecordExternalUsers())
    }

    const onAddCallback = async () => {
        setIsLoading(true)
        await dispatch(addExternalUserFromActivityRecordThunk(props.selectedActivityRecord.id, selectedExternalUser.id))
        setSelectedExternalUser(null)
        setIsLoading(false)
    }

    const onRemoveCallback = async (event, externalUser: IExternalUser) => {
        setIsLoading(true)
        await dispatch(removeExternalUserFromActivityRecordThunk(props.selectedActivityRecord.id, externalUser.id))
        setSelectedExternalUser(null)
        setIsLoading(false)
    }

    return <>
        <Dialog open={isEditingActivityRecordExternalUsers} onClose={handleClose} fullWidth>
            <DialogTitle>{Strings.AddExternalUser}</DialogTitle>
            <DialogContent>
                <Grid container direction='row' alignItems='center'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          value={selectedExternalUser}
                                          onChange={(event: any, newValue: IExternalUser | null) => {
                                              setSelectedExternalUser(newValue)
                                          }}
                                          id="external-user-autocomplete"
                                          options={availableExternalUsers}
                                          getOptionLabel={(option) => option.email}
                                          renderInput={(params) =>
                                              <TextField {...params} label={Strings.ExternalUsers}
                                                         variant="outlined"/>}/>
                        </Box>
                    </Grid>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button variant='contained' color='primary' onClick={onAddCallback}
                                    disabled={!isYesAvailable} startIcon={<Add/>}>{Strings.Add}</Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box m={margin} p={padding}>
                    <MaterialTable isLoading={isLoading}
                                   columns={getTemplateColumns()}
                                   data={activityRecordExternalUserTable}
                                   options={{
                                       actionsColumnIndex: -1,
                                       showTitle: false
                                   }}
                                   actions={[{
                                       icon: 'remove',
                                       tooltip: `${Strings.Remove}`,
                                       onClick: onRemoveCallback
                                   }]}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant='contained'>
                    {Strings.Close}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}