import {FC, useEffect} from "react";
import {useHistory} from "react-router";
import {
    loadProjectsThunk,
    navigateToProjectCompromisesThunk,
} from "../../redux/project";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";


export const HumanResourcesCompromiseNavigateComponent = () => {
    return <>
        <NavigationCompromiseComponent projectCode={"BM-INT-ADMCOM"}/>
    </>
}

export const ProjectManagerCompromiseNavigateComponent = () => {
    return <>
        <NavigationCompromiseComponent projectCode={"BM-INT-REU"}/>
    </>
}

interface NavigationCompromiseComponentProps {
    projectCode: string
}

const NavigationCompromiseComponent: FC<NavigationCompromiseComponentProps> = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {projects} = useSelector((state: RootState) => state.project)

    useEffect(() => {
        if (projects.length == 0) {
            dispatch(loadProjectsThunk)
            return
        }
        let project = projects.find(item => item.code == props.projectCode)
        dispatch(navigateToProjectCompromisesThunk(project.id, history))
    }, [projects])

    return <></>
}