import React, {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";
import {
    activityRecord, addBminingUserFromActivityRecordThunk,
    removeBminingUserFromActivityRecordThunk
} from "../../../redux/activityRecord";
import MaterialTable, {Column} from "material-table";
import {Autocomplete} from "@material-ui/lab";
import {margin, padding} from "../../../styles/styleConstants";
import {List} from "linq-typescript";
import {Add} from "@material-ui/icons";
import {IUserModel} from "../../../api/IUserModel";
import {IActivityRecord} from "../../../api/IActivityRecord";

function getTemplateColumns(): Column<IUserModel>[] {
    return [{title: Strings.Email, field: 'email', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Name, field: 'name', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Surname, field: 'surname', type: 'string', editable: "never", width: 'auto'},
    ];
}

export interface ActivityRecordBminingUsersDialogComponentProps {
    selectedActivityRecord: IActivityRecord,
    users: IUserModel[]
}

export const ActivityRecordBminingUsersDialogComponent: FC<ActivityRecordBminingUsersDialogComponentProps> = (props) => {

    const {isEditingActivityRecordBminingUsers} = useSelector((state: RootState) => state.activityRecord)

    const [availableBminingUsers, setAvailableBminingUsers] = useState<IUserModel[]>([])
    const [selectedBminingUser, setSelectedBminingUser] = useState<IUserModel>()
    const [isYesAvailable, setIsYesAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const activityRecordBminingUserTable = props.selectedActivityRecord.bminingAssistants.map
    (value => ({...props.users.find(bminingUser => bminingUser.id === value.id)})) as IUserModel[]


    const dispatch = useDispatch()

    useEffect(() => {
        async function loadData() {
            setSelectedBminingUser(null)
            updateAvailableBminingUsers()
        }

        if (isEditingActivityRecordBminingUsers) {
            // noinspection JSIgnoredPromiseFromCall
            loadData();
        }
    }, [isEditingActivityRecordBminingUsers])

    useEffect(() => {
        selectedBminingUser != null ? setIsYesAvailable(true) : setIsYesAvailable(false)
    }, [selectedBminingUser])

    useEffect(() => {
        updateAvailableBminingUsers()
    }, [props.selectedActivityRecord])

    function updateAvailableBminingUsers() {
        let listAllBminingUsers = new List(props.users)
        let listBminingUserDataTable = new List(activityRecordBminingUserTable)
        let availableBminingUsers = listAllBminingUsers.where(firstElement => !listBminingUserDataTable.any
        (secondElement => secondElement.id === firstElement.id));
        setAvailableBminingUsers(availableBminingUsers.toArray());
    }

    async function handleClose() {
        dispatch(activityRecord.actions.closeEditActivityRecordBminingUsers())
    }

    const onAddCallback = async () => {
        setIsLoading(true)
        await dispatch(addBminingUserFromActivityRecordThunk(props.selectedActivityRecord.id, selectedBminingUser.id))
        setSelectedBminingUser(null)
        setIsLoading(false)
    }

    const onRemoveCallback = async (event, bminingUser: IUserModel) => {
        setIsLoading(true)
        await dispatch(removeBminingUserFromActivityRecordThunk(props.selectedActivityRecord.id, bminingUser.id))
        setSelectedBminingUser(null)
        setIsLoading(false)
    }

    return <>
        <Dialog open={isEditingActivityRecordBminingUsers} onClose={handleClose} fullWidth>
            <DialogTitle>{Strings.AddBminingUser}</DialogTitle>
            <DialogContent>
                <Grid container direction='row' alignItems='center'>
                    <Grid item xs>
                        <Box m={margin} p={padding}>
                            <Autocomplete fullWidth
                                          value={selectedBminingUser}
                                          onChange={(event: any, newValue: IUserModel | null) => {
                                              setSelectedBminingUser(newValue)
                                          }}
                                          id="bmining-user-autocomplete"
                                          options={availableBminingUsers}
                                          getOptionLabel={(option) => option.email}
                                          renderInput={(params) =>
                                              <TextField {...params} label={Strings.BminingUser}
                                                         variant="outlined"/>}/>
                        </Box>
                    </Grid>
                    <Grid item xs='auto'>
                        <Box m={margin} p={padding}>
                            <Button variant='contained' color='primary' onClick={onAddCallback}
                                    disabled={!isYesAvailable} startIcon={<Add/>}>{Strings.Add}</Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box m={margin} p={padding}>
                    <MaterialTable isLoading={isLoading}
                                   columns={getTemplateColumns()}
                                   data={activityRecordBminingUserTable}
                                   options={{
                                       actionsColumnIndex: -1,
                                       showTitle: false
                                   }}
                                   actions={[{
                                       icon: 'remove',
                                       tooltip: `${Strings.Remove}`,
                                       onClick: onRemoveCallback
                                   }]}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant='contained'>
                    {Strings.Close}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}