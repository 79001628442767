import {BaseService} from "./BaseService";
import {apiUrl} from "./ApiConfig";
import {ISecurityReflectionModel} from "./ISecurityReflectionModel";
import moment from "moment";
import {PureBase64} from "../utils/utils";

class SecurityReflectionService extends BaseService {
    async getSecurityReflections() {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = `securityReflection`;
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        let models = await response.json() as any[]

        return models.map(value => {
            return {id: value.id, email: value.email, date: moment(value.date).toDate()} as ISecurityReflectionModel
        })
    }

    async getSecurityReflectionSampleFile() {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = `securityReflection/sample`;
        let url = `${host}${resource}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': `Bearer ${token}`,
            },
        };
        let response = await fetch(`${url}`, requestOptions);
        let blob = await response.blob()
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `sample.csv`;
        link.click();
    }

    async uploadSecurityReflectionFile(base64File: string) {
        let token = await this.getToken();
        let host = apiUrl;
        let resource = `securityReflection/file`;
        let url = `${host}${resource}`;
        let body = {
            csvFileBase64: PureBase64(base64File)
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(`${url}`, requestOptions);
    }
}

export const securityReflectionService = new SecurityReflectionService();
