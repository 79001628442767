import React from 'react'
import {IUserModel} from "../../api/IUserModel";
import {Box, Grid, List, ListItem, Typography} from "@material-ui/core";
import {Strings} from "../../Resources/Strings";
import {margin, padding} from "../../styles/styleConstants";

export interface MembersComponentProps {
    members: IUserModel[],
    projectManager: IUserModel
}

export const MembersComponent: React.FC<MembersComponentProps> = (props) => {
    const {members, projectManager} = props
    return <>{projectManager && members && <>
        <Box m={margin} p={padding}>
            <Grid container direction='column'>
                <Typography variant='h6'>{Strings.ProjectManager}</Typography>
                <ListItem>{projectManager.email}</ListItem>
                <Typography variant='h6'>{Strings.Members}</Typography>
                <List>
                    {members.map(value => {
                        return <ListItem key={value.email}>{value.email}</ListItem>;
                    })}
                </List>
            </Grid>
        </Box>
    </>
    }
    </>
}
