import {AppDispatch} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {externalUsersService, IExternalUser} from "../api/ExternalUsersService";
import {progress} from "./progress";

export interface ExternalUsersState {
    isAddingExternalUser: boolean,
    externalUsers: IExternalUser[]
}

const initialState: ExternalUsersState = {
    isAddingExternalUser: false,
    externalUsers: []
}

export const externalUser = createSlice({
    name: "externalUser",
    initialState: initialState,
    reducers: {
        openAddExternalUser: (state: ExternalUsersState) => {
            state.isAddingExternalUser = true
            return state;
        },
        closeAddExternalUser: (state: ExternalUsersState) => {
            state.isAddingExternalUser = false
            return state;
        },
        setExternalUsers: (state: ExternalUsersState, payload: PayloadAction<IExternalUser[]>) => {
            state.externalUsers = payload.payload
            return state;
        }
    }
});


export function addExternalUserThunk(inputExternalUser: IExternalUser) {
    return async function addExternalUser(dispatch: AppDispatch) {
        await externalUsersService.addExternalUser(inputExternalUser.email, inputExternalUser.name,
            inputExternalUser.surname, inputExternalUser.clientId).catch(reason => {
            console.error(reason)
        })
        await loadExternalUsersThunk(dispatch);
        dispatch(externalUser.actions.closeAddExternalUser())
    }
}

export async function loadExternalUsersThunk(dispatch: AppDispatch) {
    let externalUsers = await externalUsersService.getExternalUsers();
    await dispatch(externalUser.actions.setExternalUsers(externalUsers));
}