import {AppDispatch} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {accountRequestService, IAccountRequest} from "../api/AccountRequestService";
import {GetCredentialTypeString } from "../utils/utils";
import {getAccountRequestStatusString} from "../api/AccountRequestStatusEnum"
import { usersService } from "../api/UsersService";
import { getRequestTypeString} from "../api/RequestTypeEnum";

export interface IAccountRequestsTable {
    id:string,
    name:string,
    surname:string,
    enterpriseEmail:string,
    personalEmail:string,
    credentialType:string,
    requestUser:string,
    approvingUser:string,
    requestDate: Date,
    status: string,
    requestType: string
}

export interface IMicrosoftUsersTable {
    id:string,
    name:string,
    surname:string,
    enterpriseEmail:string,
    credentialType:string,
    createdDateTime: Date,
}


export interface AccountRequestState {
    accountRequests: IAccountRequestsTable[],
    microsoftAccountsBmining: IMicrosoftUsersTable[],
    microsoftAccountsMuroc: IMicrosoftUsersTable[],
}

const initialState: AccountRequestState = {
    accountRequests: [],
    microsoftAccountsBmining: [],
    microsoftAccountsMuroc: []
}

export const accountRequest = createSlice({
    name: "accountRequest",
    initialState: initialState,
    reducers: {
        setAccountRequests: (state: AccountRequestState, payload: PayloadAction<IAccountRequestsTable[]>) => {
            state.accountRequests = [...payload.payload]
            return state;
        },
        setMicrosoftAccountsBmining: (state: AccountRequestState, payload: PayloadAction<IMicrosoftUsersTable[]>) => {
            state.microsoftAccountsBmining = [...payload.payload]
            return state;
        },
        setMicrosoftAccountsMuroc: (state: AccountRequestState, payload: PayloadAction<IMicrosoftUsersTable[]>) => {
            state.microsoftAccountsMuroc = [...payload.payload]
            return state;
        }
    }
});


export function addAccountRequestThunk(inputAccountRequest: IAccountRequest) {
    return async function addAccountRequest(dispatch: AppDispatch) {
        await accountRequestService.addAccountRequest(inputAccountRequest.name, inputAccountRequest.surname,
            inputAccountRequest.enterpriseEmail, inputAccountRequest.personalEmail, inputAccountRequest.credentialType, inputAccountRequest.withCopyEmails).catch(reason => {
            console.error(reason)
        })
        await loadAccountRequestsAllThunk(dispatch);
    }
}

export async function loadAccountRequestsAllThunk(dispatch: AppDispatch) {
    let accountRequests = await accountRequestService.getAccountRequestsAll();
    let x: IAccountRequestsTable[] = []
    
    var users = await usersService.getUsers();
    
    for (var model of accountRequests) {
        // eslint-disable-next-line no-loop-func
        var requestUser = users.find(u => u.id === model.requestUser.id);
        var approvingUserName:string = '';
        if (model.approvingUser != null){
            // eslint-disable-next-line no-loop-func
            var approvingUser = users.find(u => u.id === model.approvingUser.id);
            approvingUserName = approvingUser.name.charAt(0).toUpperCase() + approvingUser.name.slice(1)  + 
                ' ' + approvingUser.surname.charAt(0).toUpperCase() + approvingUser.surname.slice(1);
        };
        
        x.push(
            {
                id:model.id,
                name:model.name,
                surname:model.surname,
                enterpriseEmail:model.enterpriseEmail,
                personalEmail: model.personalEmail,
                credentialType: GetCredentialTypeString(model.credentialType),
                requestUser: requestUser.name.charAt(0).toUpperCase()  + requestUser.name.slice(1) + 
                    ' ' + requestUser.surname.charAt(0).toUpperCase() + requestUser.surname.slice(1),
                requestDate: model.requestDate,
                approvingUser: approvingUserName,
                status: getAccountRequestStatusString(model.status),
                requestType: getRequestTypeString(model.requestType)
            }
        )
    }
    await dispatch(accountRequest.actions.setAccountRequests(x));
}


export async function loadMicrosoftAccountsAllThunk(dispatch: AppDispatch) {
    let microsoftUsersBmining = await accountRequestService.getMicrosoftAccountBminingAll();
    let xBmining: IMicrosoftUsersTable[] = []
    for (var model of microsoftUsersBmining) {
        xBmining.push(
            {
                id:model.id,
                name:model.name,
                surname:model.surname,
                enterpriseEmail:model.enterpriseEmail,
                credentialType: GetCredentialTypeString(model.credentialType),
                createdDateTime: model.createdDateTime,
            }
        )
    }
    await dispatch(accountRequest.actions.setMicrosoftAccountsBmining(xBmining));

    let microsoftUsersMuroc = await accountRequestService.getMicrosoftAccountMurocAll();
    let xMuroc: IMicrosoftUsersTable[] = []
    for (var modelMuroc of microsoftUsersMuroc) {
        xMuroc.push(
            {
                id:modelMuroc.id,
                name:modelMuroc.name,
                surname:modelMuroc.surname,
                enterpriseEmail:modelMuroc.enterpriseEmail,
                credentialType: GetCredentialTypeString(modelMuroc.credentialType),
                createdDateTime: modelMuroc.createdDateTime,
            }
        )
    }
    await dispatch(accountRequest.actions.setMicrosoftAccountsMuroc(xMuroc));
}


export function rejectAccountRequestThunk(id:string) {
    return async function setAccountRequestRejected(dispatch: AppDispatch) {
        await accountRequestService.setAccountRequestsRejected([id]).catch(reason => {
            console.error(reason)
        });
        await loadAccountRequestsAllThunk(dispatch);
    }
}

export function approveAccountRequestThunk(id:string) {
    return async function setAccountRequestApproved(dispatch: AppDispatch) {
        await accountRequestService.setAccountRequestsApproved([id]).catch(reason => {
            console.error(reason)
        });
        await loadAccountRequestsAllThunk(dispatch);
    }
}


export function removeMicrosoftUserRequestThunk(id:string, withCopyEmails:string[]) {
    return async function removeMicrosoftUserRequest(dispatch: AppDispatch) {
        await accountRequestService.removeMicrosoftUserRequest(id, withCopyEmails).catch(reason => {
            console.error(reason)
        });
        await loadAccountRequestsAllThunk(dispatch);
    }
}

export function changeCredentialTypeRequestThunk(id:string, withCopyEmails:string[]) {
    return async function changeCredentialTypeRequest(dispatch: AppDispatch) {
        await accountRequestService.changeCredentialTypeUserRequest(id, withCopyEmails).catch(reason => {
            console.error(reason)
        });
        await loadAccountRequestsAllThunk(dispatch);
    }
}

export function downloadMicrosoftAccountsBminingThunk() {
    return async function downloadMicrosoftAccountsBmining() {
        await accountRequestService.downloadMicrosoftAccountBmining().catch(reason => {
            console.error(reason)
        });
    }
}

export function downloadMicrosoftAccountsMurocThunk() {
    return async function downloadMicrosoftAccountsMuroc() {
        await accountRequestService.downloadMicrosoftAccountMuroc().catch(reason => {
            console.error(reason)
        });
    }
}