import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import {isEmpty} from "../../../utils/utils";
import {IApproveHoursRowData} from "../../ApproveHours/IApproveHoursRowData";

export interface RejectHoursDialogProps {
    isOpen: boolean
    onClose: (status: boolean, reasons: string, row: IApproveHoursRowData) => void;
    row: IApproveHoursRowData
}

export const RejectHoursDialogComponent: React.FC<RejectHoursDialogProps> = ({isOpen, onClose, row}) => {
    const [reasons, setReasons] = React.useState('');
    const [isEnabledOk, setIsEnabledOk] = React.useState(false);

    function handleCancel() {
        setReasons('')
        onClose(false, null, row);
    }

    function handleOk() {
        setReasons('')
        onClose(true, reasons, row);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newReasons = event.target.value;
        setReasons(newReasons);
        if (isEmpty(newReasons))
            setIsEnabledOk(false)
        else setIsEnabledOk(true);
    };


    return <>
        <Dialog open={isOpen} onClose={handleCancel} fullWidth={true}>
            <DialogTitle>Rechazar horas</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="reasons"
                    label="Razones"
                    value={reasons}
                    onChange={handleChange}
                    fullWidth/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} disabled={!isEnabledOk} color="primary" variant='contained'>
                    Ok
                </Button>
                <Button onClick={handleCancel} color="secondary" variant='contained'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>
}