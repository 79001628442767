import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import MaterialTable, {Column} from "material-table";
import {Strings} from "../../Resources/Strings";
import {ICompromiseModel} from "../../api/ICompromiseModel";
import {compromise, loadCompromisesThunk, removeCompromiseThunk} from "../../redux/compromise";
import {loadUsersThunk} from "../../redux/user";
import {loadActivityRecordsThunk} from "../../redux/activityRecord";
import {progress} from "../../redux/progress";
import {AddActivityRecordCompromiseDialogComponent} from "../Dialogs/AddActivityRecordCompromise/AddActivityRecordCompromiseDialogComponent";
import {question} from "../../redux/question";
import {EditCompromiseDialogComponent} from "../Dialogs/EditCompromise/EditCompromiseDialogComponent";

function getTemplateColumns(): Column<ICompromiseModel>[] {
    return [{title: Strings.Title, field: 'title', type: 'string', editable: "never", width: 'auto'},
        {title: Strings.Id, field: 'id', type: 'string', editable: "never", width: 'auto'}];
}

export const CompromisesComponent = () => {

    const {compromises, selectedCompromise} = useSelector((state: RootState) => state.compromise);
    const {selectedActivityRecord} = useSelector((state: RootState) => state.activityRecord);
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {users} = useSelector((state: RootState) => state.user)

    const [title,setTitle] = useState<string>()
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const compromisesDataTable = filter(compromises);

    function filter(compromises: ICompromiseModel[]) {
        let items = [] as ICompromiseModel[]
        if (selectedActivityRecord === null || selectedActivityRecord === undefined)
            compromises.forEach(value => items.push(value))
        else compromises.filter(value => value.activityRecord.id === selectedActivityRecord.id)
            .forEach(value => items.push(value));
        return items.map(value => ({...value}))
    }

    useEffect(() => {
        async function fetchData() {
            await dispatch(progress.actions.openProgressBar({}))
            await dispatch(loadUsersThunk)
            await dispatch(loadCompromisesThunk)
            await dispatch(loadActivityRecordsThunk)
            await dispatch(progress.actions.closeProgressBar())
        }        // noinspection JSIgnoredPromiseFromCall
        fetchData()
    }, [])

    useEffect(() => {
        if (selectedActivityRecord)
            setTitle(`${Strings.Compromises} ${selectedActivityRecord.title}`)
        else setTitle(Strings.Compromises)
    },[selectedActivityRecord])

    function addCompromiseCallback() {
        dispatch(compromise.actions.openAddActivityRecordCompromise())
    }

    const onRemoveCallback = async (event, compromiseModel: ICompromiseModel) => {
        async function dispatchDelete() {
            setIsLoading(true)
            await dispatch(removeCompromiseThunk(compromiseModel))
            setIsLoading(false)
        }

        dispatch(question.actions.openDialog({
            title: Strings.Question,
            text: Strings.AreYouSure,
            actionOnYes: dispatchDelete
        }))
    }
    const onEditCallback = async (event, compromiseModel: ICompromiseModel) => {
        await dispatch(compromise.actions.setSelectedCompromise(compromiseModel))
        await dispatch(compromise.actions.openEditCompromise())
    }

    return <>
        {!isOpen && <>
            <MaterialTable
                isLoading={isLoading}
                title={title}
                columns={getTemplateColumns()}
                data={compromisesDataTable}
                options={{
                    paging: false,
                    actionsColumnIndex: -1
                }}

                actions={[{
                    tooltip: Strings.Add,
                    icon: 'add',
                    isFreeAction: true,
                    onClick: addCompromiseCallback
                }, {
                    icon: 'remove',
                    tooltip: `${Strings.Remove}`,
                    onClick: onRemoveCallback
                }, {
                    icon: 'edit',
                    tooltip: `${Strings.Edit}`,
                    onClick: onEditCallback
                }]}
            />
            <AddActivityRecordCompromiseDialogComponent users={users}/>
            {selectedCompromise &&
            <EditCompromiseDialogComponent selectedCompromise={selectedCompromise}/>
            }
        </>}
    </>
}