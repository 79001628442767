import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {Strings} from "../../../Resources/Strings";

export interface NotificationDialogComponentProps {
    isOpen: boolean
    title: string
    text: string
    onClose: () => void
}

export function NotificationDialogComponent(props: NotificationDialogComponentProps) {
    const {isOpen, onClose, text, title} = props;

    function handleOk() {
        onClose();
    }

    return <>
        <Dialog open={isOpen} onClose={handleOk} fullWidth={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography variant='h6'>{text}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary" variant='contained'>
                    {Strings.Ok}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

