import MaterialTable, {Column} from "material-table";
import React, {FC, MutableRefObject, useEffect, useRef, useState} from "react";
import {Strings} from "../../../Resources/Strings";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {
    compromise,
    editCompromiseThunk,
    removeCompromiseThunk,
    updateCompromiseStatsThunk
} from "../../../redux/compromise";
import {Delete, Edit} from "@material-ui/icons";
import {
    AddGeneralCompromiseDialogComponent
} from "../../Dialogs/AddGeneralCompromise/AddGeneralCompromiseDialogComponent";
import {EditCompromiseDialogComponent} from "../../Dialogs/EditCompromise/EditCompromiseDialogComponent";
import {ICompromiseRowData} from "./ICompromiseRowData";
import {
    generalDetailsPanel,
    getCreatedCompromisesColumns,
    getGeneralCompromiseRowData,
    getGeneralCompromisesTemplateColumns,
    getOwnedCompromisesColumns,
    getProjectCompromiseRowData,
    getProjectCompromisesTemplateColumns,
    getUserCompromiseRowData,
    projectDetailsPanel,
    userDetailsPanel
} from "./compromiseTableUtils";
import {
    AddProjectCompromiseDialogComponent
} from "../../Dialogs/AddProjectCompromise/AddProjectCompromiseDialogComponent";


export interface CompromiseTableComponentProps {
    title: string,
    tableType: CompromiseTableType
}

export enum CompromiseTableType {
    General = 0,
    UserOwnedCompromises = 1,
    UserCreatedCompromises = 2,
    Project = 3
}

export interface ITableFilter {
    field: string,
    filterValue: any
}

function getFilters(tableRef: MutableRefObject<any>) {
    let newFilters: ITableFilter[] = tableRef?.current?.state.columns
        .filter(value => value.tableData.filterValue).map((column: any) => ({
            field: column.field,
            filterValue: column.tableData.filterValue
        }));
    return newFilters;
}


export const CompromisesTableComponent: FC<CompromiseTableComponentProps> = (props) => {

    const {selectedCompromise, compromises} = useSelector((state: RootState) => state.compromise);
    const {users} = useSelector((state: RootState) => state.user);
    const {projects, selectedProject} = useSelector((state: RootState) => state.project);
    const {isOpen} = useSelector((state: RootState) => state.progress)
    const {identity} = useSelector((state: RootState) => state.authentication)

    const [compromisesDataTable, setCompromisesDataTable] = useState<ICompromiseRowData[]>([])
    const [compromiseColumns, setCompromiseColumns] = useState<Column<ICompromiseRowData>[]>([])
    const [detailsPanel, setDetailsPanel] = useState<any>(generalDetailsPanel);

    const [filters, setFilters] = useState<ITableFilter[]>()
    const dispatch = useDispatch();
    const tableRef = useRef<MutableRefObject<any>>()

    useEffect(() => {
        switch (props.tableType) {
            case CompromiseTableType.General:
                setDetailsPanel(generalDetailsPanel)
                break;
            case CompromiseTableType.UserOwnedCompromises:
                setDetailsPanel(userDetailsPanel)
                break;
            case CompromiseTableType.UserCreatedCompromises:
                setDetailsPanel(userDetailsPanel)
                break;
            case CompromiseTableType.Project:
                setDetailsPanel(projectDetailsPanel)
                break;
        }
    }, [])


    useEffect(() => {
        // Storage the filters
        setFilters(getFilters(tableRef))

        switch (props.tableType) {
            case CompromiseTableType.General:
                if (compromises && users) {
                    let rowData = getGeneralCompromiseRowData(compromises, users)
                    setCompromisesDataTable([...rowData])
                }
                break;
            case CompromiseTableType.UserOwnedCompromises:
                if (compromises && users && projects) {
                    let userId = identity.credentials.id;
                    let rowData = getUserCompromiseRowData(compromises.filter(value => value.owner.id === userId), users, projects)
                    setCompromisesDataTable([...rowData])
                }
                break;
            case CompromiseTableType.UserCreatedCompromises:
                if (compromises && users && projects) {
                    let userId = identity.credentials.id;
                    let rowData = getUserCompromiseRowData(compromises.filter(value => value.creator.id === userId), users, projects)
                    setCompromisesDataTable([...rowData])
                }
                break;
            case CompromiseTableType.Project:
                if (compromises && users && projects) {
                    let rowData = getProjectCompromiseRowData(compromises
                        .filter(value => value.hasProject && value.project.id === selectedProject.id), users, projects)
                    setCompromisesDataTable([...rowData])
                }
                break;
        }
    }, [compromises, users, projects])

    useEffect(() => {
        switch (props.tableType) {
            case CompromiseTableType.General:
                setCompromiseColumns([...getGeneralCompromisesTemplateColumns(filters)])
                break;
            case CompromiseTableType.UserOwnedCompromises:
                setCompromiseColumns([...getOwnedCompromisesColumns(filters)])
                break;
            case CompromiseTableType.UserCreatedCompromises:
                setCompromiseColumns([...getCreatedCompromisesColumns(filters)])
                break;
            case CompromiseTableType.Project:
                setCompromiseColumns([...getProjectCompromisesTemplateColumns(filters)])
                break;
        }
        setCompromisesDataTable([...compromisesDataTable])
    }, [filters])

    return <>
        <MaterialTable
            tableRef={tableRef}
            title={props.title}
            isLoading={isOpen}
            columns={compromiseColumns}
            data={compromisesDataTable}
            detailPanel={detailsPanel}
            cellEditable={{
                onCellEditApproved: async (newValue, oldValue, rowData, columnDef) => {
                    if (columnDef.field === "compromiseStatus") {
                        await dispatch(updateCompromiseStatsThunk(rowData.id, +newValue))
                        rowData.compromiseStatus = +newValue;
                    }
                    return;
                }
            }}
            options={{
                actionsColumnIndex: -1,
                filtering: true,
                paging: true,
                pageSize: 20,
            }}
            actions={[{
                tooltip: Strings.AddCompromise,
                icon: 'add',
                isFreeAction: true,
                hidden: props.tableType !== CompromiseTableType.General,
                onClick: async () => {
                    await dispatch(compromise.actions.openAddGeneralCompromise())
                },
            }, {
                tooltip: Strings.AddCompromise,
                icon: 'add',
                isFreeAction: true,
                hidden: props.tableType !== CompromiseTableType.Project,
                onClick: async (event, rowData: ICompromiseRowData) => {
                    await dispatch(compromise.actions.openAddProjectCompromise())
                }
            }, {
                icon: Edit,
                tooltip: `${Strings.Edit}`,
                onClick: async (event, rowData: ICompromiseRowData) => {
                    let compromiseItem = compromises.find(s => s.id === rowData.id);
                    await dispatch(editCompromiseThunk(compromiseItem))
                }
            }, {
                icon: Delete,
                tooltip: `${Strings.Delete}`,
                onClick: async (event, rowData: ICompromiseRowData) => {
                    let compromiseItem = compromises.find(s => s.id === rowData.id)
                    await dispatch(removeCompromiseThunk(compromiseItem))
                }
            }]}
        />

        <AddGeneralCompromiseDialogComponent/>
        <AddProjectCompromiseDialogComponent/>
        {selectedCompromise &&
            <EditCompromiseDialogComponent selectedCompromise={selectedCompromise}/>
        }
    </>
}


